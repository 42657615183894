import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import { Textarea } from '@mui/joy';

const PrintSettings = (props) => {
    const [header_type, setValue] = React.useState('default');
    let [image_url,setImageURL] = React.useState('');
    let [authorized_signature,setAuthorizedSignature] = React.useState('');
    let [water_mark,setWatermark] = React.useState('');
    let [paper_size,setPaperSize] = React.useState('');
    let [number_of_copy,setNumberOfCopy] = React.useState(1);
    let [payment_print_format,setPaymentPrintFormat] = React.useState('format1');
    let [scheduled_exam_print_format,setScheduledExamPrintFormat] = React.useState('format1');
    let [is_saving_header,setSavingHeader] = React.useState(false);
    let [is_saving_signature,setSavingSignature] = React.useState(false);
    let [is_saving_watermark,setSavingWatermark] = React.useState(false);
    let [is_loading_signature,setIsLoadingSignature] = React.useState(false);
    let [is_loading_watermark,setIsLoadingWatermark] = React.useState(false);
    let [is_uploading_new,setIsNew] = React.useState(false);
    let [is_uploading_new_signature,setIsNewSignature] = React.useState(false);
    let [is_uploading_watermark,setIsNewWatermark] = React.useState(false);
    let [is_saving_footer,setSavingFooter] = React.useState(false);
    let [is_saving_page,setSavingPage] = React.useState(false);
    let [is_loading_page,setIsLoadingPage] = React.useState(true);
    let [toggle_signature_visibility,setToggleSignatureVisibility] = React.useState(true);
    let [toggle_watermark_visibility,setToggleWatermarkVisibility] = React.useState(true);
    let [print_footer_text,setPrintFooterText] = React.useState({payment:'',hall_ticket:'',scheduled_exam:'',report_card:''});
    let [print_header_title,setPrintHeaderTitle] = React.useState({payment_in:'',payment_out:''});
    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetPrintHeader();
            handleGetNumberOfCopy();
            handleGetPaymentPrintFormat();
            handleGetScheduledExamPrintFormat();
            handleGetPrintPageSize();
        }
    },[])
  
    const handleGetPrintHeader = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'print_header'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined && resp.value.header_type!==undefined){
                setValue(resp.value.header_type);
                
                if(resp.value.header!==undefined){
                    setImageURL(resp.value.header);
                }
                
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'print_footer_text'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setPrintFooterText(resp.value);
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'print_header_title'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setPrintHeaderTitle(resp.value);
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'authorized_signature'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.authorized_signature){
                setAuthorizedSignature(resp.value.authorized_signature);
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'water_mark'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.water_mark){
                setWatermark(resp.value.water_mark);
            }
        });
        setIsLoadingSignature(true);
        setIsLoadingWatermark(true);
        APP_HTTP.REQUEST('settings/get_option',{name:'toggle_signature_visibility'},true,true).then((response) => {
            let resp = response.data.response;
            
            if(resp.value!==undefined){
                setToggleSignatureVisibility(resp.value);
            }
            setIsLoadingSignature(false);
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'toggle_watermark_visibility'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined){
                setToggleWatermarkVisibility(resp.value);
            }
            setIsLoadingWatermark(false);
        });
    }
    const handleGetPrintPageSize = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'payment_receipt_page_size'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp==='A4' || resp==='A5'){
                setPaperSize(resp);
            }else if(resp.value!==undefined){
                setPaperSize(resp.value);
            }else{
                setPaperSize('A4');
            }
            setTimeout(() => {
                setIsLoadingPage(false);
            }, 100);
        });
    }
    const handleGetNumberOfCopy = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'number_of_copy'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined){
                setNumberOfCopy(resp.value);
            }
        });
    }
    const handleGetPaymentPrintFormat = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'payment_print_format'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined){
                setPaymentPrintFormat(resp.value);
            }
        });
    }
    const handleGetScheduledExamPrintFormat = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'scheduled_exam_print_format'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined){
                setScheduledExamPrintFormat(resp.value);
            }
        });
    }
    const handleUploadFileClick = () =>{
        if(document.getElementById("upload_file")){
            document.getElementById("upload_file").click();   
        }
    }
    const handleUploadFile = (e) =>{
        const files = e.target.files;
        if(files[0]){
            setIsNew(true);
            var url = URL.createObjectURL(files[0]);
            setImageURL(url);
        }
    }
    const handleSaveHeader = (e) =>{
        const formData = new FormData();
        if(document.getElementById("upload_file")){
            let upload_file = document.getElementById("upload_file");
            const files = upload_file.files;
            let photo = files[0];
            if(photo!==undefined){
                setSavingHeader(true);
                formData.append('photo', photo);
                formData.append('header_type', header_type);
                APP_HTTP.REQUEST('settings/save_print_header',formData,true,true,true).then((response) => {
                    setSavingHeader(false);
                });
            }
        }else{
            setSavingHeader(true);
            formData.append('photo', '');
            formData.append('header_type', header_type);
            APP_HTTP.REQUEST('settings/save_print_header',formData,true,true,true).then((response) => {
                setSavingHeader(false);
            });
        }
        APP_HTTP.REQUEST('settings/save_option',{name:'print_header_title',value:print_header_title},true,true).then((response) => {
            let resp = response.data;
        });
    }
    const handleUploadAuthorizedSignatureClick = () =>{
        if(document.getElementById("upload_authorized_signature")){
            document.getElementById("upload_authorized_signature").click();   
        }
    }
    const handleUploadAuthorizedSignature = (e) =>{
        const files = e.target.files;
        if(files[0]){
            setIsNewSignature(true);
            var url = URL.createObjectURL(files[0]);
            setAuthorizedSignature(url);
        }
    }
    const handleSaveSignature = (e) =>{
        
        const formData = new FormData();
        if(document.getElementById("upload_authorized_signature")){
            let upload_authorized_signature = document.getElementById("upload_authorized_signature");
            const files = upload_authorized_signature.files;
            let photo = files[0];
            if(photo!==undefined){
                setSavingSignature(true);
                formData.append('photo', photo);
                APP_HTTP.REQUEST('settings/save_authorized_signature',formData,true,true,true).then((response) => {
                    let sign = response.data.response;
                    localStorage.setItem('auth_sign',sign);
                    setSavingSignature(false);
                });
            }
        }
    }
    const handleUploadWatermarkClick = () =>{
        if(document.getElementById("upload_water_mark")){
            document.getElementById("upload_water_mark").click();   
        }
    }
    const handleUploadWatermark = (e) =>{
        const files = e.target.files;
        if(files[0]){
            setIsNewWatermark(true);
            var url = URL.createObjectURL(files[0]);
            setWatermark(url);
        }
    }
    const handleSaveWatermark = (e) =>{
        
        const formData = new FormData();
        if(document.getElementById("upload_water_mark")){
            let upload_water_mark = document.getElementById("upload_water_mark");
            const files = upload_water_mark.files;
            let photo = files[0];
            if(photo!==undefined){
                setSavingWatermark(true);
                formData.append('photo', photo);
                APP_HTTP.REQUEST('settings/save_water_mark',formData,true,true,true).then((response) => {
                    setSavingWatermark(false);
                    let wm = response.data.response;
                    localStorage.setItem('watermark',wm);
                });
            }
        }
    }
    const handleSaveFooter = (e) =>{
        setSavingFooter(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'print_footer_text',value:print_footer_text},true,true).then((response) => {
            let resp = response.data;
            setSavingFooter(false);
        });
    }
    const handleChangePaperSize = (id,value) =>{
        setPaperSize(value);
    }
    const handleChangeNumberOfCopy = (id,value) =>{
        setNumberOfCopy(value);
    }
    const handleChangeScheduledExamPrintFormat = (id,value) =>{
        setScheduledExamPrintFormat(value);
    }
    const handleChangePaymentPrintFormat = (id,value) =>{
        setPaymentPrintFormat(value);
    }
    const handleSavePage = ()=>{
        setSavingPage(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'payment_receipt_page_size',value:paper_size},true,true).then((response) => {
            let resp = response.data;
            setSavingPage(false);
        });
        APP_HTTP.REQUEST('settings/save_option',{name:'number_of_copy',value:number_of_copy},true,true).then((response) => {
            let resp = response.data;
        });
        APP_HTTP.REQUEST('settings/save_option',{name:'payment_print_format',value:payment_print_format},true,true).then((response) => {
            let resp = response.data;
        });
        APP_HTTP.REQUEST('settings/save_option',{name:'scheduled_exam_print_format',value:scheduled_exam_print_format},true,true).then((response) => {
            let resp = response.data;
        });
    }
    const handleChangeFooterText = (type,foot_text) =>{
        let pfooter = {...print_footer_text};
        pfooter[type] = foot_text;
        setPrintFooterText(pfooter);
    }
    const handleChangePrintHeaderTitle=(value,type)=>{
        let pheader = {...print_header_title};
        pheader[type]=value;
        setPrintHeaderTitle(pheader);
    }
    const handleToggleSignatureVisibility = (chk) =>{
        
        setToggleSignatureVisibility(chk);
        if(chk===false){
            localStorage.removeItem('auth_sign');
        }else{
            if(authorized_signature!==''){
                localStorage.setItem('auth_sign',authorized_signature);
            }
        }
        APP_HTTP.REQUEST('settings/save_option',{name:'toggle_signature_visibility',value:chk},true,true).then((response) => {
            let resp = response.data;
        });
    }
    const handleToggleWatermarkVisibility = (chk) =>{
        setToggleWatermarkVisibility(chk)
        if(chk===false){
            localStorage.removeItem('watermark');
        }else{
            if(water_mark!==''){
                localStorage.setItem('watermark',water_mark);
            }
        }
        APP_HTTP.REQUEST('settings/save_option',{name:'toggle_watermark_visibility',value:chk},true,true).then((response) => {
            let resp = response.data;
        });
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Print Header Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={4} spacing={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="class_name"
                            label="Payment IN(+) Header Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(e)=>handleChangePrintHeaderTitle(e.target.value,'payment_in')}
                            value={print_header_title.payment_in}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} spacing={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="class_name"
                            label="Payment OUT(-) Header Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(e)=>handleChangePrintHeaderTitle(e.target.value,'payment_out')}
                            value={print_header_title.payment_out}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} spacing={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">SELECT PRINT HEADER TYPE</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={header_type}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="default" control={<Radio/>} label="Default" />
                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                            </FormControl>
                            
                        </Grid>
                        {(header_type==='custom') &&
                            <>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                                <Button variant="outlined" onClick={handleUploadFileClick} style={{marginBottom:'10px'}}>Upload from Gallery</Button>
                                
                            </Grid>
                            {(image_url!=='' && is_uploading_new===false) &&
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <img src={image_url+'?time='+Math.random(1111,9999)} alt="Image" height={120} width={595}/>
                            </Grid>
                            }
                            {(image_url!=='' && is_uploading_new===true) &&
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <img src={image_url} alt="Image" height={120} width={595}/>
                            </Grid>
                            }
                            </>
                        }
                        {(image_url!=='' || header_type==='default') &&
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving_header===false) &&
                            <Button variant="contained" onClick={() => handleSaveHeader()}>Save Header</Button>
                        }
                        {(is_saving_header===true) &&
                            <LoaderButton label="Save Header"/>
                        }
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Print Footer Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>PAYMENT</Typography>
                            <Textarea minRows={4} size="lg" name="Size" placeholder="Please type payment footer here..." defaultValue={print_footer_text.payment}
                                      onChange={(event)=>handleChangeFooterText('payment',event.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>SCHEDULED EXAM</Typography>
                            <Textarea minRows={4} size="lg" name="Size" placeholder="Please type scheduled exam footer here..." defaultValue={print_footer_text.scheduled_exam}
                                      onChange={(event)=>handleChangeFooterText('scheduled_exam',event.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>HALL TICKET</Typography>
                            <Textarea minRows={4} size="lg" name="Size" placeholder="Please type hall ticket footer here..." defaultValue={print_footer_text.hall_ticket}
                                      onChange={(event)=>handleChangeFooterText('hall_ticket',event.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>REPORT CARD</Typography>
                            <Textarea minRows={4} size="lg" name="Size" placeholder="Please type report card footer here..." defaultValue={print_footer_text.report_card}
                                      onChange={(event)=>handleChangeFooterText('report_card',event.target.value)}/>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving_footer===false) &&
                            <Button variant="contained" onClick={() => handleSaveFooter()}>Save Footer</Button>
                        }
                        {(is_saving_footer===true) &&
                            <LoaderButton label="Save Header"/>
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(is_loading_page===false) &&
            <Grid container spacing={2} className="mrtop-10">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Other Print Setting
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                    {(paper_size!=='') &&
                        <AppDropDown label="Select Payment Receipt Size" id="paper_size" list_item={[{key:'A4',value:'A4'},{key:'A5',value:'A5'}]} size="small" defaultValue={paper_size} className="student-data" handleChangeData={handleChangePaperSize}/>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                    <AppDropDown label="Payment Receipt Number Of Copy" id="number_of_copy" list_item={[{key:'1',value:'1'},{key:'2',value:'2'},{key:'3',value:'3'},{key:'4',value:'4'},{key:'5',value:'5'}]} size="small" defaultValue={number_of_copy} className="student-data" handleChangeData={handleChangeNumberOfCopy}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                    <AppDropDown label="Payment Receipt Print Format" id="payment_print_format" list_item={[{key:'format1',value:'Format 1'},{key:'format2',value:'Format 2'},{key:'custom',value:'Custom'}]} size="small" defaultValue={payment_print_format} className="student-data" handleChangeData={handleChangePaymentPrintFormat}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                    <AppDropDown label="Scheduled Exam Print Format" id="scheduled_exam_print_format" list_item={[{key:'format1',value:'Format 1'},{key:'format2',value:'Format 2'}]} size="small" defaultValue={scheduled_exam_print_format} className="student-data" handleChangeData={handleChangeScheduledExamPrintFormat}/>
                </Grid>
                {(payment_print_format==='custom') &&
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Typography>Payment Custom Format</Typography>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                    {(is_saving_page===false) &&
                        <Button variant="contained" onClick={() => handleSavePage()}>Save Changes</Button>
                    }
                    {(is_saving_page===true) &&
                        <LoaderButton label="Save Changes"/>
                    }
                </Grid>

                <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
            </Grid>
            }
             <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Authorized Signature
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            
                            {(is_loading_signature===false) &&
                                <FormControlLabel control={<Checkbox value="auth-sigh-visibility" color="primary"  onChange={(e)=>handleToggleSignatureVisibility(e.target.checked)} checked={toggle_signature_visibility}/>} label="Hide/Show Authorized Signature"/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <input type='file' id="upload_authorized_signature" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadAuthorizedSignature}/>
                            <Button variant="outlined" onClick={handleUploadAuthorizedSignatureClick} style={{marginBottom:'10px'}}>Upload from Gallery</Button>
                        </Grid>
                        {(authorized_signature!=='' && is_uploading_new_signature===false) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <img src={authorized_signature+'?time='+Math.random(1111,9999)} alt="Image" height={100} width={300}/>
                        </Grid>
                        }
                        {(authorized_signature!=='' && is_uploading_new_signature===true) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <img src={authorized_signature} alt="Image" height={100} width={300}/>
                        </Grid>
                        }
                        
                        {(authorized_signature!=='') &&
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving_signature===false) &&
                            <Button variant="contained" onClick={() => handleSaveSignature()}>Save Signature</Button>
                        }
                        {(is_saving_signature===true) &&
                            <LoaderButton label="Save Header"/>
                        }
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Watermark
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            {(is_loading_watermark===false) &&
                                <FormControlLabel control={<Checkbox value="watermark-visibility" color="primary" onChange={(e)=>handleToggleWatermarkVisibility(e.target.checked)} checked={toggle_watermark_visibility}/>} label="Hide/Show Watermark"/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <input type='file' id="upload_water_mark" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadWatermark}/>
                            <Button variant="outlined" onClick={handleUploadWatermarkClick} style={{marginBottom:'10px'}}>Upload from Gallery</Button>
                        </Grid>
                        {(water_mark!=='' && is_uploading_watermark===false) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <img src={water_mark+'?time='+Math.random(1111,9999)} alt="Image" height={200} width={200}/>
                        </Grid>
                        }
                        {(water_mark!=='' && is_uploading_watermark===true) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <img src={water_mark} alt="Image" height={200} width={200}/>
                        </Grid>
                        }
                        
                        {(water_mark!=='') &&
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving_watermark===false) &&
                            <Button variant="contained" onClick={() => handleSaveWatermark()}>Save Watermark</Button>
                        }
                        {(is_saving_watermark===true) &&
                            <LoaderButton label="Save Header"/>
                        }
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}

export default PrintSettings;