const user_access_levels = () =>{
    let access_levels = [
        {
            'key':'dashboard',
            'index' : 1, 
            'is_parent':true,
            'has_child':false,
            'label':'Dashboard',
            'action':{'view':true}
        },
        {
            'key':'students',
            'index' : 2,
            'is_parent':true,
            'has_child':false,
            'label':'Students',
            'action':{
                'add':false,
                'edit':false,
                'delete':false,
                'view':false,
                'print':false
            }
        },
        {
            'key':'staffs',
            'index' : 3,
            'is_parent':true,
            'has_child':false,
            'label':'Staffs',
            'action':{
                'add':false,
                'edit':false,
                'delete':false,
                'view':false,
                'print':false
            }
        },
        {
            'key':'attendence',
            'index' : 4,
            'is_parent':true,
            'has_child':false,
            'label':'Attendence',
            'action':{
                'add':false,
                'edit':false,
                'view':false
            }
        },
        {
            'is_parent':true,
            'has_child':false,
            'index' : 5,
            'key':'payments',
            'label':'Payments',
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'payment_in',
                    'label':'Payment IN',
                    'index' : 501,
                    'action':{
                        'add':false,
                        'edit':false,
                        'delete':false,
                        'view':false,
                        'print':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'payment_out',
                    'label':'Payment OUT',
                    'index' : 502,
                    'action':{
                        'add':false,
                        'edit':false,
                        'delete':false,
                        'view':false,
                        'print':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'demand_bill',
                    'label':'Demand Bills',
                    'index' : 503,
                    'action':{
                        'add':false,
                        'edit':false,
                        'delete':false,
                        'view':false,
                        'convert':false,
                        'print':false
                    }
                }
            ]
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'exam',
            'label':'Exam',
            'index' : 6,
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'schedule_exam',
                    'label':'Schedule Exam',
                    'index' : 7,
                    'action':{
                        'add':false,
                        'edit':false,
                        'delete':false,
                        'view':false,
                        'print':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'exam_hall_allocation',
                    'label':'Exam Hall Allocation',
                    'index' : 8,
                    'action':{
                        'view':false,
                        'add':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'key':'hall_ticket_generation',
                    'has_child':false,
                    'label':'Hall Ticket Generation',
                    'index' : 9,
                    'action':{
                        'add':false,
                        'view':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'key':'subject_marks_entry',
                    'has_child':false,
                    'label':'Marks Entry',
                    'index' : 10,
                    'action':{
                        'view':false,
                        'edit':false,
                        'add':false,
                        'print':false,
                    }
                }]
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'transport',
            'label':'Transport',
            'index' : 11,
            'action':{
                'add':false,
                'edit':false,
                'view':false,
                'delete':false,
            },
            'childrens':[{
                'is_parent':true,
                'has_child':false,
                'key':'allocate_transport',
                'label':'Allocate Transport',
                'index' : 12,
                'action':{
                    'view':false,
                    'add':false,
                }
            }]
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'reports',
            'label':'Reports',
            'index' : 13,
            'action':{
                'view':false,
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'attendence',
                    'label':'Attendence',
                    'index' : 14,
                    'action':{
                        'view':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'tranaction',
                    'label':'Transaction',
                    'index' : 15,
                    'action':{
                        'view':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'pending_payments',
                    'label':'Pending Payments',
                    'index' : 16,
                    'action':{
                        'view':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'cash_book',
                    'label':'Cash Book',
                    'index' : 160,
                    'action':{
                        'view':false,
                        'print':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'all_user_report',
                    'label':'All User Report',
                    'index' : 161,
                    'action':{
                        'view':false
                    }
                }
            ]
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'user_management',
            'label':'User Management',
            'index' : 17,
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'roles',
                    'label':'Roles',
                    'index' : 18,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'users',
                    'label':'Users',
                    'index' : 19,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'verify':false,
                        'show_password':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'app_users',
                    'label':'Mobile App Users',
                    'index' : 32,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'verify':false,
                        'show_password':false,
                        'delete':false,
                    }
                }
            ]
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'leave_requests',
            'label':'Leave Requests',
            'index' : 170,
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'from_staff',
                    'label':'From Staff',
                    'index' : 170,
                    'action':{
                        'view':false,
                        'decline':false,
                        'accept':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'from_student',
                    'label':'From Student',
                    'index' : 170,
                    'action':{
                        'view':false,
                        'decline':false,
                        'accept':false,
                    }
                }
            ]
        },
        {
            'key':'inbox',
            'index' : 180,
            'is_parent':true,
            'has_child':false,
            'label':'Inbox',
            'action':{
                'reply':false,
                'view':false
            }
        },
        {
            'key':'reported_list',
            'index' : 190,
            'is_parent':true,
            'has_child':false,
            'label':'Reported List',
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'staff',
                    'label':'Staff',
                    'index' : 190,
                    'action':{
                        'view':false,
                        'resolve':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'student',
                    'label':'Student',
                    'index' : 190,
                    'action':{
                        'view':false,
                        'resolve':false,
                    }
                }
            ]
        },
        {
            'key':'homework',
            'index' : 300,
            'is_parent':true,
            'has_child':false,
            'label':'Homework',
            'action':{
                'add':false,
                'view':false,
                'delete':false,
            }
        },
        {
            'key':'enquiry',
            'index' : 301,
            'is_parent':true,
            'has_child':false,
            'label':'Enquiry',
            'action':{
                'add':false,
                'view':false,
                'delete':false,
            }
        },
        {
            'key':'occasion_publish',
            'index' : 304,
            'is_parent':true,
            'has_child':false,
            'label':'occasion publish',
            'action':{
                'add':false,
                'view':false
            }
        },
        {
            'key':'library',
            'index' : 303,
            'is_parent':true,
            'has_child':false,
            'label':'Library',
            'action':{
                'add':false,
                'view':false
            }
        },
        {
            'key':'inventory',
            'index' : 305,
            'is_parent':true,
            'has_child':false,
            'label':'Inventory',
            'action':{
                'add':false,
                'view':false
            }
        },
        {
            'key':'notices',
            'index' : 200,
            'is_parent':true,
            'has_child':false,
            'label':'Notices',
            'action':{
                'add':false,
                'view':false,
                'delete':false,
            }
        },
        {
            'is_parent':true,
            'has_child':true,
            'key':'settings',
            'label':'Settings',
            'index' : 20,
            'action':{
                'view':false
            },
            'childrens':[
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class',
                    'label':'Classes',
                    'index' : 21,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'section',
                    'label':'Sections',
                    'index' : 22,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'subject',
                    'label':'Subjects',
                    'index' : 23,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'staff_types',
                    'label':'Staff Types',
                    'index' : 24,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':true,
                    'key':'exam',
                    'label':'Exam',
                    'index' : 25,
                    'action':{
                        'view':false
                    },
                    'childrens':[
                        {
                            'is_parent':true,
                            'has_child':false,
                            'key':'exam_type',
                            'label':'Exam Types',
                            'index' : 26,
                            'action':{
                                'view':false,
                                'add':false,
                                'edit':false,
                                'delete':false,
                            }
                        },
                        
                        {
                            'is_parent':true,
                            'has_child':false,
                            'key':'grade',
                            'label':'Grades',
                            'index' : 27,
                            'action':{
                                'view':false,
                                'add':false,
                                'edit':false,
                                'delete':false,
                            }
                        },
                        {
                            'is_parent':true,
                            'has_child':false,
                            'key':'division',
                            'label':'Division',
                            'index' : 32,
                            'action':{
                                'view':false,
                                'add':false,
                                'edit':false,
                                'delete':false,
                            }
                        },
                    ]
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class_room',
                    'label':'Class Room',
                    'index' : 28,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'payment_types',
                    'label':'Payment Types',
                    'index' : 29,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'sequence',
                    'label':'Sequence',
                    'index' : 30,
                    'action':{
                        'view':false,
                        'add':false,
                        'edit':false,
                        'delete':false,
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'attendance_timing',
                    'label':'Attendance Time',
                    'index' : 201,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class_section_shifting',
                    'label':'Class Section Shifting',
                    'index' : 202,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class_roll_number_allocation',
                    'label':'Class Roll Number Allocation',
                    'index' : 203,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class_routine',
                    'label':'Class Routine',
                    'index' : 204,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'class_teacher_mapping',
                    'label':'Class Teacher Mapping',
                    'index' : 205,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'holiday_list',
                    'label':'Holiday List',
                    'index' : 206,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'import_export',
                    'label':'Import/Export',
                    'index' : 207,
                    'action':{
                        'view':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'messages',
                    'label':'Messages',
                    'index' : 208,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'print',
                    'label':'Print',
                    'index' : 209,
                    'action':{
                        'view':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'session',
                    'label':'Session',
                    'index' : 210,
                    'action':{
                        'view':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'uniform',
                    'label':'Uniform',
                    'index' : 211,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'homework',
                    'label':'Homework',
                    'index' : 212,
                    'action':{
                        'edit':false
                    }
                },
                {
                    'is_parent':true,
                    'has_child':false,
                    'key':'general',
                    'label':'General',
                    'index' : 213,
                    'action':{
                        'edit':false
                    }
                },
            ]
        },
        {
            'is_parent':true,
            'has_child':false,
            'key':'profile',
            'label':'Profile',
            'index' : 31,
            'action':{
                'edit':false,
                'view':false
            }
        }
    ];
    return access_levels;
}
const has_access = (state,key) =>{
    const access_info = state.access_info;
    if(access_info!==undefined && access_info.access_level!==undefined){
        let access_level = access_info.access_level;
        if(access_level.indexOf('all_access')>=0){
            return true;
        }
        if(access_level.indexOf(key)>=0 && access_level.indexOf(key)>=0){
            return true;
        }
    }
    return false;
}
const currency_format = (number) => {
    if(number===''  || number===undefined  || number===null){
        return 0;
    }
    number = number.toString();
    if(number.indexOf(",")){
        number = number.replaceAll(',','');
    }
    var multiplier = Math.pow(10, 2 || 0);
    number =  Math.round(number * multiplier) / multiplier;
	//number = parseFloat(number).toFixed(2);
    number = prepend_decimal_point(number);
    number = new Intl.NumberFormat('en-IN',{
        //style: 'currency',
        //currency: 'INR',
        minimumFractionDigits: 2,
      }).format(number);
    //console.log(number);
    return number;
}
const floating_point = (number) => {
    if(number===''  || number===undefined  || number===null){
        return 0;
    }
    number = number.toString();
    if(number.indexOf(",")){
        number = number.replaceAll(',','');
    }
    var multiplier = Math.pow(10, 2 || 0);
    number =  Math.round(number * multiplier) / multiplier;
    number = prepend_decimal_point(number);
	//number = parseFloat(number).toFixed(2);
    //return number.replace('.00','');
    return number;
}
const prepend_decimal_point = (num)  =>{
    // Cast as number
    var num = Number(num);
    // If not a number, return 0
    if (isNaN(num)) {
        return num;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
        num = num.toFixed(2);
    }
    // Return the number
    return parseFloat(num);
}

const number_to_words =(amount)=> {
    if(amount===''  || amount===undefined  || amount===null){
        return '';
    }
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    let value = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}
const stylishTextConverter = (text)=>{
    let small_text = {'a':'𝓪','b':'𝓫','c':'𝓬','d':'𝓭','e':'𝓮','f':'𝓯','g':'𝓰','h':'𝓱','i':'𝓲','j':'𝓳','k':'𝓴','l':'𝓵','m':'𝓶','n':'𝓷','o':'𝓸','p':'𝓹','q':'𝓺','r':'𝓻','s':'𝓼','t':'𝓽','u':'𝓾','v':'𝓿','w':'𝔀','x':'𝔁','y':'𝔂','z':'𝔃'};

    let caps_text = {'A':'𝓐','B':'𝓑','C':'𝓒','D':'𝓓','E':'𝓔','F':'𝓕','G':'𝓖','H':'𝓗','I':'𝓘','J':'𝓙','K':'𝓚','L':'𝓛','M':'𝓜','N':'𝓝','O':'𝓞','P':'𝓟','Q':'𝓠','R':'𝓡','S':'𝓢','T':'𝓣','U':'𝓤','V':'𝓥','W':'𝓦','X':'𝓧','Y':'𝓨','Z':'𝓩'}
    let new_text_arr = [];
    let new_text = '';
    for (let index = 0; index < text.length; index++) {
        const txt_char = text[index];
        if(small_text[txt_char]!==undefined){
            let txt_tmp = small_text[txt_char];
            new_text = new_text+txt_tmp;
        }else if(caps_text[txt_char]!==undefined){
            let txt_tmp = caps_text[txt_char];
            new_text = new_text+txt_tmp;
        }else{
            new_text = new_text+txt_char;
        }
    }
    if(new_text!==''){
        text = new_text;
    }
    return text;
}
export default {currency_format,floating_point,number_to_words,has_access,user_access_levels,stylishTextConverter}