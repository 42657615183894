import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import BasicDatePicker from '../../DatePicker.jsx';
import AppCheckbox from '../../AppCheckbox.jsx';


const AttendenceTiming = (props) => {
    const [student_attendance_type, setStudentAttendanceType] = React.useState('default');
    const [is_staff_loaded,setStaffLoading] = React.useState(false);
    const [is_student_loaded,setStudentLoading] = React.useState(false);
    const [staffTime,setStaffTime] = React.useState({'is_time_enabled':false,'start_time':new Date(),'end_time':new Date()});
    const [studentTime,setStudentTime] = React.useState({'is_time_enabled':false,'end_time':new Date(),'start_time':new Date()});
    const [timing_list,setTimings] = React.useState([]);
    const [is_saving_staff,setStaffSaving] = React.useState(false);
    const [is_saving_student,setStudentSaving] = React.useState(false);
    const [is_enabled_staff,setStaffEnabled] = React.useState(false);
    const [is_enabled_student,setStudentEnabled] = React.useState(false);
    const [school_location,setSchoolLocation] = React.useState({latitude:'',longitude:''});
    const [is_active_teacher_attenance_module,setIsEnabledTeacherAttendanceModule] = React.useState(false);
    const [is_saving_location,setSavingLocation] = React.useState(false);

    const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            get_staff_attendence_time();
            get_student_attendence_time();
            handleGetSettings();
        }
    
    },[dispatch])
    const handleChangeStudentAttendanceType = (value) => {

        setStudentAttendanceType(value);
        APP_HTTP.REQUEST('settings/save_option',{name:'student_attendance_type',value:value},true,true).then((response) => {
          
        });
      };
   const get_staff_attendence_time = () =>{
    APP_HTTP.REQUEST('settings/get_staff_attendence_time',{},true,true).then((response) => {
        let resp = response.data;
        let data = resp.response;
        if(data.is_time_enabled!==undefined){
            setStaffEnabled(data.is_time_enabled);
            setStaffTime(data);
        }
        setStaffLoading(true)
    });
   }
   const get_student_attendence_time = () =>{
    APP_HTTP.REQUEST('settings/get_student_attendence_time',{},true,true).then((response) => {
        let resp = response.data;
        let data = resp.response;
        if(data.is_time_enabled!==undefined){
            setStudentEnabled(data.is_time_enabled);
            setStudentTime(data);
        }
        setStudentLoading(true);
    });
   }
    const handleChangeMessage = (value,index) =>{
        let msgs = [...timing_list];
        msgs[index].message = value;
        setTimings(msgs);
    }
    const handleSaveStaffTiming = () =>{
        setStaffSaving(true);
        
        APP_HTTP.REQUEST('settings/save_option',{name:'staff_attendece_time',value:staffTime},true,true).then((response) => {
            let resp = response.data;
            setStaffSaving(false);
        });
    }
    const handleSaveStudentTiming = () =>{
        setStudentSaving(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'student_attendece_time',value:studentTime},true,true).then((response) => {
            let resp = response.data;
            setStudentSaving(false);
        });
    }
    const handleChangeStudentStartTime = (key,date) =>{
        studentTime.start_time=date;
        setStudentTime(studentTime);
    }
    const handleChangeStudentEndTime = (key,date) =>{
        studentTime.end_time=date;
        setStudentTime(studentTime);
    }
    const handleChangeStaffStartTime = (key,date) =>{
        staffTime.start_time=date;
        setStaffTime(staffTime);
    }
    const handleChangeStaffEndTime = (key,date) =>{
        staffTime.end_time=date;
        setStaffTime(staffTime);
    }
    const handleChangeStaffTiming = (e,key) =>{
        let is_enabled = e.target.checked;
        setStaffEnabled(is_enabled);
        staffTime.is_time_enabled=is_enabled;
        setStaffTime(staffTime);
        APP_HTTP.REQUEST('settings/save_option',{name:'staff_attendece_time',value:staffTime},true,true).then((response) => {
            let resp = response.data;
            //setStudentSaving(false);
        });
    }
    const handleChangeStudentTiming = (e,key) =>{
        let is_enabled = e.target.checked;
        setStudentEnabled(is_enabled);
        studentTime.is_time_enabled=is_enabled;
        setStudentTime(studentTime);
        APP_HTTP.REQUEST('settings/save_option',{name:'student_attendece_time',value:studentTime},true,true).then((response) => {
            let resp = response.data;
           // setStudentSaving(false);
        });
    }
    const handleGetSettings = () =>{
        APP_HTTP.REQUEST('settings/get_option',{name:'student_attendance_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!=undefined){
                setStudentAttendanceType(resp.value);
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'school_location'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.latitude){
                setSchoolLocation(resp.value);
            }
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'active_modules'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                let active_modules = resp.value;
                let is_active_t_a_module = false;
                for (let index = 0; index < active_modules.length; index++) {
                    const element = active_modules[index];
                    let is_active = element.is_active;
                    if(element.key==='teacher_attendance'){
                        is_active_t_a_module = is_active;
                    }
                }
                setIsEnabledTeacherAttendanceModule(is_active_t_a_module);
            }
        });
    }
    const handleSaveLocation = () =>{
        if(school_location.latitude==="" || school_location.longitude===""){
            return false;
        }
        setSavingLocation(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'school_location',value:school_location},true,true).then((response) => {
            setSavingLocation(false);
        });
    }
    const handleSetLocation = (value,type) =>{
        let sLocation = {...school_location};
        sLocation[type] = value;
        setSchoolLocation(sLocation);
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Attendance Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">SELECT STUDENT ATTENDENCE TYPE</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={student_attendance_type}
                            onChange={(e)=>handleChangeStudentAttendanceType(e.target.value)}
                        >
                            <FormControlLabel value="default" control={<Radio/>} label="Default" />
                            <FormControlLabel value="custom" control={<Radio />} label="Period Wise" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {(is_active_teacher_attenance_module===true) &&
                <>
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Typography>SCHOOL LATITUDE & LONGITUDE</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} spacing={2}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="latitude"
                                label="Latitude"
                                size='small'
                                onChange={(e)=>handleSetLocation(e.target.value,'latitude')}
                                value={school_location.latitude}
                            />
                            
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} spacing={2}>
                                <TextField
                                    margin="normal"
                                    size='small'
                                    fullWidth
                                    id="longitude"
                                    label="Longitude"
                                    onChange={(e)=>handleSetLocation(e.target.value,'longitude')}
                                    value={school_location.longitude}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} spacing={2}>
                                {(is_saving_location===false) &&
                                    <Button onClick={handleSaveLocation} variant='outlined' size='medium' style={{marginTop:'15px'}}>Save Location</Button>
                                }
                                {(is_saving_location===true) &&
                                    <LoaderButton variant='outlined' size='large'/>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                    </Grid>  
                </>
                }
                {(is_staff_loaded===true) &&
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>STAFF ATTENDANCE TIME</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <AppCheckbox label={'Enable Timing'} cb_key={'staff-timing'} value={'staff-timing'} checked={is_enabled_staff}  handleChangeCB={handleChangeStaffTiming}/>
                        </Grid>
                        {(is_enabled_staff===true) &&
                        <>
                            <Grid item xs={12} sm={12} md={3} spacing={2}>
                                <BasicDatePicker label="Start Time" size="small" id="staff_start_time" handleChangeData={handleChangeStaffStartTime} timepicker={true} defaultValue={staffTime.start_time}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} spacing={2} style={{textAlign:'center'}}>
                                <Typography>TO</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} spacing={2}>
                                <BasicDatePicker label="End Time" size="small" id="staff_end_time" handleChangeData={handleChangeStaffEndTime} timepicker={true} defaultValue={staffTime.end_time}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} spacing={2}>
                            {(is_saving_staff===false) &&
                                <Button onClick={handleSaveStaffTiming} variant='outlined' size='large'>Save Timing</Button>
                            }
                            {(is_saving_staff===true) &&
                                <LoaderButton variant='outlined' size='large'/>
                            }
                            </Grid>
                        </>
                        }
                    </Grid>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(is_student_loaded===true) &&
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography>STUDENT ATTENDENCE TIME</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <AppCheckbox label={'Enable Timing'} cb_key={'student-timing'} value={'student-timing'} checked={is_enabled_student}  handleChangeCB={handleChangeStudentTiming}/>
                        </Grid>
                        {(is_enabled_student===true) &&
                            <>
                                <Grid item xs={12} sm={12} md={3} spacing={2}>
                                    <BasicDatePicker label="Start Time" size="small" id="student_start_time" handleChangeData={handleChangeStudentStartTime} timepicker={true} defaultValue={studentTime.start_time}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} spacing={2} style={{textAlign:'center'}}>
                                    <Typography>TO</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} spacing={2}>
                                    <BasicDatePicker label="End Time" size="small" id="student_end_time" handleChangeData={handleChangeStudentEndTime} timepicker={true}  defaultValue={studentTime.end_time}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} spacing={2}>
                                {(is_saving_student===false) &&
                                    <Button onClick={handleSaveStudentTiming} variant='outlined' size='medium'>Save Timing</Button>
                                }
                                {(is_saving_student===true) &&
                                    <LoaderButton variant='outlined' size='medium'/>
                                }
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                }
            </Grid>
        </Box>
    </>
  );
}

export default AttendenceTiming;