import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Textarea } from '@mui/joy';
import PrintHeader from '../PrintHeader';
import APP_HTTP from '../../APP_HTTP';

export default function EmployementForm() {
  const dispatch = useDispatch();
  const [school_info, setSchoolInfo] = React.useState({});
  const state = useSelector((state) => state);

  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
        initialized.current = true;
        handleGetSchoolInfo();
      }
  },[])

  const handleGetSchoolInfo = ()=>{
    APP_HTTP.REQUEST('school/get_school_info',{},true,true).then((response) => {
        let resp = response.data;
        let sinfo = resp.response;
        setSchoolInfo(sinfo)
    });
  }
  const handlePrint = ()=>{
    if(document.getElementById("form-print-block")){
        var divContents = document.getElementById("form-print-block").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
        
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse;border:1px solid #333}table td,table th{padding:.75rem;vertical-align:top} input{border:1px solid #333}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Button variant='contained' size='small' onClick={()=>handlePrint()}>PRINT EMPLOYEMENT FORM</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <div style={{display:'block',zoom:'30%',border:'1px solid #333'}} id="form-print-block" >
                <table cellPadding={5} cellSpacing={0} style={{width:'100%'}}>
                <PrintHeader header_data={school_info}/>  
                <tbody>
                    <tr>
                        <th style={{textAlign:'center'}} colSpan={2}>
                            <u>APPLICATION FOR EMPLOYEMENT</u>
                        </th>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}}>
                            Application for the post of
                            <input type='text' style={{float:'right',height:'30px', width:'280px'}}/> 
                        </td>
                        <td style={{fontSize:'14px'}} rowSpan={3}><div style={{width:'135px',height:'150px', textAlign:'center', border:'1px solid #333', float:'right'}}>Affix Passport Size Color Photograph</div></td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}}>
                            Name of the applicant
                            <input type='text' style={{float:'right',height:'30px', width:'280px'}}/> 
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}}>
                            Father's / Husband's name
                            <input type='text' style={{float:'right',height:'30px', width:'280px'}}/> 
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}} colSpan={2}>Gender  <span style={{padding:'1px 8px', border:'1px solid #333',marginLeft:'15px'}}></span><span style={{marginLeft:'10px'}}>Male</span> <span style={{padding:'1px 8px', border:'1px solid #333',marginLeft:'10px'}}></span><span style={{marginLeft:'10px'}}>Female</span>
                        <span style={{float:'right'}}>Date of birth <input type='text' style={{height:'30px'}} placeholder='DD/MM/YYYY'/></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}} colSpan={2}>
                            <span>Contact Number</span>
                            <input type='text' style={{height:'30px',marginLeft:'10px'}} value={'+91-'}/> 

                            <span style={{marginLeft:'10px'}}>Alternate number</span>
                            <input type='text' style={{height:'30px',marginLeft:'10px'}} value={'+91-'} /> 
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}} colSpan={2}>
                            Applicant's Address
                            <input type='text' style={{height:'30px', width:'100%'}} value={""}/> 
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}} colSpan={2}>
                            Educational Qualification
                            <table cellPadding={15} cellSpacing={0} style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Degree</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Board/University</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Year</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Per(%)</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333',borderRight:'1px solid #333'}}>Subjects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333',borderRight:'1px solid #333'}}></th>
                                    </tr>
                                <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333',borderRight:'1px solid #333'}}></th>
                                    </tr>
                                <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333',borderRight:'1px solid #333'}}></th>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}} colSpan={2}>
                            Employment history
                            <table cellPadding={15} cellSpacing={0} style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Employer Name</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Employer Address</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Job Title</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333'}}>Dates Of Employment</th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333',borderRight:'1px solid #333'}}>Reason for Leaving</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333', height:'100px'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333', height:'100px'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333', height:'100px'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333', height:'100px'}}></th>
                                        <th style={{borderLeft:'1px solid #333',borderTop:'1px solid #333', height:'100px',borderRight:'1px solid #333'}}></th>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{textAlign:'right'}}>
                            <i style={{marginRight:'80px'}}>Applicant's Signature</i>
                        </td>
                    </tr>
                </tbody>
               
                </table>
                </div>
            </Grid>
        </Grid>
    </Box>
  );
}