import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { handleChangeMenu, handleSetAccessCheck, handleSetSchoolInfo } from "../actions/Action";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';

import ContactsIcon from '@mui/icons-material/Contacts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BusAlert, Celebration, CurrencyRupee, DirectionsBus, EditNote, Error, Group, HelpCenter, Inbox, Inventory, LibraryAdd, LibraryBooks, MoveToInbox, NoteAlt, Notes, Payments, PictureAsPdf, PictureAsPdfSharp, Receipt, VerifiedUser, Warning } from '@mui/icons-material';
import StudentList from './students/StudentList';
import StaffList from './staffs/StaffList';
import CreateStudent from './students/CreateStudent';
import Settings from './settings/Settings';
import CreateStaff from './staffs/CreateStaff';
import Login from './Login';
import Signup from './Signup';
import APP_HTTP from '../APP_HTTP';
import StudentProfile from './students/StudentProfile';
import StaffProfile from './staffs/StaffProfile';
import Attendence from './attendence/Attendence';
import PaymentList from './payments/PaymentList';
import CreatePayment from './payments/CreatePayment';
import ViewPayment from './payments/ViewPayment';
import SchoolProfile from './profile/SchoolProfile';
import Exam from './exam/Exam';
import Transport from './transport/Transport';
import CreateTransport from './transport/CreateTransport';
import Reports from './reports/Reports';
import AppDashboard from './dashboard/AppDashboard';
import UserManagement from './user-management/UserManagement';
import AppUtils from '../AppUtils';
import PaymentHome from './payments/PaymentHome';
import ViewDemandBill from './payments/ViewDemandBill';
import { Button, Paper, Typography } from '@mui/material';
import LeaveRequest from './leave_request/LeaveRequest';
import AppInbox from './inbox/AppInbox';
import Notices from './notices/Notices';
import ReportedList from './reported_list/ReportedList';
import AdminMain from './admin/AdminMain';
import Homework from './homework/Homework';
import Enquiry from './enquiry/Enquiry';
import { purple } from '@mui/material/colors';
import UserProfile from './profile/UserProfile';
import SchoolCertificates from './certificates/SchoolCertificates';
import SchoolLibrary from './library/SchoolLibrary';
import OccassionPublish from './ocassion_publish/OcassionPublish';
import AppNotification from './AppNotification';
import SchoolInventory from './inventory/SchoolInventory';
//import HomePage from '../blog/HomePage';


const drawerWidth = 240;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fff'),
  backgroundColor:'#fff',
  color:'#1976d2',
  '&:hover': {
    backgroundColor: '#fff',
    color:'#1976d2'
  },
}));
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const  MainComponent = ()=> {
  const dispatch = useDispatch();
  const [user_name,setUserName] =  React.useState('');
  const [role_id,setRoleID] =  React.useState('');
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
          let school_info = JSON.parse(localStorage.getItem('MnzYs4pVGL'));
            initialized.current = true;
            if(school_info!==null && school_info.hash!==undefined && school_info.hash!==null){
              APP_HTTP.REQUEST('users/check_user',{},true,true).then((response) => {
                let resp = response.data;
                let access_list = resp.response;
                let school_info = resp.school_info;
                let uName = access_list.user_name;
                let rid = parseInt(access_list.role_id);
                setRoleID(rid);
                let aLevel = access_list.access_level;
                dispatch(handleSetAccessCheck(aLevel));
                dispatch(handleSetSchoolInfo(school_info.info));
                setUserName(uName);
              });
            }
        }
  })

  const theme = useTheme();
  const state = useSelector((state) => state);
  const active_menu = state.main_menu;
  const sub_menu = state.sub_menu;
  
  const student_info = state.student_info;
  const staff_info = state.staff_info;
  const payment_info = state.payment_info;
  const transport_info = state.transport_info;
  let is_logged_in = state.is_logged_in;
  let is_show_website = state.is_show_website;
  
  let curl = window.location.href;
  const is_admin = curl.match(/school\/admin/i);
  const admin_menu = curl.match(/school\/admin\/([a-z-_]+)/i);
  let isAdmin = false;
  if(is_admin){
    isAdmin = true;
    is_logged_in = false;
  }
  let menu_label = state.menu_label;
   
  const is_edit_item = state.is_student_edit;
  const is_view_item = state.is_student_view;
  let is_enable_add_btn = state.is_enable_add_btn ;
  if(is_edit_item===true){
    is_enable_add_btn = false;
  }
  if(active_menu==='student' && sub_menu==='view'){
    is_enable_add_btn = true;
  }
  if(active_menu==='staff' && sub_menu==='view'){
    is_enable_add_btn = true;
  }
  if(active_menu==='payment' && sub_menu==='view'){
    is_enable_add_btn = true;
  }
  if(active_menu==='students'){
    if(AppUtils.has_access(state,'2_students_add')===false){
      is_enable_add_btn = false;
    }
  }
  if(active_menu==='students'){
    if(AppUtils.has_access(state,'2_students_add')===false){
      is_enable_add_btn = false;
    }
  }
  if(active_menu==='staffs'){
    if(AppUtils.has_access(state,'3_staffs_add')===false){
      is_enable_add_btn = false;
    }
  }
  if(active_menu==='payments'){
    
    if(sub_menu==='' && AppUtils.has_access(state,'501_payment_in_add')===false){
      is_enable_add_btn = false;
    }
    if(sub_menu==='out' && AppUtils.has_access(state,'502_payment_out_add')===false){
      is_enable_add_btn = false;
    }
    if(sub_menu==='demand_bill'){
      is_enable_add_btn = false;
    }
  }
  if(active_menu==='transports'){
    if(AppUtils.has_access(state,'11_transport_add')===false){
      is_enable_add_btn = false;
    }
  }
  let is_show_back = false;
  if(active_menu==='student' && (sub_menu==='view' || menu_label==='Student Profile')){
    is_show_back = true;
  }else if(active_menu==='staff' && (sub_menu==='view' || menu_label==='Staff Profile')){
    is_show_back = true;
  }else if(active_menu==='payment' && (sub_menu==='view' || menu_label==='View Payment' || menu_label==='View Demand Bill')){
    is_show_back = true;
  }

  //dispatch(handleChangeMenu(active_menu,sub_menu,menu_label,is_enable_add_btn));
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSelectMenu = (label,menu,sub_menu='',is_enable_add_btn=false) => {
    if(menu==='logout'){
      let send_data = {
        
      };
      APP_HTTP.REQUEST('school/logout',send_data,true, true).then((response) => {
          localStorage.removeItem('MnzYs4pVGL');
          localStorage.removeItem('referer');
          window.location.href = '/login';
      });
    }else{
      let smenu = '';
      if(sub_menu!==""){
        smenu = '/'+sub_menu;
      }
      let referer = window.location.href;
      localStorage.setItem('referer',referer);
      window.location.href = '/'+menu+smenu;
      //dispatch(handleChangeMenu(menu,sub_menu,label,is_enable_add_btn));
    }
    //handleDrawerClose();
    /* if(menu==='logout'){
      window.location.href = '/login';
    }*/
  }

  const handleOpenCreate = () =>{
    let add_main_menu = '';
    let add_sub_menu = '';
    let add_label = '';
    if(active_menu==='students' || (active_menu==='student' && sub_menu==='view')){
      add_main_menu = 'student';
      add_sub_menu = 'add';
      add_label = 'Create Student';
    }else if(active_menu==='staffs' || (active_menu==='staff' && sub_menu==='view')){
      add_main_menu = 'staff';
      add_sub_menu = 'add';
      add_label = 'Create Staff';
    }else if(active_menu==='payments' || (active_menu==='payment' || sub_menu==='view')){
      add_main_menu = 'payment';
      add_sub_menu = 'add/payment_in';
      add_label = 'Create Payment';
    }else if(active_menu==='transports' || active_menu==='transport'){
      add_main_menu = 'transport';
      add_sub_menu = 'add';
      add_label = 'Create Transport';
    }
    handleSelectMenu(add_label,add_main_menu,add_sub_menu,false);
  }
  const handleCloseView = () =>{
    let add_main_menu = '';
    let add_sub_menu = '';
    let add_label = '';
  
    if(active_menu==='student' && (sub_menu==='view' || menu_label==='Student Profile')){
      add_main_menu = 'students';
      add_sub_menu = '';
      add_label = 'Students';
    }else if(active_menu==='staff' && (sub_menu==='view' || menu_label==='Staff Profile')){
      add_main_menu = 'staffs';
      add_sub_menu = '';
      add_label = 'Staffs';
    }else if(active_menu==='payment' && (sub_menu==='view' || menu_label==='View Payment' || menu_label==='View Demand Bill')){
      add_main_menu = 'payments';
      add_sub_menu = '';
      if(menu_label==='View Demand Bill'){
        add_sub_menu = 'demand_bill';
      }
      add_label = 'Payments';
    }
    if(localStorage.getItem('referer')){
      let referer = localStorage.getItem('referer');
      window.location.href = referer;
    }else{
      handleSelectMenu(add_label,add_main_menu,add_sub_menu,true);
    }
  }
  
  return (
    <>
    {(is_logged_in===true) &&
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box variant="h6" component="div" sx={{width:'100%'}}>
              {(is_show_back===true) &&
                <Box component="span" sx={{float:'left',marginRight:'10px',cursor:'pointer'}}><ChevronLeftIcon onClick={handleCloseView}/></Box>
              }
              <Box component="span">{menu_label}</Box>
              <AppNotification />
              {(is_enable_add_btn===true) &&
                <Box component="span" sx={{float:'right',cursor:'pointer'}}>
                  {/* <AddCircleIcon onClick={handleOpenCreate} /> */}
                  {/* <Button variant="text" color='info' onClick={() => handleOpenCreate()}>Create New</Button> */}
                  <ColorButton variant="contained" onClick={handleOpenCreate}><AddCircleIcon style={{marginRight:'10px'}}/>  Create New</ColorButton>
                </Box>
              }
              
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {/* <DrawerHeader style={{backgroundImage: 'url(https://media1.tenor.com/m/BWBt-ddc1hQAAAAC/independance-day---indian-flag-india-flag.gif)', backgroundSize: 'contain',color:'#fff'}}>
           
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color:'#ffffff'}}/> : <ChevronRightIcon style={{color:'#ffffff'}}/>}
            </IconButton>
          </DrawerHeader> */}
          <DrawerHeader>
            <Typography sx={{textTransform:'capitalize',fontWeight:'700'}}>Welcome {user_name}</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {(AppUtils.has_access(state,'1_dashboard') && AppUtils.has_access(state,'1_dashboard_view')) &&
            <>
              <List>
                <ListItem key={'Dashboard'} disablePadding onClick={() => handleSelectMenu('Dashboard','dashboard','',false)} selected={(active_menu==='dashboard')?true:false}>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'36px'}}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Dashboard'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
            </>
          }
          <List>
          {(AppUtils.has_access(state,'2_students') && AppUtils.has_access(state,'2_students_view')) &&
            <ListItem key={'Students'} disablePadding  onClick={() => handleSelectMenu('Students','students','',true)} selected={(active_menu==='students' || active_menu==='student')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText primary={'Students'} />
              </ListItemButton>
            </ListItem>
          }
          {(AppUtils.has_access(state,'3_staffs') && AppUtils.has_access(state,'3_staffs_view')) &&
          <ListItem key={'Staffs'} disablePadding  onClick={() => handleSelectMenu('Staffs','staffs','',true)} selected={(active_menu==='staffs'  || active_menu==='staff')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Group />
                </ListItemIcon>
                <ListItemText primary={'Staffs'} />
              </ListItemButton>
          </ListItem>
          }
          {(AppUtils.has_access(state,'4_attendence') && AppUtils.has_access(state,'4_attendence_view')) &&
            <ListItem key={'Attendence'} disablePadding  onClick={() => handleSelectMenu('Attendence','attendence')} selected={(active_menu==='attendence')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <EditNote />
                </ListItemIcon>
                <ListItemText primary={'Attendence'} />
              </ListItemButton>
            </ListItem>
          }
          {(AppUtils.has_access(state,'5_payments') && AppUtils.has_access(state,'5_payments_view')) &&
            <ListItem key={'Payments'} disablePadding  onClick={() => handleSelectMenu('Payments','payments','',true)} selected={(active_menu==='payments' || active_menu==='payment')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={'Payments'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'6_exam') && AppUtils.has_access(state,'6_exam_view')) &&
            <ListItem key={'Exam'} disablePadding  onClick={() => handleSelectMenu('Exam','exam')} selected={(active_menu==='exam')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <NoteAlt />
                </ListItemIcon>
                <ListItemText primary={'Exam'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'11_transport') && AppUtils.has_access(state,'11_transport_view')) &&
            <ListItem key={'Transport'} disablePadding  onClick={() => handleSelectMenu('Transport','transports','',true)} selected={(active_menu==='transports' || active_menu==='transport')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <DirectionsBus />
                </ListItemIcon>
                <ListItemText primary={'Transport'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'13_reports') && AppUtils.has_access(state,'13_reports_view')) &&
            <ListItem key={'Reports'} disablePadding  onClick={() => handleSelectMenu('Reports','reports')} selected={(active_menu==='reports')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={'Reports'} />
              </ListItemButton>
            </ListItem>
            }
            <Divider />
            {(AppUtils.has_access(state,'300_homework') && AppUtils.has_access(state,'300_homework_view')) &&
            <ListItem key={'Homework'} disablePadding  onClick={() => handleSelectMenu('Homework','homework')} selected={(active_menu==='homework')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Notes />
                </ListItemIcon>
                <ListItemText primary={'Homework'} />
              </ListItemButton>
            </ListItem>
            }
            
            {(AppUtils.has_access(state,'17_user_management') && AppUtils.has_access(state,'17_user_management_view')) &&
            <ListItem key={'User Management'} disablePadding  onClick={() => handleSelectMenu('User Management','user_management')} selected={(active_menu==='user_management')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <VerifiedUser />
                </ListItemIcon>
                <ListItemText primary={'User Management'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'170_leave_requests') && AppUtils.has_access(state,'170_leave_requests_view')) &&
            <ListItem key={'Leave Requests'} disablePadding  onClick={() => handleSelectMenu('Leave Requests / Students','leave_requests','student')} selected={(active_menu==='leave_requests')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <MoveToInbox />
                </ListItemIcon>
                <ListItemText primary={'Leave Request'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'180_inbox') && AppUtils.has_access(state,'180_inbox_view')) &&
            <ListItem key={'Inbox'} disablePadding  onClick={() => handleSelectMenu('Inbox','inbox','')} selected={(active_menu==='inbox')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Inbox />
                </ListItemIcon>
                <ListItemText primary={'Inbox'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'190_reported_list') && AppUtils.has_access(state,'190_reported_list_view')) &&
            <ListItem key={'Reported Lists'} disablePadding  onClick={() => handleSelectMenu('Reported Lists','reported-list','')} selected={(active_menu==='reported-list')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Warning />
                </ListItemIcon>
                <ListItemText primary={'Reported Lists'} />
              </ListItemButton>
            </ListItem>
            }
             {(AppUtils.has_access(state,'200_notices') && AppUtils.has_access(state,'200_notices_view')) &&
            <ListItem key={'Notices'} disablePadding  onClick={() => handleSelectMenu('Notices','notices','')} selected={(active_menu==='notices')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Error />
                </ListItemIcon>
                <ListItemText primary={'Notices'} />
              </ListItemButton>
            </ListItem>
            }
          </List>
         
            {(AppUtils.has_access(state,'301_enquiry') && AppUtils.has_access(state,'301_enquiry_view')) &&
            <ListItem key={'Enquiry'} disablePadding  onClick={() => handleSelectMenu('Enquiry','enquiry')} selected={(active_menu==='enquiry')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <HelpCenter />
                </ListItemIcon>
                <ListItemText primary={'Enquiry'} />
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'302_certificates') && AppUtils.has_access(state,'302_certificates_view')) &&
            <ListItem key={'Certificates'} disablePadding  onClick={() => handleSelectMenu('Certificates','certificates')} selected={(active_menu==='certificates')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Receipt />
                </ListItemIcon>
                <ListItemText primary={'Certificates/Forms'}/>
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'304_occasion_publish') && AppUtils.has_access(state,'304_occasion_publish_view')) &&
            <ListItem key={'Occasion Publish'} disablePadding  onClick={() => handleSelectMenu('Occasion Publish','occasion_publish')} selected={(active_menu==='occasion_publish')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Celebration />
                </ListItemIcon>
                <ListItemText primary={'Schedule Occasion'}/>
              </ListItemButton>
            </ListItem>
            }
            {(AppUtils.has_access(state,'303_library') && AppUtils.has_access(state,'303_library_view')) &&
            <ListItem key={'Library'} disablePadding  onClick={() => handleSelectMenu('Library','library')} selected={(active_menu==='library')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <LibraryBooks />
                </ListItemIcon>
                <ListItemText primary={'Library'}/>
              </ListItemButton>
            </ListItem>
            }
           {/*  {(AppUtils.has_access(state,'305_inventory') && AppUtils.has_access(state,'305_inventory_view')) &&
            <ListItem key={'Inventory'} disablePadding  onClick={() => handleSelectMenu('Inventory','inventory')} selected={(active_menu==='inventory')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <Inventory />
                </ListItemIcon>
                <ListItemText primary={'Inventory'}/>
              </ListItemButton>
            </ListItem>
            } */}
          <Divider />
          <List>
            {(AppUtils.has_access(state,'20_settings') && AppUtils.has_access(state,'20_settings_view')) &&
          
              <ListItem key={'Settings'} disablePadding  onClick={() => handleSelectMenu('Settings','settings','')} selected={(active_menu==='settings')?true:false}>
                <ListItemButton>
                  <ListItemIcon sx={{minWidth:'36px'}}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Settings'} />
                </ListItemButton>
              </ListItem>
            }
            {(AppUtils.has_access(state,'31_profile') && AppUtils.has_access(state,'31_profile_view')) &&
              <ListItem key={'Profile'} disablePadding  onClick={() => handleSelectMenu('Profile','profile')} selected={(active_menu==='profile')?true:false}>
                <ListItemButton>
                  <ListItemIcon sx={{minWidth:'36px'}}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Profile'} />
                </ListItemButton>
              </ListItem>
            }
            <ListItem key={'Logout'} disablePadding  onClick={() => handleSelectMenu('Logout','logout','',false)} selected={(active_menu==='logout')?true:false}>
              <ListItemButton>
                <ListItemIcon sx={{minWidth:'36px'}}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Box>
            {(AppUtils.has_access(state,'1_dashboard') && AppUtils.has_access(state,'1_dashboard_view')) &&
              <>
                {(active_menu==='dashboard') &&
                  <AppDashboard />
                }
              </>
            }
            {(AppUtils.has_access(state,'2_students') && AppUtils.has_access(state,'2_students_view')) &&
            <>
              {(active_menu==='students') &&
                <StudentList />
              }
              {(active_menu==='student' && sub_menu==='add') &&
                <CreateStudent />
              }
              {(active_menu==='student' && (sub_menu==='edit' || menu_label==='Update Student')) &&
                <CreateStudent student_data={student_info} />
              }
              {(active_menu==='student' && (sub_menu==='view' || menu_label==='Student Profile')) &&
                <StudentProfile student_data={student_info} />
              }
            </>
            }
            {(AppUtils.has_access(state,'3_staffs') && AppUtils.has_access(state,'3_staffs_view')) &&
              <>
                {(active_menu==='staffs') &&
                  <StaffList />
                }
                {(active_menu==='staff' && sub_menu==='add') &&
                  <CreateStaff />
                }
                {(active_menu==='staff' && (sub_menu==='edit' || menu_label==='Update Staff')) &&
                  <CreateStaff  staff_data={staff_info}/>
                }
                {(active_menu==='staff' && (sub_menu==='view' || menu_label==='Staff Profile')) &&
                  <StaffProfile staff_data={staff_info} />
                }
              </>
            }
            {(AppUtils.has_access(state,'4_attendence') && AppUtils.has_access(state,'4_attendence_view')) &&
            <>
              {(active_menu==='attendence') &&
                <Attendence />
              }
            </>
            }
              
              {(AppUtils.has_access(state,'20_settings') && AppUtils.has_access(state,'20_settings_view')) &&
                <>
                  {(active_menu==='settings') &&
                    <Settings />
                  }
                </>
              }
              {(AppUtils.has_access(state,'5_payments') && AppUtils.has_access(state,'5_payments_view')) &&
              <>
                {(active_menu==='payments') &&
                  <PaymentHome />
                }
                {(active_menu==='payment' && (sub_menu==='add' || sub_menu==='add/demand-bill') ) &&
                  <CreatePayment is_demand_bill={(sub_menu==='add/demand-bill')?true:false}/>
                }
                {(active_menu==='payment' && (sub_menu.match('convert')) ) &&
                  <CreatePayment is_convert_to_payment={true} id={sub_menu.match(/\d+/)[0]}/>
                }
                {(active_menu==='payment' && (sub_menu==='edit' || menu_label==='Update Payment' || menu_label==='Update Demand Bill')) &&
                  <CreatePayment is_demand_bill={(sub_menu.match('edit/demand-bill'))?true:false} payment_data={payment_info} />
                }
                {(active_menu==='payment' && (sub_menu==='view' || menu_label==='View Payment')) &&
                  <ViewPayment  payment_data={payment_info} />
                }
                {(active_menu==='payment' && (menu_label==='View Demand Bill')) &&
                  <ViewDemandBill  payment_data={payment_info} />
                }
              </>
              }
              {(AppUtils.has_access(state,'31_profile') && AppUtils.has_access(state,'31_profile_view')) &&
              <>
                {(active_menu==='profile') &&
                <>
                {(role_id===1) &&
                  <SchoolProfile />
                }
                {(role_id!==1 && role_id!=='') &&
                  <UserProfile />
                }
                </>
                }
              </>
              }
              {(AppUtils.has_access(state,'6_exam') && AppUtils.has_access(state,'6_exam_view')) &&
              <>
              {(active_menu==='exam') &&
                <Exam />
              }
              </>
              }
              {(AppUtils.has_access(state,'13_reports') && AppUtils.has_access(state,'13_reports_view')) &&
              <>
              {(active_menu==='reports') &&
                <Reports />
              }
              </>
              }
              {(AppUtils.has_access(state,'300_homework') && AppUtils.has_access(state,'300_homework_view')) &&
                <>
                  {(active_menu==='homework') &&
                    <Homework />
                  }
                </>
              }
              {(AppUtils.has_access(state,'11_transport') && AppUtils.has_access(state,'11_transport_view')) &&
              <>
              {(active_menu==='transports') &&
                <Transport />
              }
              {(active_menu==='transport' && sub_menu==='add') &&
                <CreateTransport />
              }
              {(active_menu==='transport' && (sub_menu==='edit' || menu_label==='Update Transport')) &&
                <CreateTransport  transport_data={transport_info} />
              }
              </>
              }
              {(AppUtils.has_access(state,'17_user_management') && AppUtils.has_access(state,'17_user_management_view')) &&
                <>
                  {(active_menu==='user_management') &&
                    <UserManagement />
                  }
                </>
              }
               {(AppUtils.has_access(state,'170_leave_requests') && AppUtils.has_access(state,'170_leave_requests_view')) &&
                <>
                  {(active_menu==='leave_requests') &&
                    <LeaveRequest/>
                  }
                </>
              }
               {(AppUtils.has_access(state,'180_inbox') && AppUtils.has_access(state,'180_inbox_view')) &&
                <>
                  {(active_menu==='inbox') &&
                    <AppInbox/>
                  }
                </>
                }
                {(AppUtils.has_access(state,'190_reported_list') && AppUtils.has_access(state,'190_reported_list_view')) &&
                <>
                  {(active_menu==='reported-list') &&
                    <ReportedList/>
                  }
                </>
                }
                {(AppUtils.has_access(state,'200_notices') && AppUtils.has_access(state,'200_notices_view')) &&
                <>
                  {(active_menu==='notices') &&
                    <Notices/>
                  }
                </>
                }
                {(AppUtils.has_access(state,'301_enquiry') && AppUtils.has_access(state,'301_enquiry_view')) &&
                <>
                  {(active_menu==='enquiry') &&
                    <Enquiry />
                  }
                </>
                }
                {(AppUtils.has_access(state,'302_certificate') && AppUtils.has_access(state,'302_certificate_view')) &&
                <>
                  {(active_menu==='certificates') &&
                    <SchoolCertificates />
                  }
                </>
                }
                {(AppUtils.has_access(state,'304_occasion_publish') && AppUtils.has_access(state,'304_occasion_publish_view')) &&
                <>
                  {(active_menu==='occasion_publish') &&
                    <OccassionPublish />
                  }
                </>
                }
                {(AppUtils.has_access(state,'303_library') && AppUtils.has_access(state,'303_library_view')) &&
                <>
                  {(active_menu==='library') &&
                    <SchoolLibrary />
                  }
                </>
                }
                {(AppUtils.has_access(state,'305_inventory') && AppUtils.has_access(state,'305_inventory_view')) &&
                <>
                  {(active_menu==='inventory') &&
                    <SchoolInventory />
                  }
                </>
                }
              
          </Box>
        </Main>
      </Box>
    }
    {(is_show_website===false && is_logged_in===false && isAdmin===false) &&
      <>
      {(active_menu==='login' || active_menu==='') &&
        <Login />
      }
      {(active_menu==='signup') &&
        <Signup />
      }
      </>
    }
    {(is_show_website===false && isAdmin===true) &&
      <AdminMain />
    }
    {(is_show_website===true && is_logged_in===false) &&
      <Login />
    }
    </>
  );
}
export default MainComponent;