import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Textarea } from '@mui/joy';
import PrintHeader from '../PrintHeader';
import APP_HTTP from '../../APP_HTTP';

export default function OfferLetter() {
  let msg_body = "Teacher Name \nTeacher Address City, \n\nDear Teacher Name, \nWe are pleased to offer you the position of Teacher, at [School Name], effective [Start Date]. This appointment is contingent upon the terms and conditions outlined below. You will serve as a [Position Title], responsible for [responsibilities]. This is an ongoing position, beginning on [Start Date], and subject to the school\u2019s policies. Your compensation will be an annual salary of [Salary Amount], paid monthly on the last working day of each month. Additionally. Normal working hours are [Start Time] to [End Time], [days of the week], with potential additional hours or event attendance as required by your role. You are entitled to [number] days of paid leave annually, plus public holidays, pending supervisor approval. The school supports professional development through training programs, workshops, and conferences, subject to approval and budget considerations. High standards of professional conduct are expected, aligning with school policies and regulations. Con\ufb01dentiality regarding proprietary and sensitive information about the school and its students is mandatory. Employment may be terminated by either party with [Notice Period] written notice, or immediately by the school for cause, per our policies. Please sign and return the enclosed copy of this letter to con\ufb01rm your acceptance of this offer and its terms. We look forward to welcoming you to [School Name].\nSincerely, \n[Name of School Administrator] \n[Title] \n[School Name]                                                                               [Signature]";
  const dispatch = useDispatch();
  const [school_info, setSchoolInfo] = React.useState({});
  const [message_body, setMessage] = React.useState(msg_body);
  const [message_title, setMessageTitle] = React.useState('OFFER LETTER');
  const state = useSelector((state) => state);

  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
        initialized.current = true;
        handleGetSchoolInfo();
        handleGetOfferLetterBody();
      }
  },[])
  const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}
  const handleGetSchoolInfo = ()=>{
    APP_HTTP.REQUEST('school/get_school_info',{},true,true).then((response) => {
        let resp = response.data;
        let sinfo = resp.response;
        setSchoolInfo(sinfo)
    });
  }
  const handlePrint = ()=>{
    if(document.getElementById("offer-letter-print-block")){
        var divContents = document.getElementById("offer-letter-print-block").innerHTML; 
      
      
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
        
          a.document.write('<style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top} input{border:1px solid #333}.letter-content-body{display:none}.print-letter-content-body{display:block}</style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
       
      }catch{}
    }
   }
  const handleChangeMesasge = (message) =>{
    setMessage(message);
     APP_HTTP.REQUEST('settings/save_option',{name:'offer_letter_body',value:message},true,true).then((response) => {
      
    });
  }
  const handleChangeTitle = (message) =>{
      setMessageTitle(message);
     APP_HTTP.REQUEST('settings/save_option',{name:'offer_letter_title',value:message},true,true).then((response) => {
      
    });
  }
  const handleGetOfferLetterBody = ()=>{
     APP_HTTP.REQUEST('settings/get_option',{name:'offer_letter_body'},true,true).then((response) => {
        let resp = response.data.response;
        if(resp.value){
            setMessage(resp.value);
        }
    });
     APP_HTTP.REQUEST('settings/get_option',{name:'offer_letter_title'},true,true).then((response) => {
        let resp = response.data.response;
        if(resp.value){
          setMessageTitle(resp.value);
        }
    });
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Button variant='contained' size='small' onClick={()=>handlePrint()}>PRINT LETTER</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <div style={{border:'1px solid #333'}} id="offer-letter-print-block" >
                <table cellPadding={5} cellSpacing={0} style={{width:'100%'}}>
                <PrintHeader header_data={school_info}/>  
                <tbody>
                    <tr>
                        <th style={{textAlign:'center'}}>
                            <input type="text" value={message_title} onChange={(e)=>handleChangeTitle(e.target.value)} style={{fontSize:'24px',border:'none',textAlign:'center',width:'100%'}}/>
                        </th>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px'}}>
                           <div className='print-letter-content-body'><p style={{padding: '10px',fontFamily: "sans-serif",fontSize: '16px',lineHeight: '2'}} dangerouslySetInnerHTML={{ __html: nl2br(message_body) }}></p></div>
                           <textarea  className="letter-content-body" style={{width:'100%',minHeight:'750px',fontFamily:'sans-serif',fontSize: '16px',lineHeight: 2, border: 'none'}} id="message-body" value={message_body} onChange={(e)=>handleChangeMesasge(e.target.value)}></textarea>
                        </td>
                    </tr>
                </tbody>
                </table>
                </div>
            </Grid>
        </Grid>
    </Box>
  );
}