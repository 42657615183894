import * as React from 'react';
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../../APP_HTTP.jsx';
import PropTypes from 'prop-types';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { Check, CheckCircle, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };
const StartNewSession = (props) => {
  let messages = [
    {key:'back_dues',value:'Calculates BACK DUES and will assign to the respective student.',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'payment_sequence',value:'Payment SEQUENCE will reset and starts to 1 (i.e #PYI1,#PYO2).',is_checked:true,is_processed:false,is_processing:false,percent:0},
    /* {key:'student_attendance',value:'Clear student ATTENDANCE HISTORY',is_checked:false,is_processed:false,is_processing:false,percent:0},
    {key:'homework',value:'Clear assigned HOMEWORK HISTORY',is_checked:false,is_processed:false,is_processing:false,percent:0},
    {key:'exam',value:'Clear exam related data such as scheduled exam, marks entry',is_checked:false,is_processed:false,is_processing:false,percent:0},
    {key:'class_section_shifting',value:'Assign NEXT CLASS/SECTION to the student (i.e 1 to 2, 2 to 3 etc.)',is_checked:true,is_processed:false,is_processing:false,percent:0},*/
    {key:'fee_structure',value:'All student FEE STRUCTURE will reset and will automatically assign as per their respective class fee.',is_checked:true,is_processed:false,is_processing:false,percent:0},
    {key:'reset_financial_month',value:'Reset financial month to April.',is_checked:true,is_processed:false,is_processing:false,percent:0},
  ];
  const [message_list,setMessageList] = React.useState(messages);
  const [user_credential,setUserCredential] = React.useState({username:'',password:''});
  const [step,setStep] = React.useState(1);
  const [student_count,setStudentCount] = React.useState(0);
  const [is_open,setIsOpen] = React.useState(false);
  const handleSelectItem = (index,checked) =>{
    let msglist = [...message_list];
    msglist[index].is_checked = checked;
    setMessageList(msglist);
  }
  const handleProceed = (st) =>{
    setStep(st);
  }
  const handleChangeCredentials = (key,value) =>{
    let uc = {...user_credential};
    uc[key]=value;
    setUserCredential(uc);
  }
  const handleStartNewSession = () =>{
    processStartNewSession();
  }
  const processStartNewSession = () => {
    let process_key = '';
    for (let index = 0; index < messages.length; index++) {
      const element = messages[index];
      let reset_type = element.key;
      let is_checked = element.is_checked;
      if(is_checked===true){
        process_key = reset_type;
        break;
      }
    }
    if(process_key!==''){
      sendProcessRequestToServer(process_key,1);
    }else{
      // here completed process
    }
  }
  const sendProcessRequestToServer = ( process_key, page ) => {
    APP_HTTP.REQUEST('settings/restart_new_session',{process_key:process_key,page:page},true,true).then((response) => {
      if(page<=student_count && response.data.success==1){
        page = page+1;
        sendProcessRequestToServer = ( process_key, page )
      }else{
        processStartNewSession();
      }
  });
  }
  const handleGetStudentCount = () =>{
    APP_HTTP.REQUEST('dashboard/get_student_count',{},true,true).then((response) => {
        let resp = response.data;
        let total = resp.total;
        setStudentCount(total);
    });
  }
  const handleClose = ()=>{
    setIsOpen(false);
  }
  const handleOpenSession = ()=>{
    handleGetStudentCount();
    setIsOpen(true);
  }
  return (
    <>
        <Button variant='contained' color='success' onClick={handleOpenSession}>START A NEW SESSION</Button>
        <Dialog open={is_open} >
            
            <DialogTitle>What will happen if you start a new session?</DialogTitle>
            
            <DialogContent>
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(step===1) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            {(message_list.map((value,index)=>{
                                return(
                                    <>
                                        <Typography style={{display:'inline-flex', fontFamily:'monospace'}}><CheckCircle fontSize='small' color='success' style={{marginRight:'5px'}}/> {value.value}</Typography>
                                    </>
                                )
                            }))}
                        </Grid>
                    }
                    {(step===2) &&
                    <>
                        {(message_list.map((value,index)=>{
                            return(
                                <>
                                {(value.is_checked===true) &&
                                <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    <Alert variant="outlined" severity="success" iconMapping={{
    success: <CircularProgressWithLabel value={(value.is_processing===true)?value.percent:0} />
  }}>
                                    {value.value}
                                    </Alert>
                                </Grid>
                                }
                                </>
                            )
                        }))}
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Username"
                                size="small"
                                fullWidth
                                onChange={(e)=>handleChangeCredentials('username',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Password"
                                size="small"
                                fullWidth
                                type='password'
                                onChange={(e)=>handleChangeCredentials('password',e.target.value)}
                            />
                        </Grid>
                    </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
            {(step===1) &&
            <>
                <Button onClick={handleClose}>Close</Button>
                <Button variant='contained' color='error' onClick={()=>handleProceed(2)}>Next <KeyboardDoubleArrowRight/></Button>
            </>
            }
            {(step===2) &&
            <>
                <Button onClick={()=>handleProceed(1)}><KeyboardDoubleArrowLeft /> Back</Button>
                <Button variant='contained' color='error' onClick={()=>handleStartNewSession()}>Start A New Session</Button>
            </>
            }
            </DialogActions>
        </Dialog>
    </>
  );
}

export default StartNewSession;