import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { AppBar, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, RadioGroup, Skeleton, Toolbar, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import LoaderButton from '../LoaderButton';
import BasicDatePicker from '../DatePicker';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleSetPaymentInfo, handleToggleEditPayment, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import Autocomplete from '@mui/material/Autocomplete';
import Sections from '../settings/sections/Sections';
import Classes from '../settings/classes/Classes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowRight, CheckCircleOutlineRounded, CheckRounded, Close, CreditCard, CurrencyRupee, Delete, Edit, KeyboardDoubleArrowRight, PhoneAndroid } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
import AppCheckbox from '../AppCheckbox';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import { Textarea } from '@mui/joy';
import StudentCard from '../students/StudentCard';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
let curl = window.location.href;

const payment_on_load = curl.match(/payment\/add\/([payment_in|paymnet_out|staff_advance]+$)/i);
let loaded_payment_type = 'payment_in';

const PayInstantly = (props)=> {
    let paymentDataInitialState = {
        update_id : 0,
        payment_sequence : '',
        payment_type : loaded_payment_type,
        payment_date : '',
        payment_contact : '',
        list_item : '',
    }
    let paymentItemListInitialState = {
        payment_meta_id:0,
        payment_mode:(props.is_demand_bill===true)?5:1,
        particulars:(loaded_payment_type==='staff_advance')?0:'',
        particulars_name:'',
        reference:'',
        amount:'',
    }
    let item_index = 0;
    let [payment_item_list,setPaymentItemList] = React.useState([paymentItemListInitialState]);
    let [payee_payer_list,setPayeePayerList] = React.useState([]);
    let [payment_id,setPaymentId] = React.useState(0);
    let [is_show_opt_button,setIsShowOptButton] = React.useState(true);
    let [is_show_instant_payment,stIsShowInstantPayment] = React.useState(false);
    let [student_advance_payment,setStudentAdvancePayment] = React.useState(0);
    let [total_staff_advance_payment,setStaffTotalAdvanceAmount] = React.useState(0);
    let [staff_advance_payment_list,setStaffAdvancePaymentList] = React.useState([]);
    let [payment_total_amount,setTotalAmount] = React.useState(0);
    let [fee_dues,setFeeDues] = React.useState(0);
    let [payment_list_type,setDemandGenerateListType] = React.useState('detailed');
    let [demand_bill_generate_type,setDemandGenerateFamilyType] = React.useState('single');
    let [self_group_type,setSelfGroupType] = React.useState('family');
    let [discount_amount,setDiscount] = React.useState(0);
    let [late_fine,setlateFine] = React.useState(0);
    let [payment_paid_amount,setTotalPaid] = React.useState(0);
    let [payment_date,setPaymentDate] = React.useState(new Date());
    let [month_date,setMonthDate] = React.useState(new Date());
    let [pending_payments,setPendingPayments] = React.useState([]);
    let [self_pending_payments,setSelfPendingPayments] = React.useState([]);
    let [student_fee_structure,setStudentFeeStructure] = React.useState({});
    let [contact_info,setContactInfo] = React.useState([]);
    let [current_sequence_arr,setCurrentSequenceArr] = React.useState([]);
    let [repeat_db,setIsRepeat] = React.useState(false);
    let [is_calculating,setIsCalculating] = React.useState(false);
    let [is_refresh_list,setIsRefreshing] = React.useState(false);
    let [selected_payment_mode,setSelectedPaymentMode] = React.useState(1);
    let [remarks,setRemarks] = React.useState('');
    let [is_selecting_yearly_package,setIsSelectingYearlyPackage] = React.useState(false);
    let [is_selecting_uniform,setIsSelectingUniform] = React.useState(false);
    const [paid_payments_list,setPaidPaymentsList] = React.useState([]);
    const [demand_bill_list,setDemandBillList] = React.useState([]);
    const [is_loading_demand_bill,setIsLoadingDB] = React.useState(true);
    const [is_loading_payments,setIsLoadingPayments] = React.useState(true);
    let [is_show_save_payment,setShowSavePayment] = React.useState(false);
    let [is_show_payment_list,setShowPaymentList] = React.useState(false);
    let [is_selected_yearly_package,setYearlyPackage] = React.useState(false);
    let [payment_type,setPaymentType] = React.useState('payment_in');
    let [payer_type,setPayerType] = React.useState('Student');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [is_redeem_advance_payment,setIsRedeemAdvancePayment] = React.useState(false);
    let [selected_session,setSession] = React.useState(0);
    let [selected_class,setClass] = React.useState(0);
    let [selected_section,setSection] = React.useState(0);
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    let [isShowExistingTemplate,setShowTemplate] = React.useState(false);
    let [is_process_rcvd_fee,setIsProcessingRcvdFee] = React.useState(false);
    let [is_selecting_full_payment,setIsSelectingFullPayment] = React.useState(false);
    let [is_full_payment,setIsFullPayment] = React.useState(false);
    let [existingTemplate,setExistingTemplate] = React.useState([]);
    let [receivedFee,setReceivedFee] = React.useState('');
    let [totalPendingAmount,setTotalPendingAmount] = React.useState('');
    let [is_selecting_manually,setIsManually] = React.useState(false);
    let [is_convert_to_pay,setIsConvertToPay] = React.useState(false);
    let [selected_db_id,setSelectedDBID] = React.useState(0);
    let [total_payable_fee,setPayableFee] = React.useState(0);
    let [payment_mode_data,setPaymentModeData] = React.useState({});
    let [from_payment_type,setFromPaymentType] = React.useState('student');
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const dispatch = useDispatch();
    const payment_modes = state.payment_modes;
    const payment_info = state.payment_info;
    const payment_type_list = state.payment_type_list;
    const initialized = React.useRef(false);
    const handleResetPaymentScreen = ()=>{
      let paymentDataInitialState = {
        update_id : 0,
        payment_sequence : '',
        payment_type : loaded_payment_type,
        payment_date : '',
        payment_contact : '',
        list_item : '',
    }
    let paymentItemListInitialState = {
        payment_meta_id:0,
        payment_mode:(props.is_demand_bill===true)?5:1,
        particulars:'',
        particulars_name:'',
        reference:'',
        amount:'',
    }
    setPaymentItemList([paymentItemListInitialState]);
    setPaymentId(0);
    setStudentAdvancePayment(0);
    setTotalAmount(0);
    setFeeDues(0);
    setTotalPaid(0);
    setPendingPayments([]);
    setSelfPendingPayments([]);
    setStudentFeeStructure({});
    setRemarks('');
    setPaidPaymentsList([]);
    setDemandBillList([]);
    setPaymentData(paymentDataInitialState);
    setReceivedFee('');
    setTotalPendingAmount('');
    setPayableFee(0);
    }
 
    
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handlePayInstantly();
        }
    },[])
  const handleSaveInformation = (type) =>{
    if(type==='view'){
      localStorage.setItem('is_print','true');
    }
    let pmdata = {...payment_mode_data};
    let is_empty = true;
    for (const key in pmdata) {
      let paid_amount = pmdata[key];
      if(paid_amount!=="" && parseFloat(paid_amount)>0){
        is_empty = false;
      }
    }
    if(is_empty===true){
      if(document.getElementById('payment-mode-0')){
        document.getElementById('payment-mode-0').focus();
      }
      return false;
    }
    setIsSaveClicked(true);
    let short_month = month_date.toLocaleString('default', { month: 'short' });
    short_month = short_month.toLowerCase();
    let year = month_date.getFullYear();
    let plist = [];
    for (let index = 0; index < payment_item_list.length; index++) {
      const element = payment_item_list[index];
      if(element.fee_month===undefined){
        element.fee_month = short_month;
        element.fee_year = year;
      }
      if(element.fee===undefined){
        element.fee = element.amount;
      }
      plist.push(element);
    }
    let sendData = {
      is_repeat : repeat_db,
      update_id : payment_id,
      payment_sequence : current_sequence,
      payment_mode : selected_payment_mode,
      remarks : remarks,
      payment_type : payment_type,
      payment_date : payment_date,
      month_date : month_date,
      contact_info : contact_info,
      payable_amount : total_payable_fee,
      payment_total_amount : payment_paid_amount,
      fee_dues : fee_dues,
      discount_amount : discount_amount,
      payment_item_list : plist,
      payment_mode_data : payment_mode_data,
      late_fine : late_fine,
      is_demand_bill : (props.is_demand_bill===true)?true:false,
      is_convert_to_payment : (props.is_convert_to_payment===true)?props.id:0,
      staff_advance_payment_list : staff_advance_payment_list,
      total_staff_advance_payment : total_staff_advance_payment,
      is_full_payment : is_full_payment,
      self_group_type : self_group_type
    }
    if(payment_type!=='staff_advance'){
      APP_HTTP.REQUEST('payment/save_payment',sendData,true,true).then((response) => {
          let resp = response.data;
          let id = resp.id;
          setIsSaveClicked(false);
          if(props.is_demand_bill===true){
            window.location.href = '/payment/view/demand-bill/'+id;
          // dispatch(handleChangeMenu('payment','view/demand-bill/'+id,'View Demand Bill',false));
          }else{
            if(type==='view'){ 
              window.location.href = '/payment/view/'+id;
            }else{
              window.location.href = '/payment/add/'+payment_type;
            }
            //dispatch(handleChangeMenu('payment','view/'+id,'View Payment',false));
          }
          dispatch(handleToggleViewPayment(true));
      });
    }else{
      APP_HTTP.REQUEST('payment/save_staff_advance',sendData,true,true).then((response) => {
        let resp = response.data;
        let id = resp.id;
        setIsSaveClicked(false);
        if(type==='list'){
          window.location.href = '/payments/staff_advance';
        }else{
          window.location.href = '/payment/add/'+payment_type;
        }
        dispatch(handleToggleViewPayment(true));
    });
    }
  }
  const handleChangeData = (index,id,value) =>{
    let pilist = [...payment_item_list];
    pilist[index][id] = value;
    if(id==='quantity'){
      let fee = pilist[index]['fee'];
      if(value===""){
        value = 0;
      }
      let amount = fee * value;
      pilist[index]['amount'] = amount;
    }
    handleSetPaymentList(pilist);
  }
  const handleClosePaymentPopup = () =>{
    stIsShowInstantPayment(false);
    props.handleCloseInstantPay();
  }
  
  const handleLoadPendingPayments = (payment_type,value,type) =>{
    setIsSelectingFullPayment(true);
    if(value===null || value.id===undefined){
      return false;
    }
    
      APP_HTTP.REQUEST('payment/get_student_pending_payments',{student:value.id,target:'instant',type:type},true,true).then((response) => {
          let fee_struct = response.data.fee_structure;
          setStudentFeeStructure(fee_struct);
          let resp = response.data.response;
          if(resp[0]!==undefined){
            let list_items = resp[0].list_item;
            if(resp[0].self_list_item!==undefined){
                if(type==='single'){
                   // list_items = resp[0].self_list_item;
                }
            }
            setIsRefreshing(false);
            setIsSelectingFullPayment(false);
            setPendingPayments(list_items);
            let pending_amount = 0;
            for (let index = 0; index < list_items.length; index++) {
              const element = list_items[index];
              pending_amount = pending_amount + parseFloat(element.fee)
            }
            setTotalPendingAmount(pending_amount)
          }
      }); 
     /*  APP_HTTP.REQUEST('payment/get_student_advance_payment',{student_id:value.id},true,true).then((response) => {
          let adv_payment = response.data.response;
          setStudentAdvancePayment(adv_payment)
      });  */
      //handleLoadDemandBill(value.id);
      if(type==='family'){
          handleLoadPaidPayments(value.id);
        }
    
  }
  const handleLoadPaidPayments = (id) =>{
    setIsLoadingPayments(true);
    APP_HTTP.REQUEST('payment/get_student_payments',{id:id},true,true).then((response) => {
        let resp = response.data.response;
        setPaidPaymentsList(resp);
        setIsLoadingPayments(false)
    });
  }
  const handleLoadDemandBill = (id) =>{
    
    APP_HTTP.REQUEST('payment/get_demand_bill_on_student_id',{student_id:id,status:1},true,true).then((response) => {
        let resp = response.data.response;
        setDemandBillList(resp);
        setIsLoadingDB(false)
    });
  }
  
  const handleSetPaymentList = (pilist) =>{
    setPaymentItemList(pilist);
    handleSetTotalAmount(pilist,false);
  }
  const handleSetTotalAmount = (pilist,is_discount=false)=>{
    let total_amount = 0;
    for (let index = 0; index < pilist.length; index++) {
      const element = pilist[index];
      let amount = element.amount;
      if(amount!==""){
        amount = parseFloat(amount);
        total_amount = total_amount+amount;
      }
    }
    setPayableFee(total_amount);
  }
  const addMorePaymentItem = () =>{
    let pilist = [...payment_item_list];
    pilist.push(paymentItemListInitialState);
    handleSetPaymentList(pilist);
  }
  const handleRemoveItem = (index,pending_index) =>{
    setIsRefreshing(true);
    let plist = [...payment_item_list];
   
    if(payment_list_type!=='detailed'){
      let part_info = plist[index];
      if(parseFloat(part_info.particulars)===3){
        if(plist.length>1){
          plist.splice(index,1);
          handleSetPaymentList(plist);
        }else{
          handleSetPaymentList([paymentItemListInitialState]);
        }
        if(pending_index!==undefined){
          let pen_payment = [...pending_payments];
          if(pen_payment[pending_index].selected!==undefined){
            pen_payment[pending_index].selected = false;
          }
          setPendingPayments(pen_payment);
        }
        let is_rmv = false;
        let i = plist.length;
        while (i--) {
            if (parseFloat(plist[i].fee) ===0) {
              plist.splice(i, 1);
            }
        }
        if(is_rmv===true){
          handleSetPaymentList(plist);
        }
      }else{
        if(plist.length>1){
          plist.splice(index,1);
          handleSetPaymentList(plist);
        }else{
          handleSetPaymentList([paymentItemListInitialState]);
        }
        if(pending_index!==undefined){
          let pen_payment = [...pending_payments];
          if(pen_payment[pending_index].selected!==undefined){
            pen_payment[pending_index].selected = false;
          }
          setPendingPayments(pen_payment);
        }
      }
    }else{
      if(plist.length>1){
        plist.splice(index,1);
        handleSetPaymentList(plist);
      }else{
        handleSetPaymentList([paymentItemListInitialState]);
      }
      if(pending_index!==undefined){
        let pen_payment = [...pending_payments];
        if(pen_payment[pending_index].selected!==undefined){
          pen_payment[pending_index].selected = false;
        }
        setPendingPayments(pen_payment);
      }
    }
    setTimeout(() => {
      setIsRefreshing(false);
    }, 5);
  }
   
  const handleChangePaymentMode = (id,value) =>{
    setSelectedPaymentMode(value);
  }
  const handleChangeParticulars = (id,value,moreValue) =>{
    let pilist = [...payment_item_list];
    let fee_structure = {...student_fee_structure};
    let fee = '';
    let type_name = '';
    let short_month = month_date.toLocaleString('default', { month: 'short' });
    short_month = short_month.toLowerCase();
    if(fee_structure[value]!==undefined && fee_structure[value][short_month]!==undefined){
      fee = fee_structure[value][short_month];
    }
    if(fee===""){
      for (let index = 0; index < payment_type_list.length; index++) {
        const element = payment_type_list[index];
        if(parseInt(element.id)===parseInt(value)){
          let type_name = element.name;
          let global_fee = element.global_fee;
          let class_fee = element.class_fee;
          if(selected_class>0){
            if(class_fee!==null && class_fee[selected_class]!==undefined && class_fee[selected_class]!==""){
              fee = class_fee[selected_class];
              break;
            }else if(global_fee!=="" && global_fee!==null){
              fee = global_fee;
              break;
            }
          }
        }
      }
    }
    if((parseInt(value)===3) && contact_info!==null && contact_info.id!==undefined && contact_info.id>0){
      if(contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)>0){
        fee = contact_info.opening_balance; 
      }
    }
    if(moreValue.is_salary!==undefined && moreValue.is_salary===true){
        let salary = contact_info.salary;
        fee = salary;
    }
    pilist[id]['is_uniform'] = false;
    if(moreValue.is_uniform_fee!==undefined && moreValue.is_uniform_fee===true){
      pilist[id]['is_uniform'] = true;
      pilist[id]['uniform_list'] = moreValue.uniform_list;
    }
    pilist[id]['particulars'] = value;
    pilist[id]['particulars_name'] = type_name;
    pilist[id]['fee'] = fee;
    pilist[id]['amount'] = fee;
    handleSetPaymentList(pilist);
  }
 
  const handleSetRemarks = (value) =>{
    setRemarks(value)
  }
  const handleSelectPendingPayment = (index) =>{
    let pilist = [...payment_item_list];
    let pen_payment = [...pending_payments];
    let pitem = pen_payment[index];
    if(payment_list_type!=='detailed'){
      if(parseInt(pitem.particulars)===3){
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          handleSetPaymentList([]);
          pilist = [];
          setTimeout(() => {
            for (let pmi = 0; pmi < pen_payment.length; pmi++) {
              let element = pen_payment[pmi];
              if(parseInt(element.fee)===0){
                element.payment_meta_id = 0;
                element.reference = '';
                element.pending_index = pmi;
                element.is_prev_fee = true;
                pilist.push(element);
                handleSetPaymentList(pilist);
              }
            }
            pilist.push(pitem);
            handleSetPaymentList(pilist);

            

          }, 10);
        }else{
          for (let pmi = 0; pmi < pen_payment.length; pmi++) {
            let element = pen_payment[pmi];
            if(parseInt(element.fee)===0){
              element.payment_meta_id = 0;
              element.reference = '';
              element.pending_index = pmi;
              element.is_prev_fee = true;
              pilist.push(element);
              handleSetPaymentList(pilist);
            }
          }
          pilist.push(pitem);
          handleSetPaymentList(pilist);
          
        }
        pen_payment[index].selected = true;
        setPendingPayments(pen_payment);
      }else{
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          handleSetPaymentList([]);
          pilist = [];
          setTimeout(() => {
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }, 10);
        }else{
          pilist.push(pitem);
          handleSetPaymentList(pilist);
        }
        pen_payment[index].selected = true;
        setPendingPayments(pen_payment);
      }
    }else{
      pitem.payment_meta_id = 0;
      pitem.reference = '';
      pitem.pending_index = index;
      if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
        handleSetPaymentList([]);
        pilist = [];
        setTimeout(() => {
          pilist.push(pitem);
          handleSetPaymentList(pilist);
        }, 10);
      }else{
        pilist.push(pitem);
        handleSetPaymentList(pilist);
      }
      pen_payment[index].selected = true;
      setPendingPayments(pen_payment);
    }
  }
  const handleChangeReceivedAmount = (value) =>{
    setReceivedFee(value);
  }
  const handleProceedToPayReceivedAmount = () =>{
    setIsProcessingRcvdFee(true);
    handleSetPaymentList([]);
    let p_payments = [...pending_payments];
    for (let index = 0; index < p_payments.length; index++) {
      p_payments[index].selected = false;
    }
    setPendingPayments(p_payments);
    setTimeout(() => {
      handle_process_receieved_payment();
    }, 10);
  }
  const handle_process_receieved_payment =() =>{
    let pilist = [];
    let p_payments = [...pending_payments];
    let total_paid_amount = 0
    if(receivedFee!=="" && receivedFee>0){
      let remaingFee = receivedFee;
      for (let index = 0; index < p_payments.length; index++) {
        const element = p_payments[index];
        let fee = parseFloat(element.fee);
        let pitem = p_payments[index];
        if(pitem.selected===true){
          continue;
        }
        remaingFee = remaingFee - fee;
        let payable = fee;
        if(remaingFee<0){
          payable =  fee + remaingFee;
        }
        total_paid_amount = total_paid_amount+payable;
        pitem.amount = payable;
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(payable>0){
          if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
            pilist = [];
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }else{
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }
          p_payments[index].selected = true;
          setPendingPayments(p_payments);
        }
        if(remaingFee<0){
          break;
        }
      }
      
      let totalAdvance = receivedFee - total_paid_amount;
      if(totalAdvance>0){
        
        let short_month = month_date.toLocaleString('default', { month: 'short' });
        short_month = short_month.toLowerCase();
        let year = month_date.getFullYear();
        let advance_item = {
            "payment_mode": 5,
            "particulars": 0,
            "fee_month": short_month,
            "fee_year": year,
            "fee": totalAdvance,
            "amount": totalAdvance,
            "particulars_name": "Advance",
            "payment_meta_id": 0,
            "reference": "",
            "pending_index": 10000,
            "selected": false
        };
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          pilist = [];
        }
        pilist.push(advance_item);
        handleSetPaymentList(pilist);
      }
    }
    setIsProcessingRcvdFee(false);
  }
  const handleChangeDiscount = (value) =>{
    let pilist = [...payment_item_list];
    setDiscount(value);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,value,late_fine);
  }
  const handleChangeLateFine = (value)=>{
    setlateFine(value);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,discount_amount,value);
  }
  const handleChangeUniform = (id,value,list_item) =>{
    let pilist = [...payment_item_list];
    pilist[id].amount = list_item.fee;
    pilist[id].fee = list_item.fee;
    pilist[id].reference = value;
    handleSetPaymentList(pilist);
  }
  
  const handleViewItem = (id)=>{
    if(AppUtils.has_access(state,'503_demand_bill_view')===false){
      return false;
    }
    let payment_info = demand_bill_list[id];
    let payment_id = payment_info.demand_bill_payment_id;
    dispatch(handleChangeMenu('payment','view/demand-bill/'+payment_id,'View Demand Bill',false));
    dispatch(handleToggleViewPayment(true));
    dispatch(handleSetPaymentInfo(payment_info));
  }
  const handleConvertToPayment = (index) =>{
    let plist = [...demand_bill_list];
    let id = plist[index].demand_bill_payment_id;
    window.location.href = '/payment/convert/'+id;
  }
  const handleChangePaymentModeData = (id,value)=>{
    let pmdata = {...payment_mode_data};
    pmdata[id] = value;
    setPaymentModeData(pmdata);
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,discount_amount,late_fine);
  }
  const handleCalculateFinalFee = (pmdata,discount,fine)=>{
    
    let total_paid = 0;
    for (const key in pmdata) {
      let paid_amount = pmdata[key];
      if(paid_amount!==""){
        total_paid = total_paid + parseFloat(paid_amount);
      }
    }
    if(discount===''){
      discount = 0;
    }
    let l_fee = fine;
    if(l_fee===''){
      l_fee = 0;
    }
    l_fee = parseFloat(l_fee);
    discount = parseFloat(discount);
    setTotalPaid(total_paid);
    total_paid = total_paid + discount;
    let final_payable = total_payable_fee;
    if(total_staff_advance_payment>0){
      final_payable = final_payable - total_staff_advance_payment;
    }
    let calculate_fee_dues = final_payable - (total_paid - l_fee);
    setFeeDues(calculate_fee_dues);
    setIsCalculating(false);
  }
  const handleChangeFromPaymentType = (type)=>{
    setFromPaymentType(type);
  }
  const handleSelectStaffAdvancePayment = (checked,idx) =>{
    let st_adv_pyment = [...staff_advance_payment_list];
    st_adv_pyment[idx].is_selected = checked;
    setStaffAdvancePaymentList(st_adv_pyment);
    let total_adv_amount = 0;
    for (let index = 0; index < st_adv_pyment.length; index++) {
      const element = st_adv_pyment[index];
      if(element.is_selected===true){
        let adv_paid_amount = parseFloat(element.paid_amount);
        total_adv_amount = total_adv_amount + adv_paid_amount;
      }
    }
    setStaffTotalAdvanceAmount(total_adv_amount);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    setTimeout(() => {
      handleCalculateFinalFee(pmdata,discount_amount,late_fine);
    }, 100);
  }
  const handlePayInstantly =()=>{
    let value = props.contact_info;
    let payment_type = 'payment_in';
    let pay_sequence = {};

    APP_HTTP.REQUEST('settings/get_current_sequence',{type:loaded_payment_type},true,true).then((response) => {
        let resp = response.data;
        let sequence = resp.response;
        pay_sequence[loaded_payment_type] = sequence;
        setCurrentSequence(sequence);
    });
     APP_HTTP.REQUEST('settings/get_option',{name:'demand_bill_list_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setDemandGenerateListType(resp.value);
            }
        });
     APP_HTTP.REQUEST('settings/get_option',{name:'demand_bill_generate_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setDemandGenerateFamilyType(resp.value);
            }
        });
    stIsShowInstantPayment(true);
    setShowPaymentList(true);
    setShowSavePayment(true);
    handleLoadPendingPayments(payment_type,value,'family');
    setContactInfo(value);
  }
   const handleProceedForFullPayment = () =>{
      setIsFullPayment(true);
      setIsSelectingFullPayment(true);
      APP_HTTP.REQUEST('payment/get_student_pending_payments',{student:contact_info.id,fetch:'all',target:'instant'},true,true).then((response) => {
        let fee_struct = response.data.fee_structure;
        setStudentFeeStructure(fee_struct);
        let resp = response.data.response;
        setIsSelectingFullPayment(false);
        if(resp[0]!==undefined){
          let list_items = resp[0].list_item;
          if(resp[0].self_list_item!==undefined){
                if(self_group_type==='single'){
                    list_items = resp[0].self_list_item;
                }
            }
          setPendingPayments(list_items);
          let pending_amount = 0;
          for (let index = 0; index < list_items.length; index++) {
            const element = list_items[index];
            pending_amount = pending_amount + parseFloat(element.fee)
          }
          setTotalPendingAmount(pending_amount);
          if(document.getElementById('received-amount-box')){
            document.getElementById('received-amount-box').focus();
          }
        }
    }); 
    }
  const handleChangeSelfGroupType = (event) => {
    setIsSelectingFullPayment(true);
    
    let _type = event.target.value;
    setSelfGroupType(_type);
    let value = props.contact_info;
    let payment_type = 'payment_in';
    handleLoadPendingPayments(payment_type,value,_type);
  };
  
  return (
    <>
        <Dialog open={true} fullWidth={false} fullScreen={true}>
            <AppBar position="fixed" component="nav">
            <Toolbar>
                <DialogTitle>Receive Payment Instantly</DialogTitle>
            </Toolbar>
            </AppBar>
            <DialogContent sx={{marginTop:'70px'}}>
                <Box sx={{ flexGrow: 1 }} className="MainContainer">
                <Grid container spacing={2} className="mrbtm-30">  
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}> 
                        <Grid item xs={12} sm={12} md={4}>
                        {(props.contact_info!=null && props.contact_info.name!==undefined) &&
                            <StudentCard info={props.contact_info} is_photo={true}/>
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} style={{marginTop:'13px'}}>
                        {(demand_bill_generate_type==='family') &&
                            <FormControl>
                                <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={self_group_type}
                                onChange={handleChangeSelfGroupType}
                                >
                                <FormControlLabel value="family" control={<Radio />} label="Family Group" />
                                <FormControlLabel value="single" control={<Radio />} label="Self" />
                                </RadioGroup>
                            </FormControl>
                        }

{(is_selecting_full_payment===false) &&
                            <Button variant='outlined' onClick={handleProceedForFullPayment}>Full Payment</Button>
                            }
                            {(is_selecting_full_payment===true) &&
                            <LoaderButton variant='outlined'  />
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} style={{textAlign:'right'}}>
                        
                            <Typography variant='span' sx={{fontSize:'30px',fontWeight:'bold',color:'gray'}}>{(current_sequence!=="")?current_sequence:''}</Typography>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
                </Grid>
                {(is_show_payment_list===true) &&
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                        {(is_selecting_manually===false) &&
                        <>
                        <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll'}}>
                        <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={(is_convert_to_pay===false)?2:1}>PENDING FEE</StyledTableCell>
                                <StyledTableCell style={{textAlign:'right'}}>
                                {(is_selecting_full_payment===true) &&
                                <Typography variant='h4'>
                                    Fetching...
                                </Typography>
                                }
                                {(is_selecting_full_payment===false) &&
                                <Typography variant='h4'>
                                ₹{AppUtils.currency_format(totalPendingAmount)}
                                </Typography>
                                }
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>FEE TYPE</StyledTableCell>
                                <StyledTableCell style={{textAlign:'right'}}>FEE</StyledTableCell>
                                {(is_convert_to_pay===false) &&
                                <StyledTableCell></StyledTableCell>
                                }
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {(is_selecting_full_payment===false) &&
                            <>
                            {pending_payments.map((value,index)=>{
                                return (
                                    <>
                                    {(value.fee>0) &&
                                    <StyledTableRow>
                                    <StyledTableCell style={{textTransform:'capitalize'}}>
                                        {(parseInt(value.particulars)===3) &&
                                        <>
                                            {(parseFloat(value.fee)>0)?value.particulars_name:'Advance Payment'}
                                        </>
                                        }
                                        {(parseInt(value.particulars)!==3) &&
                                        <>
                                            {value.particulars_name}
                                        </>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{textAlign:'right'}}>{value.fee}</StyledTableCell>
                                    {(is_convert_to_pay===false) &&
                                    <StyledTableCell style={{textAlign:'right'}}>
                                        {(value.selected!==true) &&
                                        <Button onClick={()=>handleSelectPendingPayment(index)} size='small' sx={{fontSize:'9px'}}><Typography variant='span' style={{marginTop:'3px'}}>Select</Typography> <KeyboardDoubleArrowRight size="medium"/></Button>
                                        }
                                        {(value.selected===true) &&
                                        <CheckRounded/>
                                        }
                                    </StyledTableCell>
                                    }
                                    </StyledTableRow>
                                    }
                                    </>
                                )
                            })}
                            </>
                            }
                            {(pending_payments.length===0) &&
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign:'center'}}>No Pending Fee Found</StyledTableCell>
                                </StyledTableRow>
                            }
                            {(is_selecting_full_payment===true) &&
                            <>
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                                </StyledTableCell>
                                </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                                </StyledTableCell>
                                </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                                </StyledTableCell>
                                </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                                </StyledTableCell>
                                </StyledTableRow>
                            </>
                            }
                            </TableBody>
                        </Table>
                        </TableContainer>
                        </Box>
                        <Divider />
                        <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll',marginTop:'10px'}}>
                        <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={5}>LATEST 5 PAID PAYMENTS</StyledTableCell>
                            </TableRow>
                            <TableRow>          
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell>AMOUNT</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {paid_payments_list && paid_payments_list.map((row,index) => (
                                <StyledTableRow key={row.payment_sequence}>
                                
                                <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                                    <Typography variant='h6'>{row.payment_sequence}</Typography>
                                    <Typography variant='p'>{row.payment_date_display}</Typography>
                                </StyledTableCell>
                                
                                <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                                
                                </StyledTableRow>
                            ))}
                            {(is_loading_payments===false && paid_payments_list.length===0) &&
                                <StyledTableRow>
                                <StyledTableCell colSpan={2} style={{textAlign:'center'}}>
                                    No Payment(s) Found.
                                </StyledTableCell>
                                </StyledTableRow>
                            }
                            {(is_loading_payments===true) &&
                                <StyledTableRow>
                                <StyledTableCell colSpan={2} style={{textAlign:'center'}}>
                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                                </StyledTableCell>
                                </StyledTableRow>
                            }
                            </TableBody>
                        </Table>
                        </TableContainer>
                        </Box>
                        <Divider />
                        </>
                        }
                        </Grid>
                         
                        <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            {(is_refresh_list===false) &&
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell>SNO</StyledTableCell>
                                <StyledTableCell>FEE TYPE</StyledTableCell>
                                <StyledTableCell>REFERENCE</StyledTableCell>
                                {(is_selected_yearly_package===true) &&
                                <>
                                    <StyledTableCell>PRICE</StyledTableCell>
                                    <StyledTableCell>QUANTIY</StyledTableCell>
                                    <StyledTableCell>TOTAL</StyledTableCell>
                                </>
                                }
                                {(is_selected_yearly_package===false) &&
                                    <StyledTableCell>FEE</StyledTableCell>
                                }
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payment_item_list.map((value,index)=>{
                                    if(props.is_convert_to_payment===true && value.amount>0){
                                    item_index = item_index+1;
                                    }else if(props.is_convert_to_payment!==true && value.is_prev_fee===undefined){
                                    item_index = item_index+1;
                                    }else if(value.amount>0){
                                    item_index = item_index+1;
                                    }
                                    return (
                                    <>
                                    {(props.is_convert_to_payment===true && value.amount>0) &&
                                        <StyledTableRow>
                                        <StyledTableCell>
                                        <Typography>{item_index}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {(value.particulars>0 || value.particulars==='') &&
                                            <PaymentTypes show="dropdown" defaultValue={value.particulars} handleChangeData={handleChangeParticulars} id={index} label={(value.fee_month!==undefined && value.fee_month!=="")?value.fee_month.toUpperCase():'Fee Types'} target={payment_type} disabled={(value.selected===true || is_convert_to_pay===true)?true:false}/>
                                        }
                                        {(parseInt(value.particulars)===0) &&
                                            <Typography>Advance</Typography>
                                        }
                                        {(parseInt(value.particulars)===-1) &&
                                            <Typography>Advance Redeemed</Typography>
                                        }
                                        {(parseInt(value.particulars)===-2) &&
                                            <Typography>{value.particulars_name}</Typography>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {(value.is_uniform===true) &&
                                            <AppDropDown label="Select Uniform" list_item={value.uniform_list} size="small" defaultValue={value.reference} handleChangeUniform={handleChangeUniform} id={index}/>
                                        }
                                        {(value.is_uniform!==true) &&
                                            <TextField
                                            margin="dense"
                                            label="Reference"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.reference}
                                            onChange={(event)=>handleChangeData(index,'reference',event.target.value)}
                                            />
                                        }
                                        </StyledTableCell>
                                        {(is_selected_yearly_package===true) &&
                                        <>
                                        <StyledTableCell>
                                            <Typography>{value.fee}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TextField
                                            margin="dense"
                                            label="Quantity"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.quantity}
                                            onChange={(event)=>handleChangeData(index,'quantity',event.target.value)}
                                            />
                                        </StyledTableCell>
                                        </>
                                        }
                                        
                                        <StyledTableCell>
                                        <TextField
                                            margin="dense"
                                            label="Fee"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.amount}
                                            disabled={(payment_type==='payment_out' || payment_type==='staff_advance')?false:true}
                                            onChange={(event)=>handleChangeData(index,'amount',event.target.value)}
                                        />
                                        {(parseInt(value.particulars)===-1) &&
                                            <Typography variant='span' style={{color:'red',fontSize:'12px'}}>Please do not remove negative(-) from value</Typography>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'right'}}>
                                        {(is_convert_to_pay===false && payment_id===0) &&
                                            <Close fontSize='small' sx={{cursor:'pointer'}} onClick={() => handleRemoveItem(index,value.pending_index)}/>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'right'}}>
                                        {((payment_item_list.length - 1)==index && is_convert_to_pay===false) &&
                                            <Button onClick={addMorePaymentItem} size='small' sx={{fontSize:'9px'}}>Add More</Button>
                                        }
                                        </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {(props.is_convert_to_payment!==true && value.is_prev_fee===undefined) &&
                                    <StyledTableRow>
                                        <StyledTableCell>
                                        <Typography>{item_index}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {(value.particulars>0 || value.particulars==='') &&
                                            <PaymentTypes show="dropdown" defaultValue={value.particulars} handleChangeData={handleChangeParticulars} id={index} label={(value.fee_month!==undefined && value.fee_month!=="")?value.fee_month.toUpperCase():'Fee Types'} target={payment_type} disabled={(value.selected===true || is_convert_to_pay===true)?true:false}/>
                                        }
                                        {(parseInt(value.particulars)===0) &&
                                            <Typography>Advance</Typography>
                                        }
                                        {(parseInt(value.particulars)===-1) &&
                                            <Typography>Advance Redeemed</Typography>
                                        }
                                        {(parseInt(value.particulars)===-2) &&
                                            <Typography>{value.particulars_name}</Typography>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {(value.is_uniform===true) &&
                                            <AppDropDown label="Select Uniform" list_item={value.uniform_list} size="small" defaultValue={value.reference} handleChangeUniform={handleChangeUniform} id={index}/>
                                        }
                                        {(value.is_uniform!==true) &&
                                            <TextField
                                            margin="dense"
                                            label="Reference"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.reference}
                                            onChange={(event)=>handleChangeData(index,'reference',event.target.value)}
                                            />
                                        }
                                        </StyledTableCell>
                                        {(is_selected_yearly_package===true) &&
                                        <>
                                        <StyledTableCell>
                                            <Typography>{value.fee}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TextField
                                            margin="dense"
                                            label="Quantity"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.quantity}
                                            onChange={(event)=>handleChangeData(index,'quantity',event.target.value)}
                                            />
                                        </StyledTableCell>
                                        </>
                                        }
                                        
                                        <StyledTableCell>
                                        <TextField
                                            margin="dense"
                                            label="Fee"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            value={value.amount}
                                            disabled={(payment_type==='payment_out' || payment_type==='staff_advance')?false:true}
                                            onChange={(event)=>handleChangeData(index,'amount',event.target.value)}
                                        />
                                        {(parseInt(value.particulars)===-1) &&
                                            <Typography variant='span' style={{color:'red',fontSize:'12px'}}>Please do not remove negative(-) from value</Typography>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'right'}}>
                                        {(is_convert_to_pay===false && payment_id===0) &&
                                            <Close fontSize='small' sx={{cursor:'pointer'}} onClick={() => handleRemoveItem(index,value.pending_index)}/>
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'right'}}>
                                        {((payment_item_list.length - 1)==index && is_convert_to_pay===false) &&
                                            <Button onClick={addMorePaymentItem} size='small' sx={{fontSize:'9px'}}>Add More</Button>
                                        }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    }
                                    </>
                                    )
                                })}
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    {(is_selected_yearly_package) &&
                                    <>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    </>
                                    }
                                    <StyledTableCell><Typography variant='h5'>₹ {AppUtils.currency_format(total_payable_fee)}</Typography></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                            </Table>
                            </TableContainer>
        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                        <Box>
                            <TextField
                                margin="dense"
                                label="Discount(₹)"
                                type="text"
                                fullWidth
                                size="small"
                                
                                onChange={(e)=>handleChangeDiscount(e.target.value)}
                                defaultValue={discount_amount}
                            />
                            </Box>
                            {(payment_type==='payment_in') &&
                            <Box>
                            <TextField
                                margin="dense"
                                label="Late Fine(₹)"
                                type="text"
                                fullWidth
                                size="small"
                                onChange={(e)=>handleChangeLateFine(e.target.value)}
                                defaultValue={late_fine}
                                />
                            </Box>
                            }
                            {(payment_modes.map((value,index)=>{
                            return(
                                <Box style={{marginTop:'15px'}}>
                                <TextField
                                id={"payment-mode-"+index}
                                label={value.value}
                                fullWidth
                                size='small'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        {(value.value==='Cash') &&
                                        <CurrencyRupee />
                                        }
                                        {(value.value==='Online') &&
                                        <PhoneAndroid />
                                        }
                                        {(value.value==='Card') &&
                                        <CreditCard />
                                        }
                                        {(value.value==='Cheque') &&
                                        <Edit />
                                        }
                                    </InputAdornment>,
                                }}
                                
                                defaultValue={payment_mode_data[value.key]}
                                onChange={(e)=>handleChangePaymentModeData(value.key,e.target.value)}
                                />
                                </Box>
                            )
                            }))}
                            
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={4}>
                            {(is_calculating===false) &&
                            <Box>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            <Typography variant='h6'>
                            Payable {(payment_type==='payment_id')?'Fee':' Amount'} : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_payable_fee)}</Typography>
                            </Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            {(total_staff_advance_payment>0 && total_payable_fee>0) &&
                            <>
                            <Typography variant='h6' style={{color:'red'}}>
                            Advance Taken : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_staff_advance_payment)}</Typography>
                            </Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            <Typography variant='h6'>
                            Total Payable : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_payable_fee - total_staff_advance_payment)}</Typography>
                            </Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            {(discount_amount>0) &&
                            <>
                            <Typography variant='h6'>Discount : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(discount_amount)}</Typography></Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                        
                            {(late_fine>0) &&
                            <>
                            <Typography variant='h6' style={{color:'#00e900'}}>Late Fine : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(late_fine)}</Typography></Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            <Typography variant='h6'>
                            Paid {(payment_type==='payment_id')?'Fee':' Amount'} : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(payment_paid_amount)}</Typography>
                            </Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </Box>
                            }
                            {(fee_dues>0) &&
                            <>
                            <Typography variant='h6' style={{color:'red'}}>Fee Dues : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(fee_dues)}</Typography></Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            {(fee_dues<0) &&
                            <>
                            <Typography variant='h6' style={{color:'#00e900'}}>Advance : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(Math.abs(fee_dues))}</Typography></Typography>
                            <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                            </>
                            }
                            {/* <AppDropDown label="Payment Mode" list_item={payment_modes} size="small" handleChangeData={handleChangePaymentMode} defaultValue={selected_payment_mode}/> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Box style={{marginTop:'15px'}}>
                            <Textarea placeholder="Notes (if any)" defaultValue={remarks} minRows={5} onChange={(event)=>handleSetRemarks(event.target.value)}/>
                            </Box>
                        </Grid>
                        </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
                </Grid>
                }
                {(is_show_save_payment===true) &&
                <Grid container spacing={2} className="mrtop-30">
                    
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                        <Grid  item xs={12} sm={12} md={6}>
                            
                        <Button variant="outlined"  sx={{mt:'13px'}} onClick={handleClosePaymentPopup}>Close</Button>
                            
                        </Grid>
                        <Grid  item xs={12} sm={12} md={6} sx={{textAlign:'right'}}>
                        {(isSaveClicked===false) &&
                        <>
                        
                            <Button variant="contained" sx={{mt:'13px'}} onClick={() => handleSaveInformation('view')}>Save & Print</Button>
                        
                        </>
                        }
                        {(isSaveClicked===true) &&
                            <LoaderButton label="Save Information"/>
                        }
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
                </Grid>
                }
                </Box>
            </DialogContent>
        </Dialog>
    </>
  );
 
}
export default PayInstantly;