import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, RadioGroup, Skeleton, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import LoaderButton from '../LoaderButton';
import BasicDatePicker from '../DatePicker';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleSetPaymentInfo, handleToggleEditPayment, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import Autocomplete from '@mui/material/Autocomplete';
import Sections from '../settings/sections/Sections';
import Classes from '../settings/classes/Classes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowRight, CheckCircleOutlineRounded, CheckRounded, Close, CreditCard, CurrencyRupee, Delete, Edit, KeyboardDoubleArrowRight, PhoneAndroid } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
import AppCheckbox from '../AppCheckbox';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import { Textarea } from '@mui/joy';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
let curl = window.location.href;

const payment_on_load = curl.match(/payment\/add\/([payment_in|paymnet_out|staff_advance]+$)/i);
let loaded_payment_type = '';
if(payment_on_load){
  loaded_payment_type = payment_on_load[1];
  
}

const CreatePayment = (props)=> {
    let paymentDataInitialState = {
        update_id : 0,
        payment_sequence : '',
        payment_type : loaded_payment_type,
        payment_date : '',
        payment_contact : '',
        list_item : '',
    }
    let paymentItemListInitialState = {
        payment_meta_id:0,
        payment_mode:(props.is_demand_bill===true)?5:1,
        particulars:(loaded_payment_type==='staff_advance')?0:'',
        particulars_name:'',
        reference:'',
        amount:'',
    }
    let item_index = 0;
    let [payment_item_list,setPaymentItemList] = React.useState([paymentItemListInitialState]);
    let [payee_payer_list,setPayeePayerList] = React.useState([]);
    let [payment_id,setPaymentId] = React.useState(0);
    let [is_show_opt_button,setIsShowOptButton] = React.useState(true);
    let [student_advance_payment,setStudentAdvancePayment] = React.useState(0);
    let [total_staff_advance_payment,setStaffTotalAdvanceAmount] = React.useState(0);
    let [staff_advance_payment_list,setStaffAdvancePaymentList] = React.useState([]);
    let [payment_total_amount,setTotalAmount] = React.useState(0);
    let [fee_dues,setFeeDues] = React.useState(0);
    let [demand_bill_generate_type,setDemandGenerateListType] = React.useState('detailed');
    let [discount_amount,setDiscount] = React.useState(0);
    let [late_fine,setlateFine] = React.useState(0);
    let [payment_paid_amount,setTotalPaid] = React.useState(0);
    let [payment_date,setPaymentDate] = React.useState(new Date());
    let [month_date,setMonthDate] = React.useState(new Date());
    let [pending_payments,setPendingPayments] = React.useState([]);
    let [student_fee_structure,setStudentFeeStructure] = React.useState({});
    let [contact_info,setContactInfo] = React.useState([]);
    let [current_sequence_arr,setCurrentSequenceArr] = React.useState([]);
    let [repeat_db,setIsRepeat] = React.useState(false);
    let [is_calculating,setIsCalculating] = React.useState(false);
    let [is_refresh_list,setIsRefreshing] = React.useState(false);
    let [selected_payment_mode,setSelectedPaymentMode] = React.useState(1);
    let [remarks,setRemarks] = React.useState('');
    let [is_selecting_yearly_package,setIsSelectingYearlyPackage] = React.useState(false);
    let [is_selecting_uniform,setIsSelectingUniform] = React.useState(false);
    const [paid_payments_list,setPaidPaymentsList] = React.useState([]);
    const [demand_bill_list,setDemandBillList] = React.useState([]);
    const [is_loading_demand_bill,setIsLoadingDB] = React.useState(true);
    const [is_loading_payments,setIsLoadingPayments] = React.useState(true);
    let [is_show_save_payment,setShowSavePayment] = React.useState(false);
    let [is_show_payment_list,setShowPaymentList] = React.useState(false);
    let [is_selected_yearly_package,setYearlyPackage] = React.useState(false);
    let [payment_type,setPaymentType] = React.useState('');
    let [payer_type,setPayerType] = React.useState('Student');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [is_redeem_advance_payment,setIsRedeemAdvancePayment] = React.useState(false);
    let [selected_session,setSession] = React.useState(0);
    let [selected_class,setClass] = React.useState(0);
    let [selected_section,setSection] = React.useState(0);
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    let [isShowExistingTemplate,setShowTemplate] = React.useState(false);
    let [is_process_rcvd_fee,setIsProcessingRcvdFee] = React.useState(false);
    let [is_selecting_full_payment,setIsSelectingFullPayment] = React.useState(false);
    let [is_full_payment,setIsFullPayment] = React.useState(false);
    let [existingTemplate,setExistingTemplate] = React.useState([]);
    let [receivedFee,setReceivedFee] = React.useState('');
    let [totalPendingAmount,setTotalPendingAmount] = React.useState('');
    let [is_selecting_manually,setIsManually] = React.useState(false);
    let [is_convert_to_pay,setIsConvertToPay] = React.useState(false);
    let [selected_db_id,setSelectedDBID] = React.useState(0);
    let [total_payable_fee,setPayableFee] = React.useState(0);
    let [payment_mode_data,setPaymentModeData] = React.useState({});
    let [from_payment_type,setFromPaymentType] = React.useState('student');
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const dispatch = useDispatch();
    const payment_modes = state.payment_modes;
    const payment_info = state.payment_info;
    const payment_type_list = state.payment_type_list;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            let new_pmode = {};
            for (let index = 0; index < payment_modes.length; index++) {
              const element = payment_modes[index];
              let pmid = element.key;
              new_pmode[pmid] = '';
            }
            
            initialized.current = true;
           
            if(props.payment_data){
                let payment_data = props.payment_data;
               
                if(payment_data.is_db===undefined){
                    if(props.is_demand_bill===true){
                      payment_data.payment_type = 'payment_in';
                    }
                    handleLoadDataOnFly(payment_data);
                }else{
                  let is_demand_bill = false;
                  if(props.is_demand_bill===true){
                    is_demand_bill = true;
                  }
                  if(payment_data.is_staff_advance!==true){
                    
                    APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                        let resp = response.data.response;
                        if(is_demand_bill===true){
                          resp.payment_type = 'payment_in';
                        }
                        if(resp.payment_mode_data!==null){
                          new_pmode = resp.payment_mode_data;
                        }
                        setPaymentModeData(new_pmode);
                        let list_item = resp.list_item
                        for (let index = 0; index < list_item.length; index++) {
                          const element = list_item[index];
                          if(parseInt(element.particulars)===-2){
                            setYearlyPackage(true);
                          }
                        }
                        handleLoadDataOnFly(resp);
                        dispatch(handleSetPaymentInfo(resp));
                        let con_info = {id:resp.contact_id};
                        //handleLoadPendingPayments(resp.payment_type,con_info);
                        //setIsCalculating(true);
                        //handleCalculateFinalFee(new_pmode,0,0);
                    });
                  }else{
                    setIsShowOptButton(false);
                    APP_HTTP.REQUEST('payment/get_staff_advance_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                      let resp = response.data.response;
                     
                      if(resp.payment_mode_data!==null){
                        new_pmode = resp.payment_mode_data;
                      }
                      setPaymentModeData(new_pmode);
                     
                      handleLoadDataOnFly(resp);
                      dispatch(handleSetPaymentInfo(resp));
                      let con_info = {id:resp.contact_id};
                     
                  });
                  }
                }
            }else{
                if(props.is_convert_to_payment!==undefined && props.is_convert_to_payment===true){
                  let is_demand_bill = true;
                  let pid = props.id;
                  setSelectedDBID(pid);
                  setIsConvertToPay(true);
                  setPaymentModeData(new_pmode);
                  APP_HTTP.REQUEST('payment/get_payment_on_id',{id:pid,is_demand_bill:is_demand_bill},true,true).then((response) => {
                      let resp = response.data.response;
                      let con_info = {id:resp.contact_id};
                      handleLoadPendingPayments('payment_in',con_info);
                      resp.payment_type = 'payment_in';
                      handleLoadDataOnFly(resp);
                      let late_fine_fee = resp.late_fine_fee;
                      setlateFine(late_fine_fee);
                      dispatch(handleSetPaymentInfo(resp));
                      let pay_sequence = {};
                      APP_HTTP.REQUEST('settings/get_current_sequence',{type:'payment_in'},true,true).then((response) => {
                          let resp = response.data;
                          let sequence = resp.response;
                          pay_sequence['payment_in'] = sequence;
                          setCurrentSequence(sequence);
                      });
                      setIsCalculating(true);
                      handleCalculateFinalFee(new_pmode,0,0);
                  });
                }else{
                  let seq_type = 'payment_in';
                  if(props.is_demand_bill===true){
                    seq_type = 'demand_bill';
                    //setPaymentType(seq_type);
                  }
                  let pay_sequence = {};
                  
                  APP_HTTP.REQUEST('settings/get_current_sequence',{type:loaded_payment_type},true,true).then((response) => {
                      let resp = response.data;
                      let sequence = resp.response;
                      pay_sequence[loaded_payment_type] = sequence;
                      setCurrentSequence(sequence);
                      handleOnLoadCreatePayment(loaded_payment_type);
                  });
                }
            }
        }
       
    },[props]);
    const handleResetPaymentScreen = ()=>{
      let paymentDataInitialState = {
        update_id : 0,
        payment_sequence : '',
        payment_type : loaded_payment_type,
        payment_date : '',
        payment_contact : '',
        list_item : '',
    }
    let paymentItemListInitialState = {
        payment_meta_id:0,
        payment_mode:(props.is_demand_bill===true)?5:1,
        particulars:'',
        particulars_name:'',
        reference:'',
        amount:'',
    }
    setPaymentItemList([paymentItemListInitialState]);
    setPaymentId(0);
    setStudentAdvancePayment(0);
    setTotalAmount(0);
    setFeeDues(0);
    setTotalPaid(0);
    setPendingPayments([]);
    setStudentFeeStructure({});
    setRemarks('');
    setPaidPaymentsList([]);
    setDemandBillList([]);
    setPaymentData(paymentDataInitialState);
    setReceivedFee('');
    setTotalPendingAmount('');
    setPayableFee(0);
    }
  const handleRedeemAdvancePayment = (e) =>{
    let pilist = payment_item_list;
    let is_red = e.target.checked;
    setIsRedeemAdvancePayment(is_red);
    if(is_red===true){
        let short_month = month_date.toLocaleString('default', { month: 'short' });
        short_month = short_month.toLowerCase();
        let year = month_date.getFullYear();
        let advance_item = {
            "payment_mode": 5,
            "particulars": -1,
            "fee_month": short_month,
            "fee_year": year,
            "fee": -student_advance_payment,
            "amount": -student_advance_payment,
            "particulars_name": "Advance",
            "payment_meta_id": 0,
            "reference": "",
            "pending_index": 20000,
            "selected": false
        };
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          pilist = [];
        }
        pilist.push(advance_item);
        handleSetPaymentList(pilist);
    }else{
      for (let index = 0; index < pilist.length; index++) {
        const element = pilist[index];
        if(parseInt(element.particulars)===-1){
          pilist.splice(index,1);
        }
      }
      handleSetPaymentList(pilist);
    }
  }
  const handleLoadDataOnFly = (resp)=>{
    setPaymentData(resp);
    setPaymentType(resp.payment_type);
    if(resp.payment_type==='payment_in'){
      setPayerType('Student');
      APP_HTTP.REQUEST('student/search_student',{search:resp.contact_info.section_id,type:'autocomplete',academic_session:selected_session,class:resp.contact_info.class_id,section:resp.contact_info.section_id},true,true,false,true).then((stresponse) => {
        let stresp = stresponse.data;
        let search_list = stresp.response;
        setPayeePayerList(search_list);
      });
    }else if(resp.payment_type==='payment_out' || resp.payment_type==='staff_advance'){
      setPayerType('Staff');
      APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete'},true,true,false,true).then((stresponse) => {
        let stresp = stresponse.data;
        let search_list = stresp.response;
        setPayeePayerList(search_list);
      });
    }
    if(resp.contact_info && resp.contact_info.class_id){
      setClass(resp.contact_info.class_id)
    }
    if(resp.contact_info && resp.contact_info.section_id){
      setSection(resp.contact_info.section_id);
    }
    if(resp.contact_info && resp.contact_info.autocomplete){
      setContactInfo(resp.contact_info.autocomplete);
    }
    let discount = false;
    if(resp.discount){
      if(resp.discount!==""){
        discount = parseFloat(resp.discount);
      }
      setDiscount(resp.discount);
    }
    if(resp.payment_date && props.is_convert_to_payment!==true){
      setPaymentDate(new Date(resp.payment_date));
    }
    if(resp.paid_amount){
      setTotalAmount(resp.paid_amount);
      if(resp.payable_amount===undefined){
        setPayableFee(resp.paid_amount);
      }
    }
    if(resp.payable_amount){
      setPayableFee(resp.payable_amount);
    }
    if(resp.advance_payment){
      setStaffTotalAdvanceAmount(resp.advance_payment);
    }
    if(resp.fee_dues){
      setFeeDues(resp.fee_dues);
      let pen_amt =0;
      if(resp.fee_dues!=="" && parseFloat(resp.fee_dues)>0){
        pen_amt = parseFloat(resp.payable_amount) + parseFloat(resp.fee_dues);
        setTotalPendingAmount(pen_amt);
      }else{
        setTotalPendingAmount(resp.payable_amount);
      }
    }
    if(resp.paid_amount){
      setTotalPaid(resp.paid_amount);
    }
    if(resp.payment_mode){
      setSelectedPaymentMode(resp.payment_mode);
    }
    if(resp.remarks){
      setRemarks(resp.remarks);
    }
    if(resp.for_month){
      setMonthDate(new Date(resp.for_month));
    }
    setCurrentSequence(resp.payment_sequence);
    setPaymentId(resp.update_id);
    if(resp.list_item){
      setShowPaymentList(true);
      setShowSavePayment(true);
      setPaymentItemList(resp.list_item);
      handleSetTotalAmount(resp.list_item,discount);
    }
  }

  const handleSaveInformation = (type) =>{
    if(type==='view'){
      localStorage.setItem('is_print','true');
    }
    let pmdata = {...payment_mode_data};
    let is_empty = true;
    for (const key in pmdata) {
      let paid_amount = pmdata[key];
      if(paid_amount!=="" && parseFloat(paid_amount)>0){
        is_empty = false;
      }
    }
    if(is_empty===true){
      if(document.getElementById('payment-mode-0')){
        document.getElementById('payment-mode-0').focus();
      }
      return false;
    }
    setIsSaveClicked(true);
    let short_month = month_date.toLocaleString('default', { month: 'short' });
    short_month = short_month.toLowerCase();
    let year = month_date.getFullYear();
    let plist = [];
    for (let index = 0; index < payment_item_list.length; index++) {
      const element = payment_item_list[index];
      if(element.fee_month===undefined){
        element.fee_month = short_month;
        element.fee_year = year;
      }
      if(element.fee===undefined){
        element.fee = element.amount;
      }
      plist.push(element);
    }
    let sendData = {
      is_repeat : repeat_db,
      update_id : payment_id,
      payment_sequence : current_sequence,
      payment_mode : selected_payment_mode,
      remarks : remarks,
      payment_type : payment_type,
      payment_date : payment_date,
      month_date : month_date,
      contact_info : contact_info,
      payable_amount : total_payable_fee,
      payment_total_amount : payment_paid_amount,
      fee_dues : fee_dues,
      discount_amount : discount_amount,
      payment_item_list : plist,
      payment_mode_data : payment_mode_data,
      late_fine : late_fine,
      is_demand_bill : (props.is_demand_bill===true)?true:false,
      is_convert_to_payment : (props.is_convert_to_payment===true)?props.id:0,
      staff_advance_payment_list : staff_advance_payment_list,
      total_staff_advance_payment : total_staff_advance_payment,
      is_full_payment : is_full_payment,
    }
    if(payment_type!=='staff_advance'){
      APP_HTTP.REQUEST('payment/save_payment',sendData,true,true).then((response) => {
          let resp = response.data;
          let id = resp.id;
          setIsSaveClicked(false);
          if(props.is_demand_bill===true){
            window.location.href = '/payment/view/demand-bill/'+id;
          // dispatch(handleChangeMenu('payment','view/demand-bill/'+id,'View Demand Bill',false));
          }else{
            if(type==='view'){ 
              window.location.href = '/payment/view/'+id;
            }else{
              window.location.href = '/payment/add/'+payment_type;
            }
            //dispatch(handleChangeMenu('payment','view/'+id,'View Payment',false));
          }
          dispatch(handleToggleViewPayment(true));
      });
    }else{
      APP_HTTP.REQUEST('payment/save_staff_advance',sendData,true,true).then((response) => {
        let resp = response.data;
        let id = resp.id;
        setIsSaveClicked(false);
        if(type==='list'){
          window.location.href = '/payments/staff_advance';
        }else{
          window.location.href = '/payment/add/'+payment_type;
        }
        dispatch(handleToggleViewPayment(true));
    });
    }
  }
  const handleChangeData = (index,id,value) =>{
    let pilist = [...payment_item_list];
    pilist[index][id] = value;
    if(id==='quantity'){
      let fee = pilist[index]['fee'];
      if(value===""){
        value = 0;
      }
      let amount = fee * value;
      pilist[index]['amount'] = amount;
    }
    handleSetPaymentList(pilist);
  }
  const handleCancelEdit = () =>{
    dispatch(handleChangeMenu('payments','','Payments',true));
    dispatch(handleToggleEditPayment(false));
  }
  
  const handleOnLoadCreatePayment = (thisvalue)=>{
    if(thisvalue==='payment_in' || thisvalue==='demand_bill'){
      setPayerType('Student');
    }else if(thisvalue==='payment_out' || thisvalue==='staff_advance'){
      setPayerType('Staff');
      APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete'},true,true,false,true).then((response) => {
        let resp = response.data;
        let search_list = resp.response;
        setPayeePayerList(search_list);
      });
    }
    APP_HTTP.REQUEST('settings/get_option',{name:thisvalue},true,true).then((response) => {
        let resp = response.data.response;
        if(resp && resp.value && resp.value.length>0){
          setShowTemplate(true);
          setExistingTemplate(resp.value);
        }
    });
    APP_HTTP.REQUEST('settings/get_option',{name:'demand_bill_list_type'},true,true).then((response) => {
        let resp = response.data.response;
        if(resp.value){
            setDemandGenerateListType(resp.value);
        }
    });
    if(thisvalue==='demand_bill'){
      thisvalue = 'payment_in';
    }
    setPaymentType(thisvalue);
    //let sequence = current_sequence_arr[thisvalue];
    //setCurrentSequence(sequence)
  }

  const handleChangePaymentType = (event) => {
    let thisvalue = event.target.value;
    window.location.href = '/payment/add/'+thisvalue;
  };
  const handleChangeInputValue = (value) =>{
    
  }
  const handleChangeValue = (value) =>{
    console.log(value);
    if(value===null){
      setShowPaymentList(false);
      setShowSavePayment(false);
      handleResetPaymentScreen();
    }else{
      setShowPaymentList(true);
      setShowSavePayment(true);
      handleLoadPendingPayments(payment_type,value);
    }
    setContactInfo(value);
  }
  const handleLoadPendingPayments = (payment_type,value) =>{
    if(value===null || value.id===undefined){
      return false;
    }
     if(payment_type==='payment_in'){
      APP_HTTP.REQUEST('payment/get_student_pending_payments',{student:value.id},true,true).then((response) => {
          let fee_struct = response.data.fee_structure;
          setStudentFeeStructure(fee_struct);
          let resp = response.data.response;
          if(resp[0]!==undefined){
            let list_items = resp[0].list_item;
            setPendingPayments(list_items);
            let pending_amount = 0;
            for (let index = 0; index < list_items.length; index++) {
              const element = list_items[index];
              pending_amount = pending_amount + parseFloat(element.fee)
            }
            setTotalPendingAmount(pending_amount)
          }
      }); 
      APP_HTTP.REQUEST('payment/get_student_advance_payment',{student_id:value.id},true,true).then((response) => {
          let adv_payment = response.data.response;
          setStudentAdvancePayment(adv_payment)
      }); 
      handleLoadDemandBill(value.id);
      handleLoadPaidPayments(value.id);
    }else if(payment_type==='payment_out'){
      APP_HTTP.REQUEST('payment/get_staff_advance_payments',{staff_id:value.id},true,true).then((response) => {
        let adv_payment = response.data.response;
        setStaffAdvancePaymentList(adv_payment);
        let total_adv_amount = 0;
        for (let index = 0; index < adv_payment.length; index++) {
          const element = adv_payment[index];
          let adv_paid_amount = parseFloat(element.paid_amount);
          total_adv_amount = total_adv_amount + adv_paid_amount;
        }
        setStaffTotalAdvanceAmount(total_adv_amount);
      }); 
    }
  }
  const handleLoadPaidPayments = (id) =>{
    setIsLoadingPayments(true);
    APP_HTTP.REQUEST('payment/get_student_payments',{id:id},true,true).then((response) => {
        let resp = response.data.response;
        setPaidPaymentsList(resp);
        setIsLoadingPayments(false)
    });
  }
  const handleLoadDemandBill = (id) =>{
    
    APP_HTTP.REQUEST('payment/get_demand_bill_on_student_id',{student_id:id,status:1},true,true).then((response) => {
        let resp = response.data.response;
        setDemandBillList(resp);
        setIsLoadingDB(false)
    });
  }
  const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeSession = (id,value) =>{
    setPayeePayerList([]);
    setSession(value);
    setClass(0);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setPayeePayerList([]);
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleSetPaymentList = (pilist) =>{
    setPaymentItemList(pilist);
    handleSetTotalAmount(pilist,false);
  }
  const handleSetTotalAmount = (pilist,is_discount=false)=>{
    let total_amount = 0;
    for (let index = 0; index < pilist.length; index++) {
      const element = pilist[index];
      let amount = element.amount;
      if(amount!==""){
        amount = parseFloat(amount);
        total_amount = total_amount+amount;
      }
    }
    setPayableFee(total_amount);
  }
  const addMorePaymentItem = () =>{
    let pilist = [...payment_item_list];
    pilist.push(paymentItemListInitialState);
    handleSetPaymentList(pilist);
  }
  const handleRemoveItem = (index,pending_index) =>{
    setIsRefreshing(true);
    let plist = [...payment_item_list];
   
    if(demand_bill_generate_type!=='detailed'){
      let part_info = plist[index];
      if(parseFloat(part_info.particulars)===3){
        if(plist.length>1){
          plist.splice(index,1);
          handleSetPaymentList(plist);
        }else{
          handleSetPaymentList([paymentItemListInitialState]);
        }
        if(pending_index!==undefined){
          let pen_payment = [...pending_payments];
          if(pen_payment[pending_index].selected!==undefined){
            pen_payment[pending_index].selected = false;
          }
          setPendingPayments(pen_payment);
        }
        let is_rmv = false;
        let i = plist.length;
        while (i--) {
            if (parseFloat(plist[i].fee) ===0) {
              plist.splice(i, 1);
            }
        }
        if(is_rmv===true){
          handleSetPaymentList(plist);
        }
      }else{
        if(plist.length>1){
          plist.splice(index,1);
          handleSetPaymentList(plist);
        }else{
          handleSetPaymentList([paymentItemListInitialState]);
        }
        if(pending_index!==undefined){
          let pen_payment = [...pending_payments];
          if(pen_payment[pending_index].selected!==undefined){
            pen_payment[pending_index].selected = false;
          }
          setPendingPayments(pen_payment);
        }
      }
    }else{
      if(plist.length>1){
        plist.splice(index,1);
        handleSetPaymentList(plist);
      }else{
        handleSetPaymentList([paymentItemListInitialState]);
      }
      if(pending_index!==undefined){
        let pen_payment = [...pending_payments];
        if(pen_payment[pending_index].selected!==undefined){
          pen_payment[pending_index].selected = false;
        }
        setPendingPayments(pen_payment);
      }
    }
    setTimeout(() => {
      setIsRefreshing(false);
    }, 5);
  }
   
  const handleChangePaymentMode = (id,value) =>{
    setSelectedPaymentMode(value);
  }
  const handleChangeParticulars = (id,value,moreValue) =>{
    let pilist = [...payment_item_list];
    let fee_structure = {...student_fee_structure};
    let fee = '';
    let type_name = '';
    let short_month = month_date.toLocaleString('default', { month: 'short' });
    short_month = short_month.toLowerCase();
    if(fee_structure[value]!==undefined && fee_structure[value][short_month]!==undefined){
      fee = fee_structure[value][short_month];
    }
    if(fee===""){
      for (let index = 0; index < payment_type_list.length; index++) {
        const element = payment_type_list[index];
        if(parseInt(element.id)===parseInt(value)){
          let type_name = element.name;
          let global_fee = element.global_fee;
          let class_fee = element.class_fee;
          if(selected_class>0){
            if(class_fee!==null && class_fee[selected_class]!==undefined && class_fee[selected_class]!==""){
              fee = class_fee[selected_class];
              break;
            }else if(global_fee!=="" && global_fee!==null){
              fee = global_fee;
              break;
            }
          }
        }
      }
    }
    if((parseInt(value)===3) && contact_info!==null && contact_info.id!==undefined && contact_info.id>0){
      if(contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)>0){
        fee = contact_info.opening_balance; 
      }
    }
    if(moreValue.is_salary!==undefined && moreValue.is_salary===true){
        let salary = contact_info.salary;
        fee = salary;
    }
    pilist[id]['is_uniform'] = false;
    if(moreValue.is_uniform_fee!==undefined && moreValue.is_uniform_fee===true){
      pilist[id]['is_uniform'] = true;
      pilist[id]['uniform_list'] = moreValue.uniform_list;
    }
    pilist[id]['particulars'] = value;
    pilist[id]['particulars_name'] = type_name;
    pilist[id]['fee'] = fee;
    pilist[id]['amount'] = fee;
    handleSetPaymentList(pilist);
  }
  const handleChangeDate = (id,value) =>{
    setPaymentDate(value);
  }
  const handleChangeMonthDate = (id,value) =>{
    setMonthDate(value);
  }
  const handleShowExisitingTemplate = () =>{
    setPaymentItemList(existingTemplate);
    handleSetTotalAmount(existingTemplate,false);
  }
  const handleChangeRepeat = (e) =>{
    let is_rep = e.target.checked;
    setIsRepeat(is_rep);
  }
  const handleSetRemarks = (value) =>{
    setRemarks(value)
  }
  const handleSelectPendingPayment = (index) =>{
    let pilist = [...payment_item_list];
    let pen_payment = [...pending_payments];
    let pitem = pen_payment[index];
    if(demand_bill_generate_type!=='detailed'){
      if(parseInt(pitem.particulars)===3){
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          handleSetPaymentList([]);
          pilist = [];
          setTimeout(() => {
            for (let pmi = 0; pmi < pen_payment.length; pmi++) {
              let element = pen_payment[pmi];
              if(parseInt(element.fee)===0){
                element.payment_meta_id = 0;
                element.reference = '';
                element.pending_index = pmi;
                element.is_prev_fee = true;
                pilist.push(element);
                handleSetPaymentList(pilist);
              }
            }
            pilist.push(pitem);
            handleSetPaymentList(pilist);

            

          }, 10);
        }else{
          for (let pmi = 0; pmi < pen_payment.length; pmi++) {
            let element = pen_payment[pmi];
            if(parseInt(element.fee)===0){
              element.payment_meta_id = 0;
              element.reference = '';
              element.pending_index = pmi;
              element.is_prev_fee = true;
              pilist.push(element);
              handleSetPaymentList(pilist);
            }
          }
          pilist.push(pitem);
          handleSetPaymentList(pilist);
          
        }
        pen_payment[index].selected = true;
        setPendingPayments(pen_payment);
      }else{
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          handleSetPaymentList([]);
          pilist = [];
          setTimeout(() => {
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }, 10);
        }else{
          pilist.push(pitem);
          handleSetPaymentList(pilist);
        }
        pen_payment[index].selected = true;
        setPendingPayments(pen_payment);
      }
    }else{
      pitem.payment_meta_id = 0;
      pitem.reference = '';
      pitem.pending_index = index;
      if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
        handleSetPaymentList([]);
        pilist = [];
        setTimeout(() => {
          pilist.push(pitem);
          handleSetPaymentList(pilist);
        }, 10);
      }else{
        pilist.push(pitem);
        handleSetPaymentList(pilist);
      }
      pen_payment[index].selected = true;
      setPendingPayments(pen_payment);
    }
  }
  const handleChangeReceivedAmount = (value) =>{
    setReceivedFee(value);
  }
  const handleProceedToPayReceivedAmount = () =>{
    setIsProcessingRcvdFee(true);
    handleSetPaymentList([]);
    let p_payments = [...pending_payments];
    for (let index = 0; index < p_payments.length; index++) {
      p_payments[index].selected = false;
    }
    setPendingPayments(p_payments);
    setTimeout(() => {
      handle_process_receieved_payment();
    }, 10);
  }
  const handle_process_receieved_payment =() =>{
    let pilist = [];
    let p_payments = [...pending_payments];
    let total_paid_amount = 0
    if(receivedFee!=="" && receivedFee>0){
      let remaingFee = receivedFee;
      for (let index = 0; index < p_payments.length; index++) {
        const element = p_payments[index];
        let fee = parseFloat(element.fee);
        let pitem = p_payments[index];
        if(pitem.selected===true){
          continue;
        }
        remaingFee = remaingFee - fee;
        let payable = fee;
        if(remaingFee<0){
          payable =  fee + remaingFee;
        }
        total_paid_amount = total_paid_amount+payable;
        pitem.amount = payable;
        pitem.payment_meta_id = 0;
        pitem.reference = '';
        pitem.pending_index = index;
        if(payable>0){
          if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
            pilist = [];
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }else{
            pilist.push(pitem);
            handleSetPaymentList(pilist);
          }
          p_payments[index].selected = true;
          setPendingPayments(p_payments);
        }
        if(remaingFee<0){
          break;
        }
      }
      
      let totalAdvance = receivedFee - total_paid_amount;
      if(totalAdvance>0){
        
        let short_month = month_date.toLocaleString('default', { month: 'short' });
        short_month = short_month.toLowerCase();
        let year = month_date.getFullYear();
        let advance_item = {
            "payment_mode": 5,
            "particulars": 0,
            "fee_month": short_month,
            "fee_year": year,
            "fee": totalAdvance,
            "amount": totalAdvance,
            "particulars_name": "Advance",
            "payment_meta_id": 0,
            "reference": "",
            "pending_index": 10000,
            "selected": false
        };
        if(pilist.length===1 && pilist[0].amount==="" && pilist[0].particulars===""){
          pilist = [];
        }
        pilist.push(advance_item);
        handleSetPaymentList(pilist);
      }
    }
    setIsProcessingRcvdFee(false);
  }

  const handleSelectUniform = () =>{
   // setIsSelectingUniform(true);
  }

  const handleSelectYearlyPackage = () =>{
    setIsSelectingYearlyPackage(true);
    
    APP_HTTP.REQUEST('settings/get_yearly_package',{},true,true).then((response) => {
      let resp = response.data.response;
      setIsSelectingYearlyPackage(false);
      let short_month = month_date.toLocaleString('default', { month: 'short' });
      short_month = short_month.toLowerCase();
      let year = month_date.getFullYear();
      if(resp.length>0){
        setYearlyPackage(true);
        let pilist = [];
        for (let index = 0; index < resp.length; index++) {
          const element = resp[index];
          let price = element.price;
          let particular = element.particular;
          let advance_item = {
              "payment_mode": 5,
              "particulars": -2,
              "fee_month": short_month,
              "fee_year": year,
              "fee": price,
              "amount": price,
              "quantity": 1,
              "particulars_name": particular,
              "payment_meta_id": 0,
              "reference": "",
              "pending_index": 10001,
              "selected": false
          };
          pilist.push(advance_item);
        }
        handleSetPaymentList(pilist);
      }
    });
  }
  const handleChangeDiscount = (value) =>{
    let pilist = [...payment_item_list];
    setDiscount(value);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,value,late_fine);
  }
  const handleChangeLateFine = (value)=>{
    setlateFine(value);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,discount_amount,value);
  }
  const handleChangeManually = (is_checked) =>{
    setIsManually(is_checked);
    handleSetPaymentList([]);
    setFeeDues(0);
    setTotalAmount(0);
    setPayableFee(0);
    setTimeout(() => {
      let pilist = [{
        payment_meta_id:0,
        payment_mode:(props.is_demand_bill===true)?5:1,
        particulars:'',
        particulars_name:'',
        reference:'',
        amount:'',
      }];
      handleSetPaymentList(pilist);
      setFeeDues(0);
      setTotalAmount(0);
      setPayableFee(0);
    }, 100);
  }

  const handleChangeUniform = (id,value,list_item) =>{
    let pilist = [...payment_item_list];
    pilist[id].amount = list_item.fee;
    pilist[id].fee = list_item.fee;
    pilist[id].reference = value;
    handleSetPaymentList(pilist);
  }
  const handleProceedForFullPayment = () =>{
    setIsFullPayment(true);
    setIsSelectingFullPayment(true);
    APP_HTTP.REQUEST('payment/get_student_pending_payments',{student:contact_info.id,fetch:'all'},true,true).then((response) => {
      let fee_struct = response.data.fee_structure;
      setStudentFeeStructure(fee_struct);
      let resp = response.data.response;
      setIsSelectingFullPayment(false);
      if(resp[0]!==undefined){
        let list_items = resp[0].list_item;
        setPendingPayments(list_items);
        let pending_amount = 0;
        for (let index = 0; index < list_items.length; index++) {
          const element = list_items[index];
          pending_amount = pending_amount + parseFloat(element.fee)
        }
        setTotalPendingAmount(pending_amount);
        if(document.getElementById('received-amount-box')){
          document.getElementById('received-amount-box').focus();
        }
      }
  }); 
  }
  const handleViewItem = (id)=>{
    if(AppUtils.has_access(state,'503_demand_bill_view')===false){
      return false;
    }
    let payment_info = demand_bill_list[id];
    let payment_id = payment_info.demand_bill_payment_id;
    dispatch(handleChangeMenu('payment','view/demand-bill/'+payment_id,'View Demand Bill',false));
    dispatch(handleToggleViewPayment(true));
    dispatch(handleSetPaymentInfo(payment_info));
  }
  const handleConvertToPayment = (index) =>{
    let plist = [...demand_bill_list];
    let id = plist[index].demand_bill_payment_id;
    window.location.href = '/payment/convert/'+id;
  }
  const handleChangePaymentModeData = (id,value)=>{
    let pmdata = {...payment_mode_data};
    pmdata[id] = value;
    setPaymentModeData(pmdata);
    setIsCalculating(true);
    handleCalculateFinalFee(pmdata,discount_amount,late_fine);
  }
  const handleCalculateFinalFee = (pmdata,discount,fine)=>{
    
    let total_paid = 0;
    for (const key in pmdata) {
      let paid_amount = pmdata[key];
      if(paid_amount!==""){
        total_paid = total_paid + parseFloat(paid_amount);
      }
    }
    if(discount===''){
      discount = 0;
    }
    let l_fee = fine;
    if(l_fee===''){
      l_fee = 0;
    }
    l_fee = parseFloat(l_fee);
    discount = parseFloat(discount);
    setTotalPaid(total_paid);
    total_paid = total_paid + discount;
    let final_payable = total_payable_fee;
    if(total_staff_advance_payment>0){
      final_payable = final_payable - total_staff_advance_payment;
    }
    let calculate_fee_dues = final_payable - (total_paid - l_fee);
    setFeeDues(calculate_fee_dues);
    setIsCalculating(false);
  }
  const handleChangeFromPaymentType = (type)=>{
    setFromPaymentType(type);
  }
  const handleSelectStaffAdvancePayment = (checked,idx) =>{
    let st_adv_pyment = [...staff_advance_payment_list];
    st_adv_pyment[idx].is_selected = checked;
    setStaffAdvancePaymentList(st_adv_pyment);
    let total_adv_amount = 0;
    for (let index = 0; index < st_adv_pyment.length; index++) {
      const element = st_adv_pyment[index];
      if(element.is_selected===true){
        let adv_paid_amount = parseFloat(element.paid_amount);
        total_adv_amount = total_adv_amount + adv_paid_amount;
      }
    }
    setStaffTotalAdvanceAmount(total_adv_amount);
    let pmdata = {...payment_mode_data};
    setIsCalculating(true);
    setTimeout(() => {
      handleCalculateFinalFee(pmdata,discount_amount,late_fine);
    }, 100);
  }
  return (
    <div id="create-payment">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
          <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2} className="mrtop-10"> 

                  <Grid item xs={12} sm={12} md={2}>
                    <Typography variant='span' sx={{fontSize:'30px',fontWeight:'bold',color:'gray'}}>{(current_sequence!=="")?current_sequence:''}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} sx={{mt:'8px'}}>
                  {(is_show_opt_button===true) &&
                  <FormControl>
                      <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="row-radio-buttons-group"
                          value={payment_type}
                          onChange={handleChangePaymentType}
                      >
                        {(props.is_demand_bill===true) &&
                        <FormControlLabel value="payment_in" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
'&.Mui-checked': {
color: 'green',
},}}/>} label="DEMAND BILL" />
}
                      {(props.is_demand_bill!==true) &&
                      <>
                          <FormControlLabel value="payment_in" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
'&.Mui-checked': {
color: 'green',
},}}/>} label="RECEIVE PAYMENT" />
{(props.is_convert_to_payment!==true) &&
<>
                          <FormControlLabel value="payment_out" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'red',
'&.Mui-checked': {
color: 'red',
},}}/>} label="SEND PAYMENT" />
<FormControlLabel value="staff_advance" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'red',
'&.Mui-checked': {
color: 'red',
},}}/>} label="STAFF ADVANCE" />
</>
}
</>
}
                      </RadioGroup>
                  </FormControl>
                  }
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}></Grid>
                  {(payment_type!=='') &&
                  <>
                  <Grid item xs={12} sm={12} md={2}> 
                     {/*  <BasicDatePicker label="For Month" size="small" id="month_date" handleChangeData={handleChangeMonthDate} defaultValue={month_date} format='MMM YYYY' views={['year', 'month']}/> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}> 
                      <BasicDatePicker label="Payment Date" size="small" id="payment_date" handleChangeData={handleChangeDate} defaultValue={payment_date}/>
                  </Grid>
                  </>
                  }
                  <Grid item xs={12} sm={12} md={3}></Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          {(payment_type!=='') &&
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} className="mrtop-10"> 
              {(payment_type==='payment_in') &&
              <>
              {/* <Grid item xs={12} sm={12} md={2} spacing={2}>
                {(from_payment_type==='student') &&
                  <Button variant='contained' size='medium' color='success' onClick={()=>handleChangeFromPaymentType('student')}  style={{width:'100%'}}>
                    <CheckCircleOutlineRounded /> From Student
                  </Button>
                }
                {(from_payment_type!=='student') &&
                  <Button variant='outlined' size='medium' onClick={()=>handleChangeFromPaymentType('student')} style={{width:'100%'}}>From Student</Button>
                }
             
                {(from_payment_type==='other') &&
                  <Button variant='contained' size='medium' color='success' onClick={()=>handleChangeFromPaymentType('other')} style={{marginTop:'5px',width:'100%'}}>
                    <CheckCircleOutlineRounded /> From Other
                  </Button>
                }
                {(from_payment_type!=='other') &&
                  <Button variant='outlined' size='medium' onClick={()=>handleChangeFromPaymentType('other')} style={{marginTop:'5px',width:'100%'}}>From Other</Button>
                }
              </Grid> */}
              {(is_college===true) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <SessionDropdown handleChangeData={handleChangeSession}/>
                </Grid>
              }
               <Grid item xs={12} sm={12} md={2}>
                <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
              </Grid>
              {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={2}>
                  <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                </Grid>
              }
              </>
              }
              {(selected_class>0 || (payment_type==='payment_out' || payment_type==='staff_advance')) &&
              <>
              <Grid item xs={12} sm={12} md={3}>
                  <Autocomplete
                      value={contact_info}
                      noOptionsText={'No '+payer_type+' Found ! Type To Search'}
                      onChange={(event, newValue) => handleChangeValue(newValue)}
                      onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                      disablePortal
                      id="payee_dropdown"
                      options={payee_payer_list}
                      size="small"
                      renderInput={(params) => <TextField {...params} label={'Select '+payer_type} />}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={3} sx={{textAlign:'center'}}>
                {(contact_info!==null && contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)>0) &&
                <Typography sx={{background: '#e31119',color: '#fff',padding: '7px'}}>BACK DUES : ₹ {AppUtils.currency_format(contact_info.opening_balance)}</Typography>
                }
                {(contact_info!==null && contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)<0) &&
                  <Typography sx={{background: 'green',color: '#fff',padding: '7px'}}>ADVANCE PAYMENT : ₹ {AppUtils.currency_format(Math.abs(contact_info.opening_balance))}</Typography>
                }
              </Grid>
              </>
              }
              <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
            </Grid>
          </Grid>
          }
        </Grid>
        {(is_show_payment_list===true) &&
          <Grid container spacing={2} className="mrtop-30">
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                 {/*  <Grid item xs={12} sm={12} md={12}>
                    {(props.is_demand_bill===true) &&
                    <>
                      <FormControl>
                          <FormGroup>
                            
                            <FormControlLabel control={<Checkbox checked={repeat_db}
                              onChange={handleChangeRepeat} sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
                              '&.Mui-checked': {
                              color: 'green',
                              },}}/>} label="MOVE TO AUTO SEND LIST FOR COMING/EVERY MONTH(S)" />
                        </FormGroup>
                    </FormControl>
                    <Typography sx={{fontSize:'11px',fontWeight:'bold',color:'red'}}>IF ENABLED, IT WILL MOVE THIS BILL TO AUTO SEND LIST FOR COMING/EVERY MONTH(S), ONE CLICK REQUIRED</Typography>
                    </>
                  }
                  </Grid> */}
                  {/* {(isShowExistingTemplate===true) &&
                  <Grid item xs={12} sm={12} md={4} sx={{textAlign:'right'}}>
                      <Button variant='outlined' size='small' onClick={handleShowExisitingTemplate}>Use Existing Template</Button>
                  </Grid>
                  } */}
                  {(payment_type==='payment_out' && payment_id===0) &&
                   <Grid item xs={12} sm={12} md={4}>
                  
                   <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll',marginTop:'10px'}}>
                   <TableContainer component={Paper}>
                   <Table aria-label="customized table">
                     <TableHead>
                       <TableRow>
                         <StyledTableCell colSpan={3}>STAFF ADVANCE PAYMENT</StyledTableCell>
                       </TableRow>
                       <TableRow>          
                           <StyledTableCell></StyledTableCell>
                           <StyledTableCell>ADVANCE #</StyledTableCell>
                           <StyledTableCell>AMOUNT</StyledTableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {staff_advance_payment_list && staff_advance_payment_list.map((row,index) => (
                           <StyledTableRow key={row.payment_sequence}>
                           <StyledTableCell>
                            <Checkbox onChange={(e)=>handleSelectStaffAdvancePayment(e.target.checked,index)} checked={row.is_selected} sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
                                '&.Mui-checked': {
                                color: 'green',
                            },}}/>
                           </StyledTableCell>
                           <StyledTableCell component="th" scope="row" sx={{cursor:'pointer'}}>
                               <Typography variant='h6'>{row.payment_sequence}</Typography>
                               <Typography variant='p'>{row.payment_date_display}</Typography>
                           </StyledTableCell>
                           
                           <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                           
                           </StyledTableRow>
                       ))}
                       {(is_loading_payments===false && staff_advance_payment_list.length===0) &&
                         <StyledTableRow>
                           <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                             No Advance Payment(s) Found.
                           </StyledTableCell>
                         </StyledTableRow>
                       }
                       </TableBody>
                   </Table>
                   </TableContainer>
                   </Box>
                   <Divider />
                  
                 </Grid>
                  }
                  {((payment_type==='payment_in' && loaded_payment_type!=="") || props.is_convert_to_payment==true) &&
                  <>
                    <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                    {(is_selecting_full_payment===false) &&
                      <Button variant='outlined' size='small' style={{marginBottom:'10px'}} onClick={handleProceedForFullPayment}>Full Payment</Button>
                      }
                      {(is_selecting_full_payment===true) &&
                      <LoaderButton variant='outlined' size='small' style={{marginBottom:'10px'}}  />
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {(is_selecting_manually===false) &&
                      <>
                      <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll'}}>
                      <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={5}>PENDING DEMAND BILL REQUEST(S)</StyledTableCell>
                            
                          </TableRow>
                          <TableRow>          
                              <StyledTableCell>#</StyledTableCell>
                              <StyledTableCell>AMOUNT</StyledTableCell>
                              <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {demand_bill_list && demand_bill_list.map((row,index) => (
                              <StyledTableRow key={row.payment_sequence} style={{backgroundColor:(parseInt(selected_db_id)===parseInt(row.demand_bill_payment_id))?'#f9fffb':''}}>
                              
                              <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                                  <Typography variant='h6'>{row.payment_sequence}</Typography>
                                  <Typography variant='p'>{row.payment_date_display}</Typography>
                              </StyledTableCell>
                              
                              <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                              
                              <StyledTableCell sx={{textAlign:'right'}}>
                                  {(row.status===1 || row.status==='1') &&
                                  <>
                                  {(AppUtils.has_access(state,'503_demand_bill_convert')) &&
                                    <Button variant='outlined' size='small' onClick={()=>handleConvertToPayment(index)}>PAY</Button>
                                  }
                                  </>
                                  }
                              
                              </StyledTableCell>
                              
                              </StyledTableRow>
                          ))}
                          {(is_loading_demand_bill===false && demand_bill_list.length===0) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                No Pending Demand Bill Found.
                              </StyledTableCell>
                            </StyledTableRow>
                          }
                          {(is_loading_demand_bill===true) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                          }
                          </TableBody>
                      </Table>
                      </TableContainer>
                      </Box>
                      <Divider />
                      <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll',marginTop:'10px'}}>
                      <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={(is_convert_to_pay===false)?2:1}>PENDING FEE</StyledTableCell>
                            <StyledTableCell style={{textAlign:'right'}}>
                            {(is_selecting_full_payment===true) &&
                              <Typography variant='h4'>
                                Fetching...
                              </Typography>
                            }
                            {(is_selecting_full_payment===false) &&
                              <Typography variant='h4'>
                              ₹{AppUtils.currency_format(totalPendingAmount)}
                              </Typography>
                            }
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>FEE TYPE</StyledTableCell>
                            <StyledTableCell style={{textAlign:'right'}}>FEE</StyledTableCell>
                            {(is_convert_to_pay===false) &&
                              <StyledTableCell></StyledTableCell>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(is_selecting_full_payment===false) &&
                          <>
                          {pending_payments.map((value,index)=>{
                              return (
                                <>
                                {(value.fee>0) &&
                                <StyledTableRow>
                                  <StyledTableCell style={{textTransform:'capitalize'}}>
                                    {(parseInt(value.particulars)===3) &&
                                      <>
                                        {(parseFloat(value.fee)>0)?value.particulars_name:'Advance Payment'}
                                      </>
                                    }
                                     {(parseInt(value.particulars)!==3) &&
                                      <>
                                        {value.particulars_name}
                                      </>
                                    }
                                  </StyledTableCell>
                                  <StyledTableCell style={{textAlign:'right'}}>{value.fee}</StyledTableCell>
                                  {(is_convert_to_pay===false) &&
                                  <StyledTableCell style={{textAlign:'right'}}>
                                    {(value.selected!==true) &&
                                      <Button onClick={()=>handleSelectPendingPayment(index)} size='small' sx={{fontSize:'9px'}}><Typography variant='span' style={{marginTop:'3px'}}>Select</Typography> <KeyboardDoubleArrowRight size="medium"/></Button>
                                    }
                                    {(value.selected===true) &&
                                      <CheckRounded/>
                                    }
                                  </StyledTableCell>
                                  }
                                </StyledTableRow>
                                }
                                </>
                              )
                          })}
                          </>
                          }
                          {(pending_payments.length===0) &&
                           <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>No Pending Fee Found</StyledTableCell>
                            </StyledTableRow>
                          }
                          {(is_selecting_full_payment===true) &&
                          <>
                           <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                           <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                           <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                           <StyledTableRow>
                              <StyledTableCell colSpan={3} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                          }
                        </TableBody>
                      </Table>
                      </TableContainer>
                      </Box>
                      <Divider />
                      <Box className="custom-scroll" style={{maxHeight:'500px',overflowY:'scroll',marginTop:'10px'}}>
                      <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={5}>LATEST 5 PAID PAYMENTS</StyledTableCell>
                          </TableRow>
                          <TableRow>          
                              <StyledTableCell>#</StyledTableCell>
                              <StyledTableCell>AMOUNT</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paid_payments_list && paid_payments_list.map((row,index) => (
                              <StyledTableRow key={row.payment_sequence}>
                              
                              <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                                  <Typography variant='h6'>{row.payment_sequence}</Typography>
                                  <Typography variant='p'>{row.payment_date_display}</Typography>
                              </StyledTableCell>
                              
                              <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                              
                              </StyledTableRow>
                          ))}
                          {(is_loading_payments===false && paid_payments_list.length===0) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={2} style={{textAlign:'center'}}>
                                No Payment(s) Found.
                              </StyledTableCell>
                            </StyledTableRow>
                          }
                          {(is_loading_payments===true) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={2} style={{textAlign:'center'}}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                              </StyledTableCell>
                            </StyledTableRow>
                          }
                          </TableBody>
                      </Table>
                      </TableContainer>
                      </Box>
                      <Divider />
                      </>
                      }
                    </Grid>
                    </>
                  }
                  <Grid item xs={12} sm={12} md={(payment_type=='staff_advance')?12:(payment_id===0 || props.is_convert_to_payment===true)?8:12}>
                  <Grid container spacing={2}>
                 {/*  {((payment_type==='payment_in' && loaded_payment_type!=='') || props.is_convert_to_payment==true) &&
                  <>
                        <Grid item xs={12} sm={12} md={4}>
                       {(is_selecting_manually===false) &&
                       <>
                          <TextField
                            margin="dense"
                            label="Enter Received Amount"
                            type="text"
                            fullWidth
                            size="small"
                            id="received-amount-box"
                            onChange={(e)=>handleChangeReceivedAmount(e.target.value)}
                          />
                          <Typography style={{fontSize:'12px'}}>Or SELECT PENDING FEE FROM THE LIST</Typography>
                        </>
                       }
                       {(props.is_convert_to_payment==undefined || props.is_convert_to_payment==false) &&
                        <AppCheckbox label={'or SELECT FEE TYPE MANUALLY'} value={'manually'}  handleChangeCB={(e)=>handleChangeManually(e.target.checked)}/>
                       }
                    </Grid>
                    {(is_selecting_manually===false) &&
                       <>
                    <Grid item xs={12} sm={12} md={2} style={{marginTop:'9px'}}>
                      {(is_process_rcvd_fee===false) &&
                        <Button  size='medium' variant='outlined' onClick={handleProceedToPayReceivedAmount}>Proceed</Button>
                      }
                      {(is_process_rcvd_fee===true) &&
                        <LoaderButton size='medium' variant='outlined' />
                      }
                    </Grid>
                    {(props.is_convert_to_payment===undefined || props.is_convert_to_payment===false) &&
                    <Grid item xs={12} sm={12} md={6} style={{marginTop:'14px',textAlign:'right'}}>
                      {(is_selecting_yearly_package===false) && 
                        <Button  size='medium' variant='outlined' onClick={handleSelectYearlyPackage}>Yearly Package</Button>
                      }
                      {(is_selecting_yearly_package===true) &&
                        <LoaderButton size='medium' variant='outlined' />
                      }
                    </Grid>
                    }
                    </>
                  }
                  </>
                  } */}
                  <Grid item xs={12} sm={12} md={12}>
                    {(is_refresh_list===false) &&
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>SNO</StyledTableCell>
                          <StyledTableCell>FEE TYPE</StyledTableCell>
                          <StyledTableCell>REFERENCE</StyledTableCell>
                          {(is_selected_yearly_package===true) &&
                          <>
                            <StyledTableCell>PRICE</StyledTableCell>
                            <StyledTableCell>QUANTIY</StyledTableCell>
                            <StyledTableCell>TOTAL</StyledTableCell>
                          </>
                          }
                          {(is_selected_yearly_package===false) &&
                            <StyledTableCell>FEE</StyledTableCell>
                          }
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payment_item_list.map((value,index)=>{
                            if(props.is_convert_to_payment===true && value.amount>0){
                              item_index = item_index+1;
                            }else if(props.is_convert_to_payment!==true && value.is_prev_fee===undefined){
                              item_index = item_index+1;
                            }else if(value.amount>0){
                              item_index = item_index+1;
                            }
                            return (
                              <>
                              {(props.is_convert_to_payment===true && value.amount>0) &&
                                 <StyledTableRow>
                                 <StyledTableCell>
                                   <Typography>{item_index}</Typography>
                                 </StyledTableCell>
                                 <StyledTableCell>
                                   {(value.particulars>0 || value.particulars==='') &&
                                     <PaymentTypes show="dropdown" defaultValue={value.particulars} handleChangeData={handleChangeParticulars} id={index} label={(value.fee_month!==undefined && value.fee_month!=="")?value.fee_month.toUpperCase():'Fee Types'} target={payment_type} disabled={(value.selected===true || is_convert_to_pay===true)?true:false}/>
                                   }
                                   {(parseInt(value.particulars)===0) &&
                                     <Typography>Advance</Typography>
                                   }
                                   {(parseInt(value.particulars)===-1) &&
                                     <Typography>Advance Redeemed</Typography>
                                   }
                                   {(parseInt(value.particulars)===-2) &&
                                     <Typography>{value.particulars_name}</Typography>
                                   }
                                 </StyledTableCell>
                                 <StyledTableCell>
                                   {(value.is_uniform===true) &&
                                     <AppDropDown label="Select Uniform" list_item={value.uniform_list} size="small" defaultValue={value.reference} handleChangeUniform={handleChangeUniform} id={index}/>
                                   }
                                   {(value.is_uniform!==true) &&
                                     <TextField
                                       margin="dense"
                                       label="Reference"
                                       type="text"
                                       fullWidth
                                       size="small"
                                       value={value.reference}
                                       onChange={(event)=>handleChangeData(index,'reference',event.target.value)}
                                     />
                                   }
                                 </StyledTableCell>
                                 {(is_selected_yearly_package===true) &&
                                   <>
                                   <StyledTableCell>
                                     <Typography>{value.fee}</Typography>
                                   </StyledTableCell>
                                   <StyledTableCell>
                                     <TextField
                                       margin="dense"
                                       label="Quantity"
                                       type="text"
                                       fullWidth
                                       size="small"
                                       value={value.quantity}
                                       onChange={(event)=>handleChangeData(index,'quantity',event.target.value)}
                                     />
                                   </StyledTableCell>
                                   </>
                                 }
                                 
                                 <StyledTableCell>
                                   <TextField
                                     margin="dense"
                                     label="Fee"
                                     type="text"
                                     fullWidth
                                     size="small"
                                     value={value.amount}
                                     disabled={(payment_type==='payment_out' || payment_type==='staff_advance')?false:true}
                                     onChange={(event)=>handleChangeData(index,'amount',event.target.value)}
                                   />
                                   {(parseInt(value.particulars)===-1) &&
                                     <Typography variant='span' style={{color:'red',fontSize:'12px'}}>Please do not remove negative(-) from value</Typography>
                                   }
                                 </StyledTableCell>
                                 <StyledTableCell sx={{textAlign:'right'}}>
                                   {(is_convert_to_pay===false && payment_id===0) &&
                                     <Close fontSize='small' sx={{cursor:'pointer'}} onClick={() => handleRemoveItem(index,value.pending_index)}/>
                                   }
                                 </StyledTableCell>
                                 <StyledTableCell sx={{textAlign:'right'}}>
                                   {((payment_item_list.length - 1)==index && is_convert_to_pay===false) &&
                                     <Button onClick={addMorePaymentItem} size='small' sx={{fontSize:'9px'}}>Add More</Button>
                                   }
                                 </StyledTableCell>
                                 </StyledTableRow>
                              }
                              {(props.is_convert_to_payment!==true && value.is_prev_fee===undefined) &&
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>{item_index}</Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {(value.particulars>0 || value.particulars==='') &&
                                    <PaymentTypes show="dropdown" defaultValue={value.particulars} handleChangeData={handleChangeParticulars} id={index} label={(value.fee_month!==undefined && value.fee_month!=="")?value.fee_month.toUpperCase():'Fee Types'} target={payment_type} disabled={(value.selected===true || is_convert_to_pay===true)?true:false}/>
                                  }
                                  {(parseInt(value.particulars)===0) &&
                                    <Typography>Advance</Typography>
                                  }
                                  {(parseInt(value.particulars)===-1) &&
                                    <Typography>Advance Redeemed</Typography>
                                  }
                                  {(parseInt(value.particulars)===-2) &&
                                    <Typography>{value.particulars_name}</Typography>
                                  }
                                </StyledTableCell>
                                <StyledTableCell>
                                  {(value.is_uniform===true) &&
                                    <AppDropDown label="Select Uniform" list_item={value.uniform_list} size="small" defaultValue={value.reference} handleChangeUniform={handleChangeUniform} id={index}/>
                                  }
                                  {(value.is_uniform!==true) &&
                                    <TextField
                                      margin="dense"
                                      label="Reference"
                                      type="text"
                                      fullWidth
                                      size="small"
                                      value={value.reference}
                                      onChange={(event)=>handleChangeData(index,'reference',event.target.value)}
                                    />
                                  }
                                </StyledTableCell>
                                {(is_selected_yearly_package===true) &&
                                  <>
                                  <StyledTableCell>
                                    <Typography>{value.fee}</Typography>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      margin="dense"
                                      label="Quantity"
                                      type="text"
                                      fullWidth
                                      size="small"
                                      value={value.quantity}
                                      onChange={(event)=>handleChangeData(index,'quantity',event.target.value)}
                                    />
                                  </StyledTableCell>
                                  </>
                                }
                                
                                <StyledTableCell>
                                  <TextField
                                    margin="dense"
                                    label="Fee"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    value={value.amount}
                                    disabled={(payment_type==='payment_out' || payment_type==='staff_advance')?false:true}
                                    onChange={(event)=>handleChangeData(index,'amount',event.target.value)}
                                  />
                                  {(parseInt(value.particulars)===-1) &&
                                    <Typography variant='span' style={{color:'red',fontSize:'12px'}}>Please do not remove negative(-) from value</Typography>
                                  }
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign:'right'}}>
                                  {(is_convert_to_pay===false && payment_id===0) &&
                                    <Close fontSize='small' sx={{cursor:'pointer'}} onClick={() => handleRemoveItem(index,value.pending_index)}/>
                                  }
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign:'right'}}>
                                  {((payment_item_list.length - 1)==index && is_convert_to_pay===false) &&
                                    <Button onClick={addMorePaymentItem} size='small' sx={{fontSize:'9px'}}>Add More</Button>
                                  }
                                </StyledTableCell>
                              </StyledTableRow>
                              }
                              </>
                            )
                        })}
                        <StyledTableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            {(is_selected_yearly_package) &&
                            <>
                             <StyledTableCell colSpan={2}></StyledTableCell>
                            </>
                            }
                            <StyledTableCell><Typography variant='h5'>₹ {AppUtils.currency_format(total_payable_fee)}</Typography></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                  <Box>
                    <TextField
                        margin="dense"
                        label="Discount(₹)"
                        type="text"
                        fullWidth
                        size="small"
                        
                        onChange={(e)=>handleChangeDiscount(e.target.value)}
                        defaultValue={discount_amount}
                      />
                    </Box>
                    {(payment_type==='payment_in') &&
                    <Box>
                      <TextField
                          margin="dense"
                          label="Late Fine(₹)"
                          type="text"
                          fullWidth
                          size="small"
                          onChange={(e)=>handleChangeLateFine(e.target.value)}
                          defaultValue={late_fine}
                        />
                    </Box>
                    }
                    {(payment_modes.map((value,index)=>{
                      return(
                        <Box style={{marginTop:'15px'}}>
                        <TextField
                          id={"payment-mode-"+index}
                          label={value.value}
                          fullWidth
                          size='small'
                          InputProps={{
                              startAdornment: <InputAdornment position="start">
                                {(value.value==='Cash') &&
                                  <CurrencyRupee />
                                }
                                {(value.value==='Online') &&
                                  <PhoneAndroid />
                                }
                                {(value.value==='Card') &&
                                  <CreditCard />
                                }
                                {(value.value==='Cheque') &&
                                  <Edit />
                                }
                              </InputAdornment>,
                          }}
                         
                          defaultValue={payment_mode_data[value.key]}
                          onChange={(e)=>handleChangePaymentModeData(value.key,e.target.value)}
                          />
                          </Box>
                      )
                    }))}
                    
                   </Grid>
                 
                  <Grid item xs={12} sm={12} md={4}>
                    {(student_advance_payment>0) &&
                      <FormControl sx={{marginBottom:'12px'}}>
                          <FormGroup>
                              <FormControlLabel control={<Checkbox checked={is_redeem_advance_payment}
                                onChange={handleRedeemAdvancePayment} sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
                                '&.Mui-checked': {
                                color: 'green',
                                },}}/>} label={'REDEEM ADVANCE PAYMENT ('+AppUtils.currency_format(student_advance_payment)+')'} />
                          </FormGroup>
                      </FormControl>
                    }
                    {(is_calculating===false) &&
                    <Box>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    <Typography variant='h6'>
                      Payable {(payment_type==='payment_id')?'Fee':' Amount'} : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_payable_fee)}</Typography>
                    </Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    {(total_staff_advance_payment>0 && total_payable_fee>0) &&
                    <>
                    <Typography variant='h6' style={{color:'red'}}>
                      Advance Taken : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_staff_advance_payment)}</Typography>
                    </Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    <Typography variant='h6'>
                      Total Payable : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(total_payable_fee - total_staff_advance_payment)}</Typography>
                    </Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                    }
                    {(discount_amount>0) &&
                    <>
                    <Typography variant='h6'>Discount : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(discount_amount)}</Typography></Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                    }
                   
                    {(late_fine>0) &&
                    <>
                    <Typography variant='h6' style={{color:'#00e900'}}>Late Fine : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(late_fine)}</Typography></Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                    }
                    <Typography variant='h6'>
                      Paid {(payment_type==='payment_id')?'Fee':' Amount'} : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(payment_paid_amount)}</Typography>
                    </Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </Box>
                    }
                     {(fee_dues>0) &&
                    <>
                    <Typography variant='h6' style={{color:'red'}}>Fee Dues : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(fee_dues)}</Typography></Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                    }
                    {(fee_dues<0) &&
                    <>
                    <Typography variant='h6' style={{color:'#00e900'}}>Advance : <Typography variant='span' style={{float:'right',fontSize:'24px',fontWeight:'bold'}}>₹{AppUtils.currency_format(Math.abs(fee_dues))}</Typography></Typography>
                    <Divider style={{marginTop:'10px', marginBottom:'10px'}}/>
                    </>
                    }
                    {/* <AppDropDown label="Payment Mode" list_item={payment_modes} size="small" handleChangeData={handleChangePaymentMode} defaultValue={selected_payment_mode}/> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box style={{marginTop:'15px'}}>
                      <Textarea placeholder="Notes (if any)" defaultValue={remarks} minRows={5} onChange={(event)=>handleSetRemarks(event.target.value)}/>
                    </Box>
                   </Grid>
                  </Grid>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          </Grid>
        }
        {(is_show_save_payment===true) &&
          <Grid container spacing={2} className="mrtop-30">
            
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid  item xs={12} sm={12} md={6}>
                      {(props.payment_data) &&
                      <Button variant="outlined"  sx={{mt:'13px'}} onClick={handleCancelEdit}>Cancel</Button>
                      }
                  </Grid>
                  <Grid  item xs={12} sm={12} md={6} sx={{textAlign:'right'}}>
                  {(isSaveClicked===false) &&
                  <>
                  {(payment_type!=='staff_advance') &&
                      <Button variant="contained" sx={{mt:'13px'}} onClick={() => handleSaveInformation('view')}>Save & Print</Button>
                  }
                  {(payment_type==='staff_advance') &&
                      <Button variant="contained" sx={{mt:'13px'}} onClick={() => handleSaveInformation('list')}>Save & List</Button>
                  }
                      <Button variant="contained" sx={{mt:'13px',marginLeft:'10px'}} onClick={() => handleSaveInformation('new')}>Save & New</Button>
                  </>
                  }
                  {(isSaveClicked===true) &&
                      <LoaderButton label="Save Information"/>
                  }
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          </Grid>
        }
        </Box>
    </div>
  );
 
}
export default CreatePayment;