import * as React from 'react';
import APP_HTTP from '../APP_HTTP';
import Watermark from './WaterMark';
const PrintHeader = (props)=> {
  return (
    <>
    <thead>
        {(props.header_data && props.header_data.info) &&
        <>
       
        <tr>
        {(props.header_data.print_header.value===undefined || (props.header_data.print_header.value!==undefined && props.header_data.print_header.value.header_type==='default')) &&
            <>
               
                <th style={{textAlign:'center',whiteSpace:'nowrap',border:(props.target==='payment')?'none':'1px solid #333'}} colSpan={(props.colSpan)?props.colSpan:5}>
                    <div  style={{minHeight:'115px'}}>
                        <div style={{position:'relative'}}>
                            <img src={props.header_data.info.photo} alt="image" height={110} width={110} style={{position:'absolute',left:'0px'}}/>
                        </div>
                        <div style={{marginLeft:'75px'}}>
                        <h1 style={{fontSize:'30px',fontWeight:'bold',margin:'0px'}}>
                        {props.header_data.info.school_name}
                        </h1>
                        <p style={{fontSize:'20px',margin:'0px',fontWeight:'normal'}}>
                        {props.header_data.info.mobile_number} | {props.header_data.info.alt_mobile_number}
                        </p>
                        <p style={{fontSize:'20px',margin:'0px',fontWeight:'normal'}}>
                        {props.header_data.info.email}
                        </p>
                        <p style={{fontSize:'20px',margin:'0px',borderLeft:'none',fontWeight:'normal'}}>
                        {props.header_data.info.locality}, {props.header_data.info.city}, {props.header_data.info.state},{props.header_data.info.pincode}
                        </p>
                        {(props.more_data && props.more_data.payment_type!==undefined && props.header_data.print_header_title!==undefined && props.header_data.print_header_title.value!==undefined && props.header_data.print_header_title.value!=="" && props.header_data.print_header_title.value[props.more_data.payment_type]!==undefined) &&           

        <p style={{textAlign:'center',fontSize:'20px',fontWeight:'bold',margin:'0px'}}>{props.header_data.print_header_title.value[props.more_data.payment_type]}</p>
    
    }
    </div>
    </div>
                </th>
            </>
        }
        {(props.header_data.print_header.value!==undefined && props.header_data.print_header.value.header_type==='custom') &&
        <th style={{textAlign:'left',whiteSpace:'nowrap'}} colSpan={(props.colSpan)?props.colSpan:5}>
            <div style={{display:'flex'}}>
                <img src={props.header_data.print_header.value.header+'?time='+Math.random(1111,9999)} alt="image" height={130} width={'100%'}/>
            </div>
        </th>
        }
        </tr>
        </>
        }
        {(props.content1 && props.content1!=='') &&
         <tr>
            <th colSpan={(props.colSpan)?props.colSpan:5} style={{textAlign:'center'}}>{props.content1}</th>
        </tr> 
        }
        {(props.content2 && props.content2!=='') &&
        <tr>
       <th colSpan={(props.colSpan)?props.colSpan:5} style={{textAlign:'center'}}>{props.content2}</th>
        </tr>
        }
    </thead>
    </>
  );
}
export default PrintHeader;