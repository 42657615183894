import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, Checkbox, Divider, FormControlLabel, Menu, MenuItem, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, CurrencyRupee, Delete, Edit, Filter, FilterAlt, PlusOneRounded } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeleteStudent, handleSetStudentsList, handleToggleEditStudent,handleSetStudentInfo, handleToggleViewStudent } from './Action';
import CreateStudent from './CreateStudent';
import { handleChangeMenu } from '../../actions/Action';
import AppUtils from '../../AppUtils';
import AddOpeningBalance from './AddOpeningBalance';
import { handleOpenUpdateStudentFeeStructure } from '../settings/payment-types/Action';
import CreateStudentFeeStructure from '../settings/payment-types/StudentFeeStructure';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import StudentCard from './StudentCard';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import TransportDropdown from '../transport/TransportDropdown';
import PrintStudentList from './PrintStudentList';
import PayInstantly from '../payments/PayInstantly';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StudentList = () => {
  const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
  const [open, setAnchorOpen] = React.useState(null);
  const [selected_session, setSession] = React.useState(0);
  const [selected_class, setClass] = React.useState(0);
  const [selected_section, setSection] = React.useState(0);
  const [searched_value, setSearchedValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [student_data,setStudentData] = React.useState([]);
  const state = useSelector((state) => state);
  const is_college = state.school_info.school_info.is_college;
  const is_delete_item = state.is_delete_item;
  const is_open_update_student_fee_structure = state.is_open_update_student_fee_structure;
  const student_list = state.students_list;
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  const [is_transport_search,setTransportSearch] = React.useState(false);
  const [is_add_ob,setIsAddOB] = React.useState(false);
  const [is_print,setIsPrint] = React.useState(false);
  const [is_instant_pay,setIsInstantPay] = React.useState(false);
  const [contact_info,setContactInfo] = React.useState({});
  const [is_show_advance_filter,setShowAdvanceFilter] = React.useState(false);
  
  
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('search');
            if(myParam){
              handleSearch(myParam);
            }else{
              if(get_page===null){
                handleLoadContactList(1)
              }
            }
        }
    },[])
    const handlePrintStudent = ()=>{
      setIsPrint(true);
      setTimeout(() => {
        setIsPrint(false);
      }, 2000);
  }
    const handleClick = (event,idx) => {
      setAnchorEl(event.currentTarget);
      setAnchorOpen(idx);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setAnchorOpen(null);
    };
    const handleLoadContactList = (page) =>{
      setIsLoadingList(true);
      dispatch(handleSetStudentsList([]));
      let t_records = total_records;
      if(page===1){
        t_records = 0;
      }
      APP_HTTP.REQUEST('student/get_students',{page:page,total_records:t_records},true,true).then((response) => {
          let resp = response.data;
          let stu_list = resp.response;
          
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
        
          dispatch(handleSetStudentsList(stu_list));
          setIsLoadingList(false);
          setStudentData(resp);
      });
    }
    const handleNavigatePage = (page) =>{
      handleLoadContactList(page);
    }

    const handleDeleteItem = (id)=> {
      let studentValue = student_list[id];
      let student_id = studentValue.student_id;
      
      let delData = {id:student_id,index:id,target:'student'};
      setDeleteData(delData);
      dispatch(handleConfirmDeleteStudent(true));
  }
  const handleEditItem = (id)=>{
    let student_info = student_list[id];
    let student_id = student_info.student_id;
    window.location.href = '/student/edit/'+student_id;
    
  }
  const handleRestore = (id)=>{
    let student_info = student_list[id];
    let student_id = student_info.student_id;
    APP_HTTP.REQUEST('student/restore',{id:student_id},true,true).then((response) => {
      window.location.href = '/student/view/'+student_id;
    });
  }
  const handleViewItem = (id)=>{
    
    if(AppUtils.has_access(state,'2_students_view')===false){
      return false;
    }
    let student_info = student_list[id];
    let student_id = student_info.student_id;
    dispatch(handleChangeMenu('student','view/'+student_id,'Student Profile',false));
    dispatch(handleToggleViewStudent(true));
    dispatch(handleSetStudentInfo(student_info));
    
  }
  const handleSearch = (thisvalue)=>{
    const url = new URL(window.location.href);
    setSearchedValue(thisvalue);
    if(thisvalue!==''){
      url.searchParams.set('search', thisvalue);
      let new_url = url.toString();
      localStorage.setItem('referer',new_url);
      window.history.pushState(null, '',new_url);
      if(thisvalue.length<2){
        return false; 
      }
      if(!isNaN(thisvalue)){
        if(thisvalue.length<4){
          return false;
        }
      }
      handleProcessSearch(thisvalue,selected_session,selected_class,selected_section);
    }else{
      url.search = "";
      localStorage.setItem('referer',url.href);
      window.history.pushState(null, '',url.href);
      if(selected_class>0){
        handleProcessSearch(thisvalue,selected_session,selected_class,selected_section);
      }else{
        handleLoadContactList(1);
      }
    }
  }
  const handleProcessSearch = (thisvalue,session_id,class_id,section_id)=>{
    
    setIsLoadingList(true);
    dispatch(handleSetStudentsList([]));
    APP_HTTP.REQUEST('student/search_student',{search:thisvalue,academic_session:session_id,class:class_id,section:section_id},true,true,false,true).then((response) => {
          if(response.data){
            let resp = response.data;
            let stf_list = resp.response;
            let tot_record = resp.total_record;
            let no_of_page = resp.number_of_page;
            setTotalRecords(tot_record);
            setNumberOfPage(no_of_page);
            
            dispatch(handleSetStudentsList(stf_list));
            setIsLoadingList(false);
        }
    });
  }
  const handleEditStudentFeeStructure = (idx) =>{
    let student_info  = student_list[idx];
    dispatch(handleSetStudentInfo(student_info));
    dispatch(handleOpenUpdateStudentFeeStructure(true));
  }

  const handleAddOpeningBalance = ()=>{
    setIsAddOB(true)
  }
  const handleCloseOpeningBalance = ()=>{
    setIsAddOB(false)
  }
  const handleChangeSession = (id,value) =>{
    setSession(value); 
    handleProcessSearch(searched_value,value,selected_class,selected_section);
  }
  const handleSelectClass = (id,value) =>{
    setClass(value); 
    handleProcessSearch(searched_value,selected_session, value,selected_section);
  }
  const handleChangeSection = (id,value) =>{
    setSection(value); 
    handleProcessSearch(searched_value,selected_session,selected_class,value);
  }
  const handleChangeTransport = (id,value) =>{
    if(value>0){
      setTransportSearch(true);
      setIsLoadingList(true);
      dispatch(handleSetStudentsList([]));
      APP_HTTP.REQUEST('student/search_student',{transport:value},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              dispatch(handleSetStudentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      setTransportSearch(true);
      handleProcessSearch(searched_value,selected_session,selected_class,selected_section);
    }
  }
  const handleShowAdvanceFilter = () =>{
    setShowAdvanceFilter(!is_show_advance_filter);
  }
  const handleShowCancelledStudents = (is_checked) =>{
    if(is_checked){
      setIsLoadingList(true);
      dispatch(handleSetStudentsList([]));
      APP_HTTP.REQUEST('student/search_student',{show_cancelled:true},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              dispatch(handleSetStudentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      if(selected_class>0){
        handleProcessSearch(searched_value,selected_session,selected_class,selected_section);
      }else{
        handleLoadContactList(1);
      }
    }
  }
  const handlePayInstantly =(instant_data)=>{
   // let con_info = {"opening_balance": instant_data.opening_balance,"id": instant_data.student_id,"photo": instant_data.photo,"name": instant_data.student_name,"class": instant_data.class,"section": instant_data.section,"father_name": instant_data.father_name,"roll_number": instant_data.roll_number, "type": "student"}
    instant_data.id = instant_data.student_id;
    instant_data.name = instant_data.student_name;
    instant_data.type = 'student';
    setContactInfo(instant_data);
    setIsInstantPay(true);
   }
   const handleCloseInstantPay = () =>{
    setIsInstantPay(false);
   }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={2} spacing={2}>
              <TextField
                label="Search Student"
                size="small"
                fullWidth
                onChange={(e)=>handleSearch(e.target.value)}
                value={searched_value}
                />
              </Grid>
              {(is_college===true) &&
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <SessionDropdown handleChangeData={handleChangeSession}/>
              </Grid>
              }
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Classes show="dropdown"  handleChangeData={handleSelectClass}/>
              </Grid>
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Sections show="dropdown"  handleChangeData={handleChangeSection}/>
              </Grid>
              <Grid item xs={12} sm={12} md={1} spacing={2} style={{display:'inline-flex',marginTop:'5px'}}>
                <ContactsIcon style={{marginTop:'3px',marginRight:'8px'}}/> <Typography variant='h5'>{total_records}</Typography>
              </Grid>
              {(is_college!==true) &&
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                  <TransportDropdown handleChangeData={handleChangeTransport} defaultValue={0}/>
              </Grid>
              }
              
              <Grid item xs={12} sm={12} md={1} spacing={2}>
                <Button variant='outlined' size='small' onClick={handlePrintStudent}>Print</Button>
              </Grid>
              
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Button variant='outlined' size='small' onClick={handleAddOpeningBalance}>Add Back Dues</Button>
                <FilterAlt fontSize="large" style={{marginLeft:'5px',marginTop:'-4px',float:'right',cursor:'pointer'}} onClick={handleShowAdvanceFilter}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
              <Divider />
              </Grid>
              {(is_show_advance_filter===true) &&
                <>
                  <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <FormControlLabel control={<Checkbox value="show_cancelled" color="primary" onChange={(e)=>handleShowCancelledStudents(e.target.checked)}/>} label="Show Cancelled Student(s)"/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <Divider />
                  </Grid>
                </>
              }
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                       
                        <StyledTableCell>NAME</StyledTableCell>
                        
                        <StyledTableCell>BACK DUES</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {student_list.map((row,index) => (
                        <StyledTableRow key={row.student_sequence}>
                          <StyledTableCell component="th" scope="row">
                           <StudentCard info={row} is_photo={true}/>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography variant='h6'>
                            ₹{AppUtils.currency_format(row.opening_balance)}
                            </Typography>
                          </StyledTableCell>
                            <StyledTableCell>
                            {AppUtils.has_access(state,'501_payment_in_add') &&
                              <Button variant="outlined" size='small' onClick={()=>handlePayInstantly(row)}>Quick Payment</Button>
                            }
                            </StyledTableCell>
                          <StyledTableCell>
                          
                          <Button
                                  id={"demo-customized-button-"+index}
                                  aria-controls={(open===index) ? 'demo-customized-menu-'+index : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={(open===index) ? 'true' : undefined}
                                  variant="contained"
                                  disableElevation
                                  onClick={(event)=>handleClick(event,index)}
                                  endIcon={<KeyboardArrowDownIcon />}
                                  key={'btn-key-'+index}
                                >
                                  Action
                          </Button>
                          <StyledMenu
                            key={'sm-key-'+index}
                            id={"demo-customized-menu-"+index}
                            MenuListProps={{
                              'aria-labelledby': "demo-customized-menu-"+index,
                            }}
                            anchorEl={anchorEl}
                            open={(open===index)?true:false}
                            onClose={handleClose}
                          >
                            {(AppUtils.has_access(state,'2_students_delete')) &&
                              <MenuItem disableRipple onClick={() =>handleDeleteItem(index)} key={'mu1-key-'+index}>
                                <Delete /> Delete
                              </MenuItem>
                            }
                            {(parseInt(row.status)===0) &&
                            <>
                            
                                <MenuItem key={'mu2-key-'+index} disableRipple  onClick={() =>handleRestore(index)}>
                                  <Edit /> Restore
                                </MenuItem>
                            
                            </>
                            }
                            {(parseInt(row.status)===1) &&
                            <>
                            {(AppUtils.has_access(state,'2_students_edit')) &&
                                <MenuItem key={'mu2-key-'+index} disableRipple  onClick={() =>handleEditItem(index)}>
                                  <Edit /> Modify
                                </MenuItem>
                            }
                            {(AppUtils.has_access(state,'29_payment_types_edit')) &&
                                <MenuItem key={'mu3-key-'+index} disableRipple  onClick={() =>handleEditStudentFeeStructure(index)}>
                                  <CurrencyRupee /> Modify Fee Structure
                                </MenuItem>
                            }
                            </>
                            }
                          </StyledMenu>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                        Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && student_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/students/page'}/>
              </Grid>
            </Grid>
            {(is_delete_item) &&
              <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
            }
            {(is_add_ob===true) &&
              <AddOpeningBalance handleCloseOB={handleCloseOpeningBalance}/>
            }
            {(is_open_update_student_fee_structure===true) &&
              <CreateStudentFeeStructure />
            }
            {(is_print) &&
              <PrintStudentList printData={student_list} is_transport_search={is_transport_search}/>
            } 
            {(is_instant_pay) &&
              <PayInstantly contact_info={contact_info} handleCloseInstantPay={handleCloseInstantPay}/>
            }
          </Box>
      </>
  );
}

export default StudentList;