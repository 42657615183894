import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { AppBar, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, RadioGroup, Toolbar, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import LoaderButton from '../LoaderButton';
import BasicDatePicker from '../DatePicker';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleOpenCreateDemandBill, handleSetPaymentInfo, handleToggleEditPayment, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import Autocomplete from '@mui/material/Autocomplete';
import Sections from '../settings/sections/Sections';
import Classes from '../settings/classes/Classes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AccountCircleRounded, Add, Check, CheckCircle, Close, CurrencyRupee, Delete, Edit } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
import SuccessLoader from '../SuccessLoader';
import PrintDemandBill from './PrintDemandBill';
import StudentCard from '../students/StudentCard';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CreateDemandBill = (props)=> {
    const [month_list,setMonthList] = React.useState([{key:0,value:'Apr'},{key:1,value:'May'},{key:2,value:'Jun'},{key:3,value:'Jul'},{key:4,value:'Aug'},{key:5,value:'Sep'},{key:6,value:'Oct'},{key:7,value:'Nov'},{key:8,value:'Dec'},{key:9,value:'Jan'},{key:10,value:'Feb'},{key:11,value:'Mar'}]);

    let [selected_class,setClass] = React.useState(0);
    let [selected_session,setSession] = React.useState(0);
    let [selected_section,setSection] = React.useState(0);
    let [students_list,setStudentList] = React.useState([]);
    let [pending_payment_list,setPendingPaymentList] = React.useState([]);
    let [contact_info,setContactInfo] = React.useState([]);
    let [printData,setPrintData] = React.useState([]);
    let [is_print,setIsPrint] = React.useState(false);
    let [is_saving,setIsSaving] = React.useState(false);
    let [is_saved,setIsSaved] = React.useState(false);
    let [is_new_row_added,setIsNewRowAdded] = React.useState(false);
    
    let [more_list,setMoreList] = React.useState({id:0,name:'',fee_duration:'',link_month:'',fee_structure:{months:[],total:''},fee_year:'',fee:'',month:'',fee_year:''});
    let [uniform_list,setUniformList] = React.useState([]);
    let [is_uniform_fee,setIsUniform] = React.useState(false);
    let [demand_bill_generate_month,setDemandBillMonth] = React.useState('');
    
    let [is_proceeding,setIsProceeding] = React.useState(false);
    const state = useSelector((state) => state);
    const is_open_create_demand_bill = state.is_open_create_demand_bill;
    const is_college = state.school_info.school_info.is_college;
    const payment_type_list = state.payment_type_list;
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetDemandBillGenerateMonth();
        }
    },[props])
    const handleClose = ()=>{
        initialized.current = false;
        dispatch(handleOpenCreateDemandBill(false));
     }
  let demand_bill_total = 0;
  const handleChangeInputValue = (value) =>{
    
  }
  const handleChangeValue = (value) =>{
    setContactInfo(value)
  }
  const handleGetDemandBillGenerateMonth = () =>{
    APP_HTTP.REQUEST('settings/get_demand_bill_generate_month',{},true,true,false,true).then((response) => {
      let resp = response.data;
      let month_idx = resp.response;
      setDemandBillMonth(month_idx);
    });
}
  const handleChangeSession = (id,value) =>{
    setSession(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:value,class:selected_class,section:selected_section},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentList(search_list);
    });
}

  const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setStudentList([]);
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:value,academic_session:selected_session},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentList(search_list);
    });
  }
  const handlePrepareDemandBill = () =>{
    setIsProceeding(true);
    let student_id = 0;
    if(contact_info!==null && contact_info.id!==undefined){
        student_id = contact_info.id;
    }
    APP_HTTP.REQUEST('payment/prepare_demand_bill',{class:selected_class,section:selected_section,student:student_id,demand_bill_generate_month:demand_bill_generate_month},true,true,false,true).then((response) => {
        setIsProceeding(false);
        let resp = response.data;
        let search_list = resp.response;
        setPendingPaymentList(search_list);
    });
  }
  const handleSaveDemandBill = (type) =>{
    setIsSaving(true);
    let student_id = 0;
    if(contact_info!==null && contact_info.id!==undefined){
        student_id = contact_info.id;
    }
    APP_HTTP.REQUEST('payment/create_demand_bill',{data:pending_payment_list,class:selected_class,section:selected_section,student:student_id,month:demand_bill_generate_month,target:type},true,true,false,true).then((response) => {
      setIsProceeding(false);
      let resp = response.data.response;
      setIsSaving(false);
      if(type==='save'){
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          handleClose();
          window.location.href='/payments/demand_bill';
        }, 800);
      }else if(type==='print'){
        setPrintData(resp);
        setIsPrint(true);
        setTimeout(() => {
          setIsPrint(false);
          setPrintData([]);
          handleClose();
        }, 2000);
      }
      
  });
  }
  const handleChangeDemandBillGenerateMonth = (id,value)=>{
    setDemandBillMonth(value);
  }
  const handleChangeUniform = (id,value,moreValue) =>{
    let uni_list = [...uniform_list];
    let fee = 0;
    for (let index = 0; index < uni_list.length; index++) {
      const element = uni_list[index];
      if(parseInt(element.uniform_id)==parseInt(value)){
        fee = element.fee;
        break;
      }
    }
    let mlist = {...more_list};
    mlist.fee = fee;
    setMoreList(mlist);
  }
  const handleChangeParticulars = (id,value,moreValue) =>{
    let mlist = {...more_list};
    mlist.id = value;
    let is_uniform = moreValue.is_uniform_fee;
    if(is_uniform===true){
      setIsUniform(is_uniform);
      let uni_list = moreValue.uniform_list;
      setUniformList(uni_list);
    }
    let fee = '';
    let type_name = '';
    for (let index = 0; index < payment_type_list.length; index++) {
      const element = payment_type_list[index];
      if(parseInt(element.id)===parseInt(value)){
        let type_name = element.name;
        let global_fee = element.global_fee;
        let class_fee = element.class_fee;
        if(selected_class>0){
          if(class_fee!==null && class_fee[selected_class]!==undefined && class_fee[selected_class]!==""){
            fee = class_fee[selected_class];
            break;
          }else if(global_fee!=="" && global_fee!==null){
            fee = global_fee;
            break;
          }
        }
      }
    }
    let mitem = {id:value,name:moreValue.name,fee_duration:'more',link_month:'',fee_structure:{months:[],total:fee},fee_year:'',fee:fee,month:'',fee_year:''};
    setMoreList(mitem);
  }
  const handleAddMoreParticulars = (idx)=>{
    let payList = [...pending_payment_list];
    payList[idx].is_add_more = true;
    setPendingPaymentList(payList);
  }
  const handleCloseAddToList = (idx)=>{
    let payList = [...pending_payment_list];
    payList[idx].is_add_more = false;
    setPendingPaymentList(payList);
  }
  const handleChangeMoreListAmount=(value)=>{
    let mlist = {...more_list};
    mlist.fee = value;
    setMoreList(mlist);
  }
  const handleAddToList = (idx) =>{
    
    let pen_list = [...pending_payment_list];
    let mlist = {...more_list};
  
    pen_list[idx].pending_payment_list.push(mlist);
    
    pen_list[idx].is_add_more = false;
    setPendingPaymentList(pen_list);
    let empty_list = {id:0,name:'',fee_duration:'',link_month:'',fee_structure:{months:[],total:''},fee_year:'',fee:'',month:'',fee_year:''};
    setMoreList(empty_list);
   
  }
  const handleRemoveItem = (index,idx) =>{
    let pen_list = [...pending_payment_list];
    pen_list[index].pending_payment_list.splice(idx,1);
    setPendingPaymentList(pen_list);
  }
  return (
    
    <Dialog open={is_open_create_demand_bill} onClose={handleClose} fullWidth={true} fullScreen={true}>
        <AppBar position="fixed" component="nav">
          <Toolbar>
              <DialogTitle>Preview Demand Bill</DialogTitle>
          </Toolbar>
        </AppBar>
      
        <DialogContent sx={{marginTop:'100px'}}>
        <Grid container spacing={2}>
              {(is_college===true) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <SessionDropdown handleChangeData={handleChangeSession}/>
                </Grid>
                }
              <Grid item xs={12} sm={12} md={2}>
                <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
              </Grid>
              {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={2}>
                  <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                </Grid>
              }
              {(selected_class>0) &&
              <>
              <Grid item xs={12} sm={12} md={2}>
                  <Autocomplete
                      value={contact_info}
                      noOptionsText={'No Student Found ! Type To Search'}
                      onChange={(event, newValue) => handleChangeValue(newValue)}
                      onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                      disablePortal
                      id="payee_dropdown"
                      options={students_list}
                      size="small"
                      renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                  />
              </Grid>
              {(demand_bill_generate_month!=='') &&
              <Grid item xs={12} sm={12} md={2}>
                <AppDropDown label="Generate Demand Bill Till Month" list_item={month_list} size="small" handleChangeData={handleChangeDemandBillGenerateMonth} defaultValue={demand_bill_generate_month} id={'demand_bill_generate_month'}/>
              </Grid>
              }
              </>
            }
              {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={2}>
                    {(is_proceeding===false) &&
                        <Button onClick={handlePrepareDemandBill} variant='outlined'>Proceed</Button>
                    }
                    {(is_proceeding===true) &&
                        <LoaderButton variant='outlined'/>
                    }
                </Grid>
                }
              <Grid item xs={12} sm={12} md={12}><Divider /></Grid>
            {(is_new_row_added===false) &&
            <>
              {(pending_payment_list.map((value,index)=>{
                demand_bill_total = 0;
                  return(
                    <Grid item xs={12} sm={12} md={4}>
                      <Grid container spacing={2} style={{marginTop:'10px'}}>
                          
                              <Grid item xs={12} sm={12} md={12} sx={{marginTop:'5px'}}> 
                                  <StudentCard info={value.student_info} is_photo={true}/>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}><Divider /></Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                  <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{'whiteSpace':'nowrap'}}>FEE TYPE</StyledTableCell>
                                            <StyledTableCell style={{'whiteSpace':'nowrap'}}>TOTAL</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {value.pending_payment_list && value.pending_payment_list.map((row,idx) => {
                                          if(row.fee_duration==='yearly'){
                                            demand_bill_total = demand_bill_total + parseInt(row.fee_structure.total);
                                          }
                                          return(
                                            <>
                                            {(row.fee_duration==='yearly') &&
                                            <>
                                            {(row.fee_structure.total>0) &&
                                              <StyledTableRow key={'fitem-'+idx}>
                                                  <StyledTableCell sx={{textTransform:'uppercase'}}>
                                                    {(row.name!=='Back Dues') &&
                                                      <>{row.name}({row.link_month})</>
                                                    }
                                                    {(row.name==='Back Dues') &&
                                                      <>{(parseFloat(row.fee_structure.total)>0)?row.name:'Advance Payment'}</>
                                                    }
                                                    </StyledTableCell>
                                                  <StyledTableCell sx={{textTransform:'uppercase'}}>₹{AppUtils.currency_format(row.fee_structure.total)}</StyledTableCell>
                                              </StyledTableRow>
                                            }
                                            </>
                                            }
                                            {(row.fee_duration==='more') &&
                                              <StyledTableRow key={'fitem-'+idx}>
                                                  <StyledTableCell sx={{textTransform:'uppercase'}}>
                                                    {row.name}
                                                    </StyledTableCell>
                                                  <StyledTableCell sx={{textTransform:'uppercase'}}>
                                                    ₹{AppUtils.currency_format(row.fee)}
                                                    <Close style={{float:'right',color:'red',cursor:'pointer'}} onClick={()=>handleRemoveItem(index,idx)}/>
                                                  </StyledTableCell>
                                              </StyledTableRow>
                                            }
                                            {(row.fee_duration==='monthly') &&
                                              <>
                                              {(row.fee_structure.months.map((mvalue,midx)=>{
                                                
                                                demand_bill_total = demand_bill_total + mvalue.fee;
                                                
                                                return(
                                                  <>
                                                  {(mvalue.fee>0) &&
                                                  <StyledTableRow key={'fmitem-'+midx}>
                                                    <StyledTableCell sx={{textTransform:'uppercase'}}>
                                                      {row.name}
                                                      {(row.name!=='Back Dues') &&
                                                        <>({mvalue.month})</>
                                                      }
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{textTransform:'uppercase'}}>₹{AppUtils.currency_format(mvalue.fee)}</StyledTableCell>
                                                  </StyledTableRow>
                                                  }
                                                  </>
                                                )
                                              }))}
                                              </>
                                            }
                                            </>
                                        )})}
                                        
                                        <StyledTableRow>
                                          <StyledTableCell sx={{textTransform:'uppercase'}}>
                                          {(value.is_add_more!==true) &&
                                            <Button variant='outlined' size='small' onClick={()=>handleAddMoreParticulars(index)}>Add more particular</Button>
                                          }
                                          </StyledTableCell>
                                          <StyledTableCell sx={{textTransform:'uppercase',fontWeight:'bold'}}>
                                            <Typography variant='h6'>
                                              ₹{AppUtils.currency_format(demand_bill_total)}
                                            </Typography>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        {(value.is_add_more===true) &&
                                        <>
                                        <StyledTableRow>
                                          <StyledTableCell colSpan={2} style={{background:'#5e86ad',color:'#fff'}}>
                                            <Typography style={{fontSize:'14px'}}>ADD NEW PARTICULAR TO THE LIST</Typography>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow style={{background:'#f7ffff'}}>
                                          <StyledTableCell colSpan={2}>
                                            <PaymentTypes show="dropdown" defaultValue={''} handleChangeData={handleChangeParticulars} id={index} label={'Fee Types'} target={'payment_type'}/>
                                          
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        {(is_uniform_fee===true) &&
                                          <StyledTableRow style={{background:'#f7ffff'}}>
                                          <StyledTableCell colSpan={2}>
                                          
                                              <AppDropDown label="Select Uniform" list_item={uniform_list} size="small" handleChangeData={handleChangeUniform} id={index}/>
                                            
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        }
                                          <StyledTableRow  style={{background:'#f7ffff'}}>
                                          <StyledTableCell colSpan={2}>
                                            <TextField
                                              margin="dense"
                                              label="Enter Amount"
                                              type="text"
                                              fullWidth
                                              size="small"
                                              id="amount-box"
                                              value={more_list.fee}
                                              onChange={(e)=>handleChangeMoreListAmount(e.target.value)}
                                              style={{marginTop:'4px'}}
                                            />
                                          </StyledTableCell>
                                          </StyledTableRow>
                                          <StyledTableRow  style={{background:'#f7ffff'}}>
                                          <StyledTableCell>
                                            <Button variant='contained' color='error' size='small' onClick={()=>handleCloseAddToList(index)}>Close</Button>
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <Button variant='contained' color='success' fullWidth={true} size='small' onClick={()=>handleAddToList(index)}><Add /> Add To List</Button>
                                          </StyledTableCell>
                                          </StyledTableRow>
                                        </>
                                        }
                                        </TableBody>
                                    </Table>
                                  </TableContainer>
                              </Grid>
                      </Grid>
                    </Grid>
                  )
              }))}
            </>
            }
        </Grid>
        </DialogContent>
        
          <DialogActions>
            <Button onClick={handleClose} size='small'>Close</Button>
            {(pending_payment_list.length>0) &&
              <>
                {(is_saving===false) &&
                  <>
                    <Button onClick={()=>handleSaveDemandBill('save')} variant='contained' color='success'>Generate</Button>
                    
                    <Button onClick={()=>handleSaveDemandBill('print')} variant='contained' color='success'>Generate & Print</Button>
                    
                  </>
                }
                {(is_saving===true) &&
                  <LoaderButton variant='contained' color='success'/>
                }
              </>
            }    
          </DialogActions>
        {(is_saved===true) &&
            <SuccessLoader />
        }
        {(is_print===true) &&
          <PrintDemandBill payment_data={printData} is_demand_bill={true}/>
        }
      </Dialog>
      
  );
 
}
export default CreateDemandBill;