import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Divider, InputLabel, MenuItem, NativeSelect, Table, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';

import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeletePaymentType, handleOpenAddPaymentType,handleSetPaymentTypeList } from './Action.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import CreatePaymentType from './CreatePaymentType.jsx';
import AppUtils from '../../../AppUtils.jsx';
import LoaderButton from '../../LoaderButton.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
let late_fine_days = [];
for (let index = 0; index < 31; index++) {
    let fDay = index + 1;
    let lfd = {key:fDay,value:fDay};
    late_fine_days.push(lfd);
}
const PaymentTypes = (props) => {
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [class_fee,setClassFee] = React.useState({});
    const [yearly_package_particulars,setYearlyPackage] = React.useState([{id:0,particular:'',price:''}]);
    const [is_loading,setLoading] = React.useState(true);
    const [is_saving_package,setPackageSaving] = React.useState(false);
    const [is_saving_demand_bill,setSavingDemandBill] = React.useState(false);
    const [is_saving_name_type,setSavingNameType] = React.useState(false);
    const [is_saving_demand_bill_list_type,setSavingDemandBillListType] = React.useState(false);
    const [is_saving_late_fine,setIsSavingLateFine] = React.useState(false);
    const [is_loading_settings,setIsLoadingSetting] = React.useState(true);
    const [is_loading_demand_bill,setIsLoadingDemandBill] = React.useState(true);
    const [is_loading_demand_bill_type,setIsLoadingDemandBillListType] = React.useState(true);
    const [is_loading_show_name_type,setIsLoadingShowNameType] = React.useState(true);
    const [late_fine_start_day,setLateFineDay] = React.useState(10);
    const [late_fine,setLateFine] = React.useState('');
    const [demand_bill_generate_type,setDemandBillGenerateType] = React.useState('single');
    const [demand_bill_generate_list_type,setDemandGenerateListType] = React.useState('detailed');
    const [show_name_type,setShowNameType] = React.useState('all');

    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const is_open_add_payment_type = state.is_open_add_payment_type;
    const is_delete_item = state.is_delete_item;
    const payment_type_list = state.payment_type_list;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(payment_type_list.length===0){
            
                handleGetYearlyPackage();
                APP_HTTP.REQUEST('settings/get_payment_type_list',{target:props.target},true,true).then((response) => {
                    let resp = response.data;
                    let payment_type_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetPaymentTypeList(payment_type_list));
                });
            }
            handleGetSettings();
        }
    },[dispatch,payment_type_list])

    const list_item = [{key:0,value:'Select Fee Type'}];
    for (let index = 0; index < payment_type_list.length; index++) {
        const element = payment_type_list[index];
        let ptype_item = {key:element.id,value:element.name,is_salary:element.is_salary};
        list_item.push(ptype_item)
    }
    const [payment_typeValue,setPaymentTypeEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let ptypeValue = payment_type_list[id];
        let ptypeId = ptypeValue.id;
        
        let delData = {id:ptypeId,index:id,target:'payment_type'};
        setDeleteData(delData);
        dispatch(handleConfirmDeletePaymentType(true));
    }
    const handleEditItem = (id)=>{
        
        let ptypeValue = payment_type_list[id];
        
        let cls_fee = ptypeValue.class_fee;
        setClassFee(cls_fee);
        if(ptypeValue.is_edit===undefined){
            ptypeValue['is_edit'] = true;
        }else {
            ptypeValue.is_edit = true;
        }
        if(ptypeValue.update_id===undefined){
            ptypeValue['update_id'] = id;
        }else{
            ptypeValue.update_id = id;
        }
        setPaymentTypeEdit(ptypeValue);
        dispatch(handleOpenAddPaymentType(true));
    }
    const openAddPaymentType = () =>{
        setPaymentTypeEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddPaymentType(true));
    }
    const handleOnChangeType = (key,value) =>{
        let type_value = {id:0};
        for (let index = 0; index < payment_type_list.length; index++) {
            const element = payment_type_list[index];
            let id = element.id;
            if(parseInt(value)===parseInt(id)){
                type_value = element;
                break;
            }
        }
        props.handleChangeData(key,value,type_value);
    }

    const handleChangePaticular = (value,index)=>{
        let yearly_pck = [...yearly_package_particulars];
        yearly_pck[index].particular = value;
        setYearlyPackage(yearly_pck);
    }
    const handleChangePrice = (value,index)=>{
        let yearly_pck = [...yearly_package_particulars];
        yearly_pck[index].price = value;
        setYearlyPackage(yearly_pck);
    }
    const handleSavePackage = () =>{
        setPackageSaving(true);
        APP_HTTP.REQUEST('settings/save_yearly_package',{package:yearly_package_particulars},true,true).then((response) => {
            let resp = response.data.response;
            setPackageSaving(false);
            setYearlyPackage(resp);
        });
    }
    const handleGetYearlyPackage = () =>{
        APP_HTTP.REQUEST('settings/get_yearly_package',{},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.length>0){

                setYearlyPackage([]);
                setTimeout(() => {
                    setYearlyPackage(resp);
                }, 100);
            }
        });
    }
    const handleAddMore = () =>{
        let yearly_pck = [...yearly_package_particulars];
        yearly_pck.push({id:0,particular:'',price:''});
        setYearlyPackage(yearly_pck);
    }
    const handleRemovePackageItem = (index) =>{
        let yearly_pck = [...yearly_package_particulars];
        let id = yearly_pck[index].id;
        if(yearly_pck.length===1){
            setYearlyPackage([]);
            yearly_pck = [{id:0,particular:'',price:''}];
            setTimeout(() => {
                setYearlyPackage(yearly_pck);
            }, 100);
        }else{
            yearly_pck.splice(index,1);
            setYearlyPackage([]);
            setTimeout(() => {
                setYearlyPackage(yearly_pck);
            }, 10);
        }
        if(id>0){
            APP_HTTP.REQUEST('settings/remove_yearly_package',{id:id},true,true).then((response) => {
               
            });
        }
    }
    const handleChangeLateFine = (value) =>{
        setLateFine(value);
    }
    const handleChangeLateFineDay = (id,value) =>{
        setLateFineDay(value);
    }
    const handleSaveLateFine = ()=>{
        setIsSavingLateFine(true);
        let late_fine_setting = {late_fine:late_fine,late_fine_start_day:late_fine_start_day};
        APP_HTTP.REQUEST('settings/save_option',{name:'late_fine_settings',value:late_fine_setting},true,true).then((response) => {
            let resp = response.data;
            setIsSavingLateFine(false);
        });
    }
    const handleGetSettings = () =>{
        setIsLoadingSetting(true);
        APP_HTTP.REQUEST('settings/get_option',{name:'late_fine_settings'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.late_fine){
                setLateFine(resp.value.late_fine);
            }
            if(resp.value && resp.value.late_fine_start_day){
                setLateFineDay(resp.value.late_fine_start_day);
            }
            setIsLoadingSetting(false);
        });
        setIsLoadingDemandBill(true);
        APP_HTTP.REQUEST('settings/get_option',{name:'demand_bill_generate_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setDemandBillGenerateType(resp.value);
            }
            setIsLoadingDemandBill(false);
        });
        setIsLoadingShowNameType(true);
        APP_HTTP.REQUEST('settings/get_option',{name:'show_name_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setShowNameType(resp.value);
            }
            setIsLoadingShowNameType(false);
        });
        APP_HTTP.REQUEST('settings/get_option',{name:'demand_bill_list_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value){
                setDemandGenerateListType(resp.value);
            }
            setIsLoadingDemandBillListType(false);
        });
    }
    const handleChangeDemandBillGenerateType = (id,value)=>{
       setDemandBillGenerateType(value);
    }
    const handleChangeDemandBillGenerateListType = (id,value)=>{
       setDemandGenerateListType(value);
    }
    const handleChangeShowNameType = (id,value)=>{
       setShowNameType(value);
    }
    const handleSaveDemandBill = ()=>{
        setSavingDemandBill(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'demand_bill_generate_type',value:demand_bill_generate_type},true,true).then((response) => {
            let resp = response.data;
            setSavingDemandBill(false);
        });
    }
    const handleSaveShowNameType = ()=>{
        setSavingNameType(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'show_name_type',value:show_name_type},true,true).then((response) => {
            let resp = response.data;
            setSavingNameType(false);
        });
    }
    const handleSaveDemandBillListType = ()=>{
        setSavingDemandBillListType(true);
        APP_HTTP.REQUEST('settings/save_option',{name:'demand_bill_list_type',value:demand_bill_generate_list_type},true,true).then((response) => {
            let resp = response.data;
            setSavingDemandBillListType(false);
        });
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} payment_typeName="MainContainer">
            <Grid container spacing={2} payment_typeName="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Fee Structure(s)
                        {(AppUtils.has_access(state,'29_payment_types_add')) &&
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddPaymentType}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'29_payment_types_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(payment_type_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={value.name} />
                                        {(parseInt(value.id)!==3) &&
                                            <>
                                                {(AppUtils.has_access(state,'29_payment_types_delete')) &&
                                                    <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                                }
                                                {(AppUtils.has_access(state,'29_payment_types_edit')) &&
                                                    <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                                }
                                            </>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && payment_type_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && payment_type_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
            <Grid container spacing={2} payment_typeName="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Yearly Package
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>PARTICUALRS</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>PRICE/PIECE</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}></StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(yearly_package_particulars.map((value,index)=>{
                        return(
                          <StyledTableRow style={{backgroundColor:'#fff'}}>
                            <StyledTableCell>
                              <TextField
                              margin="dense"
                              size='small'
                              id={"particulars-"+index}
                              label="Particular"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={(event)=>handleChangePaticular(event.target.value,index)}
                              defaultValue={value.particular}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <TextField
                              margin="dense"
                              size='small'
                              id={"price-"+index}
                              label="Price"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={(event)=>handleChangePrice(event.target.value,index)}
                              defaultValue={value.price}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Close onClick={()=>handleRemovePackageItem(index)} style={{cursor:'pointer'}}/>
                            </StyledTableCell>
                            <StyledTableCell>
                            {((yearly_package_particulars.length-1)===index || yearly_package_particulars.length===1) &&
                            <Button size='small' onClick={handleAddMore}>Add More</Button>
                            }     
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }))}
                     <StyledTableRow style={{backgroundColor:'#fff'}}>
                           
                        <StyledTableCell colSpan={4} style={{textAlign:'right'}}>
                            {(is_saving_package===false) &&
                                <Button variant='outlined' size='medium' onClick={handleSavePackage}>Save Package</Button>
                            }
                            {(is_saving_package===true) &&
                                <LoaderButton variant='outlined' size='medium' />
                            }
                        </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        More Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'0px'}}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                   
                    <TableBody>
                     
                          <StyledTableRow style={{backgroundColor:'#fff'}}>
                            <StyledTableCell>
                                <TextField
                                    margin="dense"
                                    size='small'
                                    id={"late-fine"}
                                    label="₹ Late Fine"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(event)=>handleChangeLateFine(event.target.value)}
                                    value={late_fine}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                {(is_loading_settings===false) &&
                                    <AppDropDown label={"Late Fine Start From Day"} id={'late-fine-start-from'}  defaultValue={late_fine_start_day} list_item={late_fine_days} size="small" handleChangeData={handleChangeLateFineDay}/>
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                                {(is_saving_late_fine===false) &&
                                    <Button variant='outlined' size='medium' onClick={handleSaveLateFine}>Save</Button>
                                }
                                {(is_saving_late_fine===true) &&
                                    <LoaderButton variant='outlined' size='medium' />
                                }
                            </StyledTableCell>
                          </StyledTableRow>
                        
                        <StyledTableRow style={{backgroundColor:'#fff'}}>
                        <StyledTableCell colSpan={2}>
                            {(is_loading_demand_bill===false) &&
                                <AppDropDown label={"Demand Bill Generate Type"} id={'demand-bill-generate-type'}  defaultValue={demand_bill_generate_type} list_item={[{key:'single',value:'Single'},{key:'family',value:'Family'}]} size="small" handleChangeData={handleChangeDemandBillGenerateType}/>
                            }
                        </StyledTableCell>
                        <StyledTableCell>
                        {(is_saving_demand_bill===false) &&
                                    <Button variant='outlined' size='medium' onClick={handleSaveDemandBill}>Save</Button>
                                }
                                {(is_saving_demand_bill===true) &&
                                    <LoaderButton variant='outlined' size='medium' />
                                }
                        </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{backgroundColor:'#fff'}}>
                        <StyledTableCell colSpan={2}>
                            {(is_loading_show_name_type===false) &&
                                <AppDropDown label={"Payment/Demand Bill Show Name Type"}  defaultValue={show_name_type} list_item={[{key:'all',value:'Full Sibling Name with comma(,) separated'},{key:'more',value:'With +(number)'}]} size="small" handleChangeData={handleChangeShowNameType}/>
                            }
                        </StyledTableCell>
                        <StyledTableCell>
                        {(is_saving_name_type===false) &&
                                    <Button variant='outlined' size='medium' onClick={handleSaveShowNameType}>Save</Button>
                                }
                                {(is_saving_name_type===true) &&
                                    <LoaderButton variant='outlined' size='medium' />
                                }
                        </StyledTableCell>
                        </StyledTableRow> 
                        {(demand_bill_generate_type==='single') &&
                        <StyledTableRow style={{backgroundColor:'#fff'}}>
                        <StyledTableCell colSpan={2}>
                            {(is_loading_demand_bill_type===false) &&
                            <AppDropDown label={"Demand Bill Generate List Type"}  defaultValue={demand_bill_generate_list_type} list_item={[{key:'detailed',value:'Detailed List(Mothwise)'},{key:'narrow',value:'Only Current Month with Backdues'}]} size="small" handleChangeData={handleChangeDemandBillGenerateListType}/>
                            }
                        </StyledTableCell>
                        <StyledTableCell>
                            {(is_saving_demand_bill_list_type===false) &&
                                <Button variant='outlined' size='medium' onClick={handleSaveDemandBillListType}>Save</Button>
                            }
                            {(is_saving_demand_bill_list_type===true) &&
                                <LoaderButton variant='outlined' size='medium' />
                            }
                        </StyledTableCell>
                        </StyledTableRow> 
                        }
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
            </Grid>
        </Box>
       {(is_open_add_payment_type) && 
        <CreatePaymentType payment_typeValue={payment_typeValue} class_fee={class_fee}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
    <>
        {(list_item.length>0) &&
            <AppDropDown label={(props.label!==undefined)?props.label:"Payment Type"} id={(props.id!==undefined)?props.id:'payment_mode'}  defaultValue={(props.defaultValue)?parseFloat(props.defaultValue):0} list_item={list_item} size="small" payment_typeName={props.payment_typeName} handleChangeData={handleOnChangeType} disabled={(props.disabled===true)?true:false}/>
        }
    </>
    }
    </>
  );
}

export default PaymentTypes;